import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function AboutUs() {
        const navigate = useNavigate();
        const goBack = () => { navigate(-1); };

        useEffect(() => {
                window.scrollTo(0, 0)
        }, [])

        return (
                <div className='privacy_policy_parent '>
                        <div className='content'>

                                <div className='inner_content'>

                                </div></div>

                        <div className='header'>
                                <span onClick={goBack}><MdArrowBackIos /></span>
                                <Link to="/">  <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
                        </div>
                        <div class="privacy_policy">
                                <h4>Delete your BidsBoxx account:</h4>
                                <p>
                                        Deleting your BidsBoxx account will delete all associated data with it.
                                </p>
                                <hr />

                                <h5>Steps to delete account</h5>
                                <div className='content'>
                                        <p>
                                               1. <b>Sign In:</b> Log in to your BidsBoxx account in the app.
                                        </p>
                                        <p>
                                        2. <b>Access the More Section: </b> Tap the <b>More</b> section on the bottom bar of the home screen.
                                        </p>
                                        <p>
                                        3. <b>Go to Settings:</b>  Select the <b>Settings</b> option to navigate to the Settings screen.
                                        </p>
                                        <p>
                                        4. <b>Delete Account: </b>Tap the <b>Delete Account</b> card.
                                        </p>
                                        <p>
                                        5. <b>Confirm Deletion: </b> Confirm your decision to delete your account.
                                        </p>
                                </div>


                                <hr />

                                <h5>Important Notes:</h5>
                                <div className='content'>
                                        <p>
                                                • Will delete all the history related to your account 
                                        </p>
                                </div>

                        </div>

                </div>
        )
}

export default AboutUs