import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function BuyerPolicy() {
        const navigate = useNavigate();
        const goBack = () => { navigate(-1); };

        useEffect(() => {
                window.scrollTo(0, 0)
        }, [])

        return (
                <div className='privacy_policy_parent '>
                        <div className='header'>
                                <span onClick={goBack}><MdArrowBackIos /></span>
                                <Link to="/">  <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
                        </div>
                        <div className='privacy_policy '>
                                <h4>Bidsboxx Buyer Agreement</h4>
                                <p>
                                        This Buyer Agreement ("Agreement") is entered into by and between Bidsboxx ("Platform")
                                        and the undersigned buyer ("Buyer") as of the date of acceptance by the Buyer.
                                </p>
                                <h5>1. Purpose</h5>
                                <p>
                                        This Agreement outlines the terms and conditions under which the Buyer will participate in
                                        live sales on the Bidsboxx platform. Bidsboxx provides an online platform for live sales
                                        and facilitates transactions but does not assume responsibility for the products sold by Sellers.
                                </p>
                                <h5>2. Buyer Responsibilities</h5>
                                <div className='content'>
                                <h6>2.1 Account Information</h6>
                                <p>
                                        The Buyer must provide accurate and complete information during account creation and keep it up-to-date.
                                </p>
                                <h6>2.2 Funds Management</h6>
                                <p>
                                        The Buyer must ensure sufficient funds are available in their Bidsboxx wallet to cover offers and purchases.
                                        The Buyer is responsible for adding funds using the available payment methods, such as UPI, credit/debit cards, or net banking.
                                </p>
                                <h6>2.3 Participation</h6>
                                <p>
                                        The Buyer must review product details and descriptions carefully before placing an offer. The Buyer is responsible
                                        for their offers and must ensure they understand the terms and conditions of the live sale.
                                </p>
                                <h6>2.4 Payment</h6>
                                <p>
                                        If buyer wins the product, submitted offer amount will be deducted. The Buyer must follow the
                                        instructions provided to complete the purchase.
                                </p>
                                <h6>2.5 Cancellation</h6>
                                <p>
                                        The Buyer may only cancel offers or purchases in accordance with Bidsboxx’s cancellation and return policies.
                                </p>
                                <h6>2.6 Compliance with Policies</h6>
                                <p>
                                        The Buyer agrees to comply with all Bidsboxx policies, including those related to submitting offer during live sales.
                                </p>
                           </div>
                             
                                <h5>3. Platform Responsibilities</h5>
                                <div className='content'>
                                <h6>3.1 Platform Provision</h6>
                                <p>
                                        Bidsboxx provides an online platform for live sales and facilitates transactions but does not endorse, guarantee, or take responsibility
                                        for the quality, authenticity, or legality of the products sold by Sellers.
                                </p>
                                <h6>3.2 Payment Processing</h6>
                                <p>
                                        Bidsboxx facilitates payment processing and ensures that payments are managed in accordance with Bidsboxx’s payout schedule.
                                </p>
</div>
                          
                                <h5>4. Limitation of Liability</h5>
                                <p>
                                        Bidsboxx is not liable for any issues related to the quality, authenticity, or legality of the products sold by Sellers.
                                        The Buyer agrees to indemnify and hold Bidsboxx harmless from any claims or disputes arising out of or related to their participation in live sales.
                                </p>
                                <h5>5. Termination</h5>
                                <p>
                                        Bidsboxx reserves the right to suspend or terminate the Buyer’s account for non-compliance with this Agreement or for any misconduct related to submitting offers and purchases.
                                </p>
                                <h5>6. Amendments</h5>
                                <p>
                                        Bidsboxx may update this Agreement from time to time. Significant changes will be communicated to the Buyer.
                                </p>
                                <h5>7. Governing Law</h5>
                                <p>
                                        This Agreement shall be governed by and construed in accordance with the laws of [Your Jurisdiction].
                                        Any disputes arising under this Agreement shall be resolved in the courts located in [Your Jurisdiction].
                                </p>
                        </div>
                </div>
        )
}

export default BuyerPolicy