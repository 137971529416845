import React, { useRef, useState, useCallback, useReducer, useEffect } from 'react';
import Button from '../../CommonComponent/Button';
import Modal from 'react-bootstrap/Modal';
import InlineError from '../../CommonComponent/inlineError';
import '../../StyleComponent/AuthStyle.css'
import Input from '../../CommonComponent/Input';
import { apiService } from '../../Services/ApiServices';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg } from "../../CommonComponent/Helper";
import BtnLoader from '../../CommonComponent/BtnLoader';
import { IoCloseCircle } from "react-icons/io5";
import { checkFileSize } from '../../CommonComponent/Validation';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import { EditIcon } from '../../Svg';
import { FaPencil } from 'react-icons/fa6';


function EditProductModal(props) {
  console.log("propspropsprops",props)
  const logout = useLogout();
  const [btnLoader, setBtnLoader] = useState(false);
  const [newUrl, setNewUrl] = useState([]);
  const [cImage, setCImage] = useState('');
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const inputRef = useRef();
  const token = localStorage.getItem('token');
  const [document, setDocument] = useState([]);
  const [image, setImage] = useState([]);
  const [imageId, setImageId] = useState('');
  const [productName, setProductName] = useState('');
  const [productDetail, setProductDetail] = useState('');
  const [condition, setCondition] = useState('');
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [fabric, setFabric] = useState('');
  const [mrp, setMrp] = useState('');
  const [startPrice, setStartPrice] = useState('');
  const [bidAmountBoost, setBidAmountBoost] = useState('');
  const [isLinked, setIsLinked] = useState(0);
  const [isAllAdded, setisAllAdded] = useState(false);
  const [getAuctionList, setAuctionList] = useState({});
  const [auctionId, setAuctionId] = useState('');
  const [imageUpdate, setImageUpdate] = useState('');
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      list: {},
      isResponse: '',
      search: '',
      searchType: '',
      inactive: '',
      bP: '',
      totalCount: '',
      activePage: 1,
      dir: '',
      sortValue: '',
      type: '',
      image: ''

    }
  );

  const [category, setCategory] = useState();
  // const cropInitialState = {
  //   aspect: 16 / 15,
  //   width: 100,
  // }
  // const [crop, setCrop] = useState({
  //   aspect: 16 / 15,
  //   width: 100,
  // });


  // const cropInitialState = {
  //   unit: '%', // Can be 'px' or '%'
  //   width: 100,
  //   aspect: 9 / 16,
  // }

  // const [crop, setCrop] = useState(cropInitialState);


  const cropInitialState = { aspect: 16 / 15, width: 100 }

  const [crop, setCrop] = useState({ aspect: 16 / 15, width: 100 });

  const onSelectFile = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const checkSize = await checkFileSize(e);
      if (!checkSize) {
        setCImage(e.target.files)
        const reader = new FileReader();
        reader.addEventListener("load", () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
        setUserInput({
          documentErr: '',
        });
      } else {
        setCImage('');
        setNewUrl('');
        setUserInput({
          documentErr: "Image size must be less than 2 MB",
        });
      }
    }
  };

  const completedCrop = crop => {
    getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
      setNewUrl(res.url);
      setUserInput({ image: res.url });
    })
  }

  const handleFileUpload = () => {
    if (upImg) {
      setUpImg('')
      setCrop(cropInitialState)
      image.push({ "url": newUrl })
      getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
        document.push(res);
        addProductImage();
      })
    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const addProductImage = () => {
    try {
      let formData = new FormData();
      formData.append("image_upload_to", 1);
      document.forEach(element => {
        setImageUpdate(element)
        formData.append("image", element);
      });
      apiService.addAuctionImage(formData, token).then(result => {
        if (result.data?.status_code == 1) {
          setUserInput({ ...userInput, image: result.data?.response_data?.data.image });
        } else {
        }
      })
    } catch (e) {
      console.error('e', e);
    }
  }

  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      productName: '', productDetail: '', size: '', color: '', fabric: '',
      category:'',condition:'', mrp:'',isLinked:'',startPrice:'',bidAmountBoost:'',isLinked,
      auctionId:'' ,auction_start_price: '', bid_amount_boost: ''
    }
  );

  const handleValidate = () => {
    let validate = true;
    setError({});
    // if ((productName == undefined) || (productName.length == 0)) {
    //   setError({ productName: 'Product name is required' });
    //   validate = false
    // }
    // if (productName) {
    //   if (productName.length < 2) {
    //     setError({ productName: 'Product name must contain at least 2 characters' });
    //     validate = false
    //   } else if (productName.length > 30) {
    //     setError({ productName: 'Product name must contain only 30 characters' });
    //     validate = false
    //   } else {

    //   }
    // }

    const trimmedValueproductName = productName.trim(); // Trim only for validation purposes
    if (!trimmedValueproductName) {
      setError({ productName: 'Product name is required' });
      validate = false
    } else if (trimmedValueproductName.length <= 1) {
      setError({ productName: 'Product name must be between 2 and 30 characters' });
      validate = false
    } else {
     
    }
    // if ((productDetail == undefined) || (productDetail == '')) {
    //   setError({ productDetail: 'Product description is require' });
    //   validate = false
    // }
    // if (productDetail) {
    //   if (productDetail.length < 2) {
    //     setError({ productDetail: 'Product description must contain at least 2 characters' });
    //     validate = false
    //   } else if (productDetail.length > 90) {
    //     setError({ productDetail: 'Product description must contain only 90 characters' });
    //     validate = false
    //   } else {

    //   }
    // }


    const trimmedValueproductDetail = productDetail.trim(); // Trim for validation purposes only
    if (!trimmedValueproductDetail) {
      setError({ productDetail: 'Product description is required' });
      validate = false
    } else if (trimmedValueproductDetail.length <= 1) {
      setError({ productDetail: 'Product description must be between 2 and 90 characters' });
      validate = false
    } else {
    }

    if ((mrp == undefined) || (mrp.length == 0)) {
      setError({ mrp: 'Original MRP is required' });
      validate = false
    }
    if ((startPrice == undefined) || (startPrice.length == 0)) {
      setError({ startPrice: 'Start Price is required' });
      validate = false
    }
    if ((bidAmountBoost == undefined) || (bidAmountBoost.length == 0)) {
      setError({ bidAmountBoost: 'Offer Amount Boost is required' });
      validate = false
    }
    // if ((isLinked == undefined) || (isLinked.length == 0) || (isLinked == '')){
    //   setError({ isLinked: 'Status is required' });
    //   validate = false
    // }
    // if((isLinked == "true") && getAuctionList.length>0){
    //   if ((auctionId == undefined) || (auctionId.length == 0) || (auctionId == '')){
    //     setError({ auctionId: 'Status detail is required' });
    //     validate = false
    //   }
    // }

    return validate;
  }

  const addProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (handleValidate() == true) {
        setBtnLoader(true);
        let req = {
          "name": productName,
          "description": productDetail,
          "category": category,
          "condition": condition,
          "no_exchange_refund": condition,
          "size": size,
          "color": color,
          "product_id": imageId,
          "type": fabric,
          "original_mrp": mrp,
          "auction_start_price": startPrice,
          "bid_amount_boost": bidAmountBoost,
          auction_id: auctionId
        }

        if ((isLinked == "1") || (isLinked == 1)) {
          req.is_linked = true;
          req.auction_id = auctionId;
        } else {
          req.is_linked = false;
        }

        if (imageUpdate) {
          req.image = userInput.image;
        }
        console.log("req_______________",req, auctionId)
        apiService.editProduct(req, token).then(res => {
          if (res?.data?.status_code == 1) {
            props.getProduct();
            props.updatedProductList();
            props.onlyClose(false);
            props.onHide1();
          } else {
            if (res?.data?.status_code != 1) {
              notify("err", res?.data?.error?.message?.error);
              if(res?.data?.status_code == 2)
                logout();

              if (res?.data?.status_code == 5)
                logout();
              if (res?.data?.status_code == 4)
                logout();
            }
          }
        })

      } else {
        // props.onHide();
      }
    } catch (e) {
      console.error('e', e);
    }
  }

  // const productNameHandleChange = (e) => {
  //   let name =  e.target.value.trim();
  //   setProductName(name);
  //   if ((name == undefined) || (name.length == 0)) {
  //     setError({ productName: 'Product name is required' });
  //   } else if ((name.length > 0) && (name.length <= 1)) {
  //     setError({ productName: 'Product name must be between 2 and 90 characters' });
  //   } else {
  //     setError({ ...error, [e.target.name]: '', });
  //   }
  // };

  const productNameHandleChange = (e) => {
    const rawValue = e.target.value; // Get the raw value, including spaces
    setProductName(rawValue); // Update the state with the untrimmed value
  
    const trimmedValue = rawValue.trim(); // Trim only for validation purposes
    if (!trimmedValue) {
      setError({ productName: 'Product name is required' });
    } else if (trimmedValue.length <= 1) {
      setError({ productName: 'Product name must be between 2 and 30 characters' });
    } else {
      setError({ ...error, [e.target.name]: '' }); // Clear error
    }
  };

  // const productDetailHandleChange = (e) => {
  //   let name =  e.target.value.trim();
  //   setProductDetail(name);
  //   if ((name == undefined) || (name.length == 0)) {
  //     setError({ productDetail: 'Product description is required' });
  //   } else if ((name.length > 0) && (name.length <= 1)) {
  //     setError({ productDetail: 'Product description must contain at least 2 characters' });
  //   } else if (name.length > 90) {
  //     setError({ productDetail: 'Product description must be between 2 and 90 characters' });
  //   } else {
  //     setError({ ...error, [e.target.name]: '', });
  //   }
  // };

  const productDetailHandleChange = (e) => {
    const rawValue = e.target.value; // Keep the raw value for the input field
    setProductDetail(rawValue); // Update the state with the untrimmed value
  
    const trimmedValue = rawValue.trim(); // Trim for validation purposes only
    if (!trimmedValue) {
      setError({ productDetail: 'Product description is required' });
    } else if (trimmedValue.length <= 1) {
      setError({ productDetail: 'Product description must be between 2 and 90 characters' });
    } else {
      setError({ ...error, [e.target.name]: '' }); // Clear the error for the current field
    }
  };
  

  const onChangeCategory = (e) => {
    setCategory(e.target.value)
    auctionList(e.target.value)
  };

  const onChangeCondition = (e) => {
    setCondition(e.target.value)
  };

  const onChangeMrp = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, ''); // Remove non-digits
    setMrp(sanitizedValue);
    // Initialize error state
    let errors = { mrp: '', startPrice: '' };
    if (sanitizedValue.length === 0) {
      errors.mrp = 'Original MRP is required';
    } else {
      if (parseInt(sanitizedValue) === 0) {
        errors.mrp = 'Original MRP should not be zero';
      }

      if (startPrice.length > 0 && parseInt(sanitizedValue) < parseInt(startPrice)) {
        errors.startPrice = 'Start price should not be more than original MRP';
      }
    }
    setError(errors);
  };

  const onChangeStartPrice = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Remove non-digits
    setStartPrice(sanitizedValue)
    if (sanitizedValue.length > 0) {
      if (parseInt(sanitizedValue) > parseInt(mrp)) {
        setError({ startPrice: 'Start price should not more than original MRP' });
      }
      else if (parseInt(sanitizedValue) == 0) {
        setError({ startPrice: 'Start price should not be zero' });
      }
      else {
        setError({ startPrice: '' });
      }
    } else {
      setError({ startPrice: 'Start price is required' });
    }
  };
  const onChangeBidAmountBoost = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Remove non-digits
    setBidAmountBoost(sanitizedValue)
    if (sanitizedValue.length > 0) {
      setError({ bidAmountBoost: '' });
    } else {
      setError({ bidAmountBoost: 'Offer amount boost is required' });
    }
  };

  const onChangeIsLinked = (e) => {
    setIsLinked(e.target.value)
  };

  const checkFormCompletion = () => {
    if (productName && productDetail && (category >= 0) && ((condition == 0) || (condition == 1)) && mrp && startPrice && bidAmountBoost && imageId) {
      if ((isLinked == "1" || isLinked == 1) && auctionId) {
        setisAllAdded(true);
      }
      else if ((isLinked == "0" || isLinked == 0)) {
        setisAllAdded(true);
      }
      else {
        setisAllAdded(false);
      }
    } else {
      setisAllAdded(false);
    }
  };

  useEffect(() => {
    checkFormCompletion()
  }, [productName, productDetail, category, condition, size, color, fabric, mrp, startPrice, bidAmountBoost, imageId, isLinked, auctionId])

  useEffect(() => {
    if (props.productId)
      getProduct()
  }, [props.productId])


  const getProduct = () => {
    setUserInput({ isLoader: true });
    let productId = props.productId;
    apiService.getProduct(productId, token).then(res => {
      if (res?.data?.status_code == 1) {
        let data = res?.data?.response_data.product_details;
        setUserInput({ data: res?.data?.response_data.product_details, image: res?.data?.response_data.product_details.image, totalCount: res?.data.response_data.count, isLoader: false })
        setProductName(data.name);
        setProductDetail(data.description);
        setCategory(data.category);
        setCondition(data.condition);
        setSize(data.size);
        setColor(data.color);
        setFabric(data.type);
        setImageId(data.id);
        setMrp(data.original_mrp);
        setStartPrice(data.auction_start_price);
        setBidAmountBoost(data.bid_amount_boost);
        if (data.is_linked)
          setIsLinked(1);
        else
          setIsLinked(0);
        setAuctionId(data?.auction?.id)

      }
      else {
        setUserInput({
          loading: false
        })
      }
    })
  }


  const auctionList = (categorieid) => {
    let req = {
      "categorie_id": categorieid ? Number(categorieid) : 0
    };
    apiService.auctionList(req, token).then(res => {
      if (res?.data?.status_code == 1) {
        setAuctionList(res?.data?.response_data.auction_list);
      }
      else {

      }
    })
  }

  useEffect(() => {
    auctionList(category);
  }, [category])


  const getAuctionId = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      setAuctionId(e.target.value);
    } else {
      setAuctionId('')
    }
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        className='cstm_modal_product'
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>

          <div className='close_icon' onClick={props.onHide}><span><IoCloseCircle /></span></div>
          <div className='_add_product_modal' style={{minHeight: '575px'}}>
          {
                upImg ? <>
                  <div className='crop_img' style={{ position: 'relative',  margin: '0px auto' }}>
                  <div className="text-center new_crop_btn mb-3">
                      {upImg ?
                        <Button name={"Crop"} className="done_btn" onClick={handleFileUpload} />
                        : ''}
                    </div>
                    <ReactCrop
                      src={upImg}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={c => setCrop(c)}
                      onComplete={completedCrop}
                    />
                   
                  </div>
                </> : 
            <>
            <div className='profile__step_header text-center'>
              <span className='signup_stps_txt avenir_black w-100'>Edit Product</span>
            </div>
            <form className='form_box'>

              <div className='auction_store_img'>
                <label>
                  {(userInput?.image) ?
                    <img src={userInput?.image} className='selected_img' />
                    :
                    <img src='assets/photo_upld.svg' height={40} width={50} />
                  }
                  <input type="file" size="60" name="document" id="file-input" ref={inputRef} onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                  
                  {!(userInput?.image) ?
                      <span className='auction_pls_icn avenir_medium'>+</span>
                    :
                    <span className='auction_pls_icn avenir_medium _edit'  title="160*240"><FaPencil/></span>
                  }
               
               
                </label>
              </div>
             
              <span className="upload_store_logo_danger avenir_heavy">{userInput.documentErr}</span>
              <div className='row mt-4'>
                <div className='col-sm-12'>
                  <Input
                    name="productName"
                    value={productName}
                    placeholder="Product Name"
                    className="form-control"
                    maxLength={30}
                    minLength={10}
                    onChange={productNameHandleChange}
                  />
                  <InlineError className='store_error' errorMessage={error.productName} />
                </div>

              </div>
              <div className='row mt-4'>
                <div className='col-sm-12'>
                  <Input
                    name="productDetail"
                    value={productDetail}
                    placeholder="Product Description"
                    className="form-control"
                    maxLength={90}
                    minLength={10}
                    onChange={productDetailHandleChange}
                  />
                  <InlineError className='store_error' errorMessage={error.productDetail} />
                </div>
                {/* <label>Please describe your product details.</label> */}
              </div>
              <h5 className='mt-5 mb-4'>Product Category</h5>
              <div className='row'>
                <div className='col-sm-12'>
                  {/* <label className='mt-1 mb-3'>Choose the product’s category</label> */}
                  <div className='wrap_radio mb-0'>
                    {props?.categories?.map(categoryItem => (
                      <div key={categoryItem.id}>
                        <input
                          type="radio"
                          className="radio_inpt"
                          value={categoryItem.id.toString()}
                          name="category"
                          checked={category == categoryItem.id.toString()}
                          onChange={onChangeCategory}
                        />
                        <span className=''>{categoryItem.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <h3 className='mt-4'>Other Details</h3> */}
              <h5 className='mt-3 '>No exchange/No refund</h5>
              <div className='row mt-3'>
                <div className='col-sm-12'>
                  <div className='wrap_radio mb-0'>
                    <div className='_radio_box'>
                      <input
                        type="radio"
                        className="radio_inpt"
                        name="condition"
                        checked={condition == 1}
                        value={1}
                        onChange={onChangeCondition}
                      />
                      <span className=' '>Yes</span>
                    </div>
                    <div className='_radio_box'>
                      <input
                        type="radio"
                        className="radio_inpt"
                        name="condition"
                        checked={condition == 0}
                        value={0}
                        onChange={onChangeCondition}
                      />
                      <span className=' '>No</span>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className='mt-3 '>Product Condition</h5>
              <div className='mt-3' style={{fontWeight:300}}>
                <input type="checkbox" id="asShownOnLive" name="asShownOnLive" defaultChecked disabled />&nbsp;&nbsp;&nbsp;&nbsp;As shown on live
              </div>
              <div className='row mt-5'>
                <div className='col-sm-6'>
                  <Input
                    name="originalMrp"
                    className='form-control'
                    placeholder="Original MRP"
                    onChange={onChangeMrp}
                    value={mrp}
                    maxLength={20}
                    minLength={1}
                  />
                  <InlineError className='store_error' errorMessage={error.mrp} />
                </div>
                <div className='col-sm-6'>
                  <Input
                    name="auctionStartPrice"
                    className='form-control'
                    placeholder="Auction Start Price"
                    onChange={onChangeStartPrice}
                    value={startPrice}
                    maxLength={20}
                    minLength={1}
                  />
                  <InlineError className='store_error' errorMessage={error.startPrice} />
                </div>
              </div>
              <div className='row mt-5'>
                <div className='col-sm-12'>
                  <Input
                    name="bidAmountBoost"
                    value={bidAmountBoost}
                    placeholder="Bid Amount Boost"
                    className="form-control"
                    onChange={onChangeBidAmountBoost}
                    maxLength={20}
                    minLength={1}
                  />
                  <InlineError className='store_error' errorMessage={error.bidAmountBoost} />
                </div>
                {/* <label>Specify the amount you want to increase at the time of bidding</label> */}
              </div>
              <div className='row mt-5'>
                <div className='col-sm-10'>
                  <h5>Status</h5>
                  <div className='wrap_radio mb-0 mt-3'>
                    <div className='_radio_box'>
                      <input
                        type="radio"
                        className="radio_inpt"
                        name="linked"
                        value={1}
                        checked={isLinked == 1}
                        onChange={onChangeIsLinked}
                      />
                      <span className=''>Link to Live</span>
                    </div>
                    <div className='_radio_box'>
                      <input
                        type="radio"
                        className="radio_inpt"
                        value={0}
                        name="linked"
                        checked={isLinked == 0}
                        onChange={onChangeIsLinked}
                      />
                      <span className=''>Not Scheduled for Live</span>
                    </div>
                  </div>
                  {/* <InlineError className='store_error' errorMessage={error.isLinked} /> */}
                </div>
                <div className='col-sm-12 mt-4'>
                  {
                    (isLinked == 1) ?
                    <>
                      <select
                        name="auctionList"
                        className='form-select'
                        onChange={(e) => getAuctionId(e)}
                        value={auctionId ? auctionId : ''}
                      >
                        <option >List of Lives</option>
                        {
                          getAuctionList.length ?
                            <>
                              {getAuctionList.map(auction => (
                                <option key={auction.id} value={auction.id}>
                                  {auction.description}
                                </option>
                              ))}
                            </>
                            :
                            <></>
                        }
                      </select>
                      {/* {getAuctionList.length && <InlineError className='store_error' errorMessage={error.auctionId}/>} */}
                      </>
                      :
                      <></>
                  }
                </div>
              </div>
              <div className='mt-4 text-center'>
                {/* {
                  (isAllAdded == true) ? */}
                 
                    <Button
                      name={btnLoader ? <BtnLoader /> : "Submit"}
                      onClick={(e) => addProduct(e)}
                    />
                    {/* :
                    <Button
                      className="mt-4"
                      name={"Submit"}
                      disabled="true"
                    />
                } */}
              </div>
            </form>
            </>  }
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default EditProductModal;