import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function SellerPolicy() {
        const navigate = useNavigate();
        const goBack = () => { navigate(-1); };

        useEffect(() => {
                window.scrollTo(0, 0)
        }, [])

        return (
                <div className='privacy_policy_parent '>
                        <div className='header'>
                                <span onClick={goBack}><MdArrowBackIos /></span>
                                <Link to="/">  <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
                        </div>
                        <div className='privacy_policy '>
                                <h4>For Seller</h4>
                                <div className='content'>
                                        <h5>1. Registration and Account Setup</h5>
                                        <div className='inner_content'>
                                                <h6>1.1. Account Creation:</h6>
                                                <p>To sell on Bidsboxx, you must create a seller account by providing accurate and complete information as requested during the registration process.</p>
                                                <h6>1.2. Verification:</h6>
                                                <p>Bidsboxx reserves the right to verify the information provided and may request additional documentation to confirm your identity and eligibility to sell. All sellers are verified based on their GST certificates.</p>

                                        </div></div>

                                <div className='content'>
                                        <h5>2. Listing Products</h5>
                                        <div className='inner_content'>
                                                <h6>2.1. Product Listings:</h6>
                                                <p>Sellers are responsible for accurately listing their products, including detailed descriptions, images, and any relevant information. Sellers are responsible for all aspects of the sales process, including initiating live sales, dispatching products, displaying details and features, providing descriptions, and responding to customer questions and inquiries about the products.</p>
                                                <h6>2.2. Compliance:</h6>
                                                <p>All products listed must comply with Bidsboxx’s policies, including legal and regulatory requirements. Prohibited items or services will not be allowed.</p>
                                                <h6>2.3. Pricing:</h6>
                                                <p>Sellers must set a fair and reasonable starting offer price for their products. Bidsboxx reserves the right to review and adjust listings if deemed necessary.</p>

                                        </div></div>

                                <div className='content'>
                                        <h5>3. Live Sales</h5>
                                        <div className='inner_content'>

                                                <h6>3.1. Live Presentation:</h6>
                                                <p>Sellers must conduct live sales in a professional manner, providing clear and accurate information about the products being showcased.</p>
                                                <h6>3.2. Availability:</h6>
                                                <p>Products offered during live sales must be available for purchase. Sellers are responsible for ensuring inventory levels match the items listed.</p>
                                        </div></div>

                                <div className='content'>
                                        <h5>4. Transactions and Payments</h5>
                                        <div className='inner_content'>

                                                <h6>4.1. Purchase Process:</h6>
                                                <p>Once a purchase is made, sellers will be notified. The seller must honor the sale if the purchase is successful and proceed with the transaction.</p>
                                                <h6>4.2. Payment Processing:</h6>
                                                <p>Bidsboxx will facilitate payment processing. Sellers will receive payment according to Bidsboxx’s payout schedule, minus any applicable fees.</p>
                                        </div></div>

                                <div className='content'>
                                        <h5>5. Shipping and Delivery</h5>
                                        <div className='inner_content'>

                                                <h6>5.1. Order Fulfillment:</h6>
                                                <p>Sellers must ensure timely and accurate shipping of products. Orders should be dispatched within the specified timeframe.</p>
                                                <h6>5.2. Shipping Costs:</h6>
                                                <p>Sellers are responsible for determining and covering shipping costs unless otherwise specified.</p>
                                        </div></div>

                                <div className='content'>
                                        <h5>6. Cancellation and Returns</h5>
                                        <div className='inner_content'>

                                                <h6>6.1. Cancellation:</h6>
                                                <p>Sellers must accept cancellations of orders before the product is dispatched. Once dispatched, cancellations are subject to Bidsboxx’s return policy.</p>
                                                <h6>6.2. Returns and Refunds:</h6>
                                                <p>Sellers must adhere to Bidsboxx’s return and refund policies, providing a fair and transparent process for buyers.</p>
                                        </div></div>

                                <div className='content'>
                                        <h5>7. Seller Responsibilities</h5>
                                        <div className='inner_content'>

                                                <h6>7.1. Product Quality:</h6>
                                                <p>Sellers are solely responsible for the quality, condition, and authenticity of the products they offer. Bidsboxx is not liable for any issues related to product quality, defects, or misrepresentations.</p>
                                                <h6>7.2. Customer Service:</h6>
                                                <p>Sellers must provide prompt and courteous customer service, addressing any inquiries or issues raised by buyers.</p>
                                        </div></div>

                                <div className='content'>
                                        <h5>8. Compliance and Termination</h5>
                                        <div className='inner_content'>

                                                <h6>8.1. Compliance:</h6>
                                                <p>Sellers must comply with all Bidsboxx policies and any applicable laws and regulations.</p>
                                                <h6>8.2. Termination:</h6>
                                                <p>Bidsboxx reserves the right to suspend or terminate a seller’s account for non-compliance with these terms or any misconduct.</p>
                                        </div></div>

                                <div className='content'>
                                        <h5>9. Changes to Terms</h5>
                                        <div className='inner_content'>

                                                <h6>9.1. Modifications:</h6>
                                                <p>Bidsboxx may update these terms and conditions from time to time. Sellers will be notified of any significant changes.</p>
                                        </div></div>

                                <div className='content'>
                                        <h5>10. Contact Information</h5>
                                        <div className='inner_content'>

                                                <h6>10.1. Support:</h6>
                                                <p>For any questions or concerns regarding these terms and conditions, sellers should contact Bidsboxx’s support team.</p>
                                        </div></div>

                                <hr />
                                <h4>Seller Code of Conduct</h4>
                                <p>
                                        To ensure a safe and reliable marketplace on Bidsboxx, all sellers are required to follow these guidelines:
                                </p>
                                <ul>
                                        <li>Provide accurate information to Bidsboxx and our customers at all times.</li>
                                        <li>Act fairly and not misuse Bidsboxx’s features or services.</li>
                                        <li>Not attempt to damage or abuse another seller, their listings, or ratings.</li>
                                        <li>Not attempt to influence customers’ ratings, feedback, and reviews.</li>
                                        <li>Not send unsolicited or inappropriate communications.</li>
                                        <li>Not contact customers except through Bidsboxx’s Buyer-Seller Messaging.</li>
                                        <li>Not attempt to circumvent the Bidsboxx sales process.</li>
                                        <li>
                                                Not operate more than one selling account on Bidsboxx without a legitimate business need.
                                        </li>
                                </ul>
                                <h5>Consequences of Violations</h5>
                                <p>
                                        Failing to adhere to these policies may result in serious consequences, including the cancellation of your
                                        listings, suspension or forfeiture of payments, and removal of your selling privileges.
                                </p>
                                <hr />
                                <h4>Seller Terms and Conditions</h4>
                                <h5>Content Disclaimer</h5>
                                <p>
                                        While Bidsboxx makes reasonable efforts to ensure the accuracy and completeness of the information on our
                                        website, we do not guarantee its accuracy or usefulness. Information provided on this site is given in good
                                        faith and may include third-party content on an "as-is" basis.
                                </p>
                                <h5>Disclaimer of Warranties and Limitation of Liabilities</h5>
                                <p>
                                        Bidsboxx provides this website on an "as is" and "as available" basis. We make no warranties, express or
                                        implied, about the operation of this website or the information, content, materials, or products included.
                                </p>
                                <h5>Indemnity and Release</h5>
                                <p>
                                        You agree to indemnify and hold harmless Bidsboxx, its affiliates, and their officers, directors, agents, and
                                        employees from any claims or demands, including reasonable legal fees, arising from your breach of these Terms
                                        or any applicable laws or regulations.
                                </p>
                                <h5>Sanctions and Export Policy</h5>
                                <p>
                                        You may not use this website if you are subject to U.S. sanctions or sanctions imposed by your country that are
                                        consistent with U.S. law.
                                </p>
                                <h5>Governing Law and Jurisdiction</h5>
                                <p>
                                        These Terms are governed by the laws of India. You agree to submit to the exclusive jurisdiction of the courts
                                        in New Delhi.
                                </p>
                                <h5>Seller Registration</h5>
                                <p>
                                        If you register as a seller on www.Bidsboxx.in ("Marketplace"), your activities will be governed by the
                                        Marketplace Conditions of Use and any relevant policies.
                                </p>
                                <h5>Events Beyond Our Control</h5>
                                <p>
                                        Bidsboxx is not responsible for any delays or failures to meet our obligations under these Terms due to events
                                        beyond our reasonable control.
                                </p>
                                <h5>Waiver</h5>
                                <p>
                                        If Bidsboxx does not immediately enforce any part of these Terms, it does not waive our right to enforce those
                                        Terms in the future.
                                </p>
                                <hr />
                                <h4>Seller Contract</h4>
                                <p>
                                        This Seller Contract ("Contract") is entered into by and between Bidsboxx ("Platform") and the undersigned
                                        seller ("Seller") as of the date of acceptance by the Seller.
                                </p>
                                <h5>1. Purpose</h5>
                                <p>
                                        This Contract outlines the terms and conditions under which the Seller will list and sell products on the
                                        Bidsboxx platform. Bidsboxx provides an online platform for live sales but does not assume any responsibility
                                        for the products sold by the Seller.
                                </p>
                                <h5>2. Seller Responsibilities</h5>
                                <ul>
                                        <li>Product Authenticity: Ensure that all products listed and sold on Bidsboxx are authentic and as described.</li>
                                        <li>Product Quality: Guarantee high-quality products in good condition, conforming to descriptions and images.</li>
                                        <li>Listing Accuracy: Provide accurate and complete descriptions, images, and details of products.</li>
                                        <li>Compliance with Laws: Adhere to all applicable laws concerning product sales.</li>
                                        <li>Customer Service: Provide prompt and courteous customer service to buyers.</li>
                                        <li>Shipping and Delivery: Ensure timely and accurate shipping of products.</li>
                                        <li>
                                                Returns and Refunds: Process returns and refunds in adherence to Bidsboxx’s policies.
                                        </li>
                                </ul>
                                <h5>3. Platform Responsibilities</h5>
                                <ul>
                                        <li>
                                                Platform Provision: Bidsboxx provides an online platform for live sales but does not endorse, guarantee, or
                                                take responsibility for the quality or authenticity of products.
                                        </li>
                                        <li>Payment Processing: Facilitates payment processing according to payout schedules.</li>
                                </ul>
                                <h5>4. Limitation of Liability</h5>
                                <p>
                                        Bidsboxx is not liable for any issues related to product quality, authenticity, or defects. The Seller agrees to
                                        indemnify and hold Bidsboxx harmless from any claims or disputes.
                                </p>
                                <h5>5. Termination</h5>
                                <p>
                                        Bidsboxx reserves the right to suspend or terminate the Seller’s account for non-compliance with this Contract
                                        or for misconduct related to product sales.
                                </p>
                                <h5>6. Amendments</h5>
                                <p>
                                        Bidsboxx may update this Contract from time to time. Significant changes will be communicated to the Seller.
                                </p>
                                <h5>7. Governing Law</h5>
                                <p>
                                        This Contract shall be governed by and construed in accordance with the laws of India. Any disputes shall be
                                        resolved in the courts located in New Delhi.
                                </p>
                        </div>
                </div>
        )
}

export default SellerPolicy