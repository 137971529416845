/**
 * @about
 * This file conatins api end points
 */

import siteSetting from "../Component/Config/env/Index";

export default {
  'AUTH': siteSetting.api.AUTH,
  'LOGIN': siteSetting.api.url + "admin/login",
  'SEND_OTP': siteSetting.api.url + "seller/send_otp/",
  'SEND_OTP_NEW': siteSetting.api.url + "seller/send_otp_for_number/",
  'REGISTER_FIRST_STEP': siteSetting.api.url + "seller/sign_up/",
  'VERIFY_OTP': siteSetting.api.url + "seller/verify_otp/",
  'VERIFY_OTP_FOR_NUMBER': siteSetting.api.url + "seller/verify_otp_for_number/",
  'SET_USERNAME': siteSetting.api.url + "seller/add_username/",
  'ADD_STORE_INFO': siteSetting.api.url + "seller/store_register/",
  'CHECK_GST_VERIFICATION': siteSetting.api.url + "seller/gst_verification/",
  'CATEGORY_LIST': siteSetting.api.url + "product/get_product_categories/",
  'ADD_CATEGORY': siteSetting.api.url + "seller/add_categories/",
  'USER_LOGIN': siteSetting.api.url + "seller/sign_in/",
  'CHECK_IFSC_CODE': siteSetting.api.url + "seller/check_ifsc/",
  'ADD_BANK_ACCOUNT': siteSetting.api.url + "seller/add_bank/",
  'SET_NEW_PASSWORD': siteSetting.api.url + "seller/set_new_password/",
  'SETTING_NOTIFICATION': siteSetting.api.url + "seller/setting/",
  'GET_SETTING_NOTIFICATION': siteSetting.api.url + "seller/get_setting/",
  'DELETE_SELLER': siteSetting.api.url + "seller/seller_delete/",
  'LOGOUT': siteSetting.api.url + "seller/logout/",
  'CHECK_USERNAME': siteSetting.api.url + "seller/check_username/",
  'ADD_STORE_IMAGE': siteSetting.api.url + "seller/store_image/",
  'ADD_AUCTION': siteSetting.api.url + "product/add_auction/",
  'ADD_AUCTION_IMAGE': siteSetting.api.url + "product/upload_image/",
  'AUCTION_LIST': siteSetting.api.url + "product/get_auction_list/",
  'GET_AUCTION': siteSetting.api.url + "product/{{id}}/get_auction_detail/",
  'EDIT_AUCTION': siteSetting.api.url + "product/edit_auction/",
  'GET_AUCTION_SLOT': siteSetting.api.url + "product/get_auction_slot/",
  'ADD_PRODUCT': siteSetting.api.url + "product/add_product/",
  'PRODUCT_LIST': siteSetting.api.url + "product/get_product_list/",
  'GET_PRODUCT': siteSetting.api.url + "product/{{id}}/get_product_detail/",
  'EDIT_PRODUCT': siteSetting.api.url + "product/edit_product/",
  'ADD_PRODUCT_TO_AUCTION': siteSetting.api.url + "product/add_product_to_auction/",
  'AUCTION_PRODUCT_LIST': siteSetting.api.url + "product/auction_product_list/",
  'REORDER_PRODUCT': siteSetting.api.url + "product/reorder_product/",
  'MEETING_SESSION_API': siteSetting.api.url + "product/get_video_obj/",
  'ATTENDEE_ADD_API': siteSetting.api.url + "product/create_attendees/",
  'ORDER_LIST': siteSetting.api.url + "product/order_list/",
  'DASHBOARD_LIST': siteSetting.api.url + "seller/dashboard_seller/",
  'ORDER_LIST_FILTER': siteSetting.api.url + "product/order_filter/",
  'ORDER_DETAIL': siteSetting.api.url + "product/order_detail/",
  'CANCEL_ORDER': siteSetting.api.url + "product/seller_order_cancel/",
  'APPROVED_CANCELLATION': siteSetting.api.url + "product/approved_cancellation/",
  'REJECT_CANCELLATION': siteSetting.api.url + "product/reject_cancellation/",
  'AUCTION_SETUP': siteSetting.api.url + "product/auction_setup/",
  'GET_LABEL': siteSetting.api.url + "product/label/",
  'ORDER_RETURN': siteSetting.api.url + "product/order_return/",
  'ADD_YOUTUBE_URL': siteSetting.api.url + "product/start_recording/",
  'STOP_YOUTUBE_URL': siteSetting.api.url + "product/stop_recording/",
  'SET_YOUTUBE_URL': siteSetting.api.url + "product/set_youtube_url/",
  'GET_USER_DETAILS': siteSetting.api.url + "seller/seller_user_details/",
  'GET_NOTIFICATION_DETAILS': siteSetting.api.url + "product/notification_seller/",
  'EDIT_USER_DETAILS': siteSetting.api.url + "seller/edit_user_details/",
  'GET_STORE_DETAILS': siteSetting.api.url + "seller/store_detail/",
  'GET_YOUTUBE_URL': siteSetting.api.url + "product/get_youtube_url/",
  'GET_DOWNLOAD_ORDER_INVOICE': siteSetting.api.url + "product/download_invoice/",
  'NOTIFICATION_READ': siteSetting.api.url + "product/notification_read/",
  'VERIFY_OTP_FOR_EMAIL': siteSetting.api.url + "seller/verify_email_otp/",
  'SEND_OTP_FOR_EMAIL': siteSetting.api.url + "seller/send_otp_to_email/",
   'ADD_FCM_TOKEN': siteSetting.api.url + "seller/edit_fcm_token/"
}