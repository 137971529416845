import React, { useEffect, useState } from 'react';
import { NavLink as Link, useLocation } from "react-router-dom";
import "../StyleComponent/Sidebar.scss"
import { hideNotification } from '../Component/Redux/Sliceses/notificationSlice';
import { useDispatch } from 'react-redux';

function Sidebar(props) {
    const pathname = useLocation();
    const dispatch = useDispatch();
    const currentPath = pathname.pathname.split("/")[1]
    const [path, setPath] = useState('');
    const [active, setActive] = useState(currentPath);
    useEffect(() => {
        localStorage.getItem("SidebarActive")
            ? setActive(localStorage.getItem("SidebarActive"))
            : setActive("Dashboard");
    })

    useEffect(() => {
        const handlePathChange = (newPath) => {
            // props.noticationList()
        };
        if (currentPath !== path) {
            setPath(currentPath);
            handlePathChange(path);
        }

    }, [currentPath])
    const CloseNotification = () => {
        dispatch(hideNotification());
    }
    useEffect(() => {
        CloseNotification()
    }, [])

    return (
        <div className="custom_sidebar" >
            <div className='sidebar_box avenir_heavy'>
                <Link onClick={CloseNotification} to="/dashboard" id="Dashboard">
                    <div
                        id="Dashboard"
                        className={
                            currentPath == "dashboard"
                                ? "side_list_active"
                                : "side_list "
                        }
                    >
                        <img
                            id="Dashboard"
                            src={
                                currentPath == "dashboard"
                                    ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/dashboard-active.svg"
                                    : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/dashboard_PHL2vkh.svg"
                            }
                            alt="Dashboard"
                        />
                        Dashboard
                    </div>
                </Link>
                <Link onClick={CloseNotification} to="/personal_details" id="PersonalDetails" className="hide_navigation">
                    <div
                        id="PersonalDetails"
                        className={
                            currentPath === "personal_details"
                                ? "side_list_active"
                                : "side_list "
                        }
                    >
                        <img
                            id="PersonalDetails"
                            src={
                                currentPath === "personal_details"
                                    ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/user-active.svg"
                                    : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/PersonalDetails.svg"
                            }
                            alt="Personal Details"
                        />
                        Personal Details
                    </div>
                </Link>
                <Link onClick={CloseNotification} to="/auction" id="Auction">
                    <div
                        id="Auction"
                        className={
                            currentPath === "auction"
                                ? "side_list_active"
                                : "side_list"
                        }
                    >
                        <img
                            id="Auction"
                            src={
                                currentPath === "auction"
                                    ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/auction-active.svg"
                                    : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/Auction.svg"
                            }
                            alt="Auction"
                        />
                        Live
                    </div>
                </Link>
                <Link onClick={CloseNotification} to="/product" id="Product">
                    <div
                        id="Product"
                        className={
                            currentPath === "product"
                                ? "side_list_active"
                                : "side_list"
                        }
                    >
                        <img
                            id="Product"
                            src={
                                currentPath === "product"
                                    ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/store-active.svg"
                                    : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/Product.svg"
                            }
                            alt="Product"
                        />
                        Product
                    </div>
                </Link>
                <Link onClick={CloseNotification} to="/orders" id="Orders">
                    <div
                        id="Orders"
                        className={
                            currentPath === "orders"
                                ? "side_list_active"
                                : "side_list"
                        }
                    >
                        <img
                            id="Orders"
                            src={
                                currentPath === "orders"
                                    ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/wallet-active.svg"
                                    : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/Orders.svg"
                            }
                            alt="Orders"
                        />
                        Orders
                    </div>
                </Link>

                <Link onClick={CloseNotification} to="/store" id="StoreInformation" className="hide_navigation">
                    <div
                        id="StoreInformation"
                        className={
                            currentPath === "store"
                                ? "side_list_active"
                                : "side_list "
                        }
                    >
                        <img
                            id="StoreInformation"
                            src={
                                currentPath === "store"
                                    ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/shop.svg"
                                    : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/StoreInformation.svg"
                            }
                            alt="Store Information"
                        />
                        Store Information
                    </div>
                </Link>
                <Link onClick={CloseNotification} to="/account_details" id="AccountDetails" className="hide_navigation">
                    <div
                        id="AccountDetails"
                        className={
                            currentPath === "account_details"
                                ? "side_list_active"
                                : "side_list "
                        }
                    >
                        <img
                            id="AccountDetails"
                            src={
                                currentPath === "account_details"
                                    ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/credit-card-active.svg"
                                    : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/AccountDetails.svg"
                            }
                            alt="Account Details"
                        />
                        Account Details
                    </div>
                </Link>
                <Link onClick={CloseNotification} to="/settings" id="Settings" className="hide_navigation">
                    <div
                        id="Settings"
                        className={
                            currentPath === "settings"
                                ? "side_list_active"
                                : "side_list hide_navigation"
                        }
                    >
                        <img
                            id="Settings"
                            src={
                                currentPath === "settings"
                                    ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/settings-active.svg"
                                    : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/setting.svg"
                            }
                            alt="Settings"
                        />
                        Settings
                    </div>
                </Link>
                <Link onClick={CloseNotification} to='/more' >
                    <div

                    >
                        <div className={
                            currentPath === "more"
                                ? "side_list_active"
                                : "side_list more_btn"
                        }
                        >
                            <img
                                id="more"
                                src={
                                    currentPath === "more"
                                        ? "https://d2ugmqijh93rc0.cloudfront.net/temp/images/More.svg"
                                        : "https://d2ugmqijh93rc0.cloudfront.net/temp/images/More.svg"
                                }
                                alt="more"
                            />
                            More
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Sidebar