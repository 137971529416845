import React, { useState, useEffect } from 'react';
import { Coin, MuteIcon, WinnerIcon } from '../Svg'
import Used_tag from '../assets/used.svg'
import Button from '../CommonComponent/Button';
import { useNavigate, useLocation } from 'react-router';
import livesvg from '../assets/livesvg.svg';
import * as Chime from 'amazon-chime-sdk-js';
import moment from 'moment';
import UnMute from '../assets/un-mute.svg';
import socket from '../Socket/io';
import { apiService } from '../Services/ApiServices';
import winerAnimation from '../assets/winerAnimation.json';
import Lottie from 'react-lottie';
import { notify } from '../CommonComponent/Toaster';
import ProductList from './ProductList';
import { GiveawaySkeleton } from '../CommonComponent/Skeleton';
import { toast } from 'react-toastify';
import winimage from '../assets/win.svg'
import unsold from '../assets/unsold-product.jpg'
import SlotAnimation from './SlotAnimation';
import CameraIcon from '../assets/Video-on.svg';
import CameraOffIcon from '../assets/Video-off.svg';
import { Modal, Spinner } from 'react-bootstrap';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: winerAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function VideoSection(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [mute, setMute] = useState(false);
  const { meeting, attendee } = state?.meeting;
  var meetingSession;
  let defaultVideo = state?.item?.is_camera_setup ? state?.item?.is_camera_setup.toString() : null;
  let defaultAudio = state?.item?.is_audio_setup ? state?.item?.is_audio_setup.toString() : null;
  const token = localStorage.getItem('token');
  const [getMeetingSession, setMeetingSession] = useState(null);
  const [addProductModal, setAddProductModal] = useState(false);
  const [giveawayDetail, setGiveawayDetail] = useState({});
  let [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [pickerButtonStatus, setPickerButtonStatus] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [showStartAuctionButton, setShowStartAuctionButton] = useState(true);
  const [animation, setAnimation] = useState(true);
  const [auctionButtonShow, setAuctionButtonShow] = useState(false);
  const [pickWinnerButtonShow, setPickWinnerButtonShow] = useState(false);
  const [broadcastUrl, setBroadcastUrl] = useState(null);
  const [winnerStatusCode, setWinnerStatusCode] = useState(0);
  const [giveawayWinnerStatusCode, setGiveawayWinnerStatusCode] = useState(0);
  const [getCountUpgradeStatus, setCountUpgradeStatus] = useState(0);
  const auctionId = localStorage.getItem("auctionId");
  const selectedProduct_id = localStorage.getItem("selectedProduct_id");
  const [checkForBidValue, setCheckForBidValue] = useState(false);
  const [bidData, setBidData] = useState();
  const [participantList, setParticipantList] = useState([]);
  const [winnername, setWinnername] = useState("");
  const [auctionWinnerAnimation, setAuctionWinnerAnimation] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [animateLoader, setAnimateLoader] = useState(true);
  const [wonAnimateLoader, setWonAnimateLoader] = useState(false);
  const [lottieAnimation, setLottieAnimation] = useState(false);
  const [pickWinneDetail, setPickWinneDetail] = useState();
  const [showPickButton, setShowPickButton] = useState(false);
  const [is_auction, setIs_auction] = useState(false);
  const [is_giveaway, setIs_giveaway] = useState(false);
  const [liveShowButton, setLiveShowButton] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [radioButtonSelected, setRadioButtonSelected] = useState(false);
  const [productSelectedId, setProductSelectedId] = useState(null)
  const [isLoader, setIsLoader] = useState(false);
  const [windowReload, setWindowReload] = useState(false);
  const [status, setStatus] = useState(true);
  var i = 0;
  const [stateCount, setStateCount] = useState(i);

  useEffect(() => {
    function changeStatus() {
      const online = navigator.onLine;
      setStatus(online);
      setLiveShowButton(online)
      if (online == false)
        setIsLoader(true)

      if (online) {
        setStateCount(prevCount => prevCount + 1);
        setIsLoader(true);
      }
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  useEffect(() => {
    if (stateCount == 1)
      window.location.reload()
  }, [stateCount]);

  useEffect(() => {
    if (!selectedVideoDevice) {
      setSelectedVideoDevice(state?.item?.is_camera_setup)

    } else {

    }
  }, [defaultVideo, selectedVideoDevice]);

  useEffect(() => {
    if (props.unSoldProductCount > getCountUpgradeStatus) {
      setCountUpgradeStatus(props.unSoldProductCount);
      setShowStartAuctionButton(true);
    } else {
      setCountUpgradeStatus(props.unSoldProductCount);
    }
  }, [props.unSoldProductCount]);

  useEffect(() => {
    if (!selectedAudioDevice) {
      setSelectedAudioDevice(defaultAudio)
    } else {

    }
  }, [defaultAudio, selectedAudioDevice]);

  var i = 0;

  const joinVideoCall = async () => {
    const logger = new Chime.ConsoleLogger();
    const deviceController = new Chime.DefaultDeviceController(logger);
    const configuration = new Chime.MeetingSessionConfiguration(meeting, attendee);
    meetingSession = new Chime.DefaultMeetingSession(configuration, logger, deviceController);
    const video_element = document.getElementById("videoId_123");
    const audio_element = document.getElementById("meeting-audio");
    const audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();
    const audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();
    const firstAudioDeviceId = state?.item?.is_audio_setup ? state?.item?.is_audio_setup : audioInputDevices[0].deviceId;
    await meetingSession.audioVideo.startAudioInput(firstAudioDeviceId);
    await meetingSession.audioVideo.bindAudioElement(audio_element);
    // Get list of available video input devices
    const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
    const firstVideoDeviceId = state?.item?.is_camera_setup ? state?.item?.is_camera_setup.toString() : videoInputDevices[0].deviceId;
    // Choose the first available video input device
    meetingSession.audioVideo.startVideoInput(firstVideoDeviceId);
    meetingSession.audioVideo.chooseVideoInputQuality(1280, 720, 15, 1400);
    meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    const observer = {
      audioVideoDidStart: () => {
        meetingSession.audioVideo.startLocalVideoTile();
      },
      videoTileDidUpdate: tileState => {
        if (tileState.localTile) {
          meetingSession.audioVideo.bindVideoElement(tileState?.tileId, video_element);
        }
      },
      audioVideoDidStop: sessionStatus => {
        // See the "Stopping a session" section for details.
        // Attempt to reconnect the session
        meetingSession.audioVideo.stop();
      },
      audioVideoDidStartConnecting: reconnecting => {
        if (reconnecting) {
          // e.g. the WiFi connection is dropped.
          meetingSession.audioVideo.start();
        } else {
        }
      },
    }

    meetingSession.audioVideo.addObserver(observer);
    // Check if any devices are available
    if (videoInputDevices.length > 0) {
      try {
        meetingSession.audioVideo.startLocalVideoTile();
        // Start the meeting session
        setMeetingSession(meetingSession);
        meetingSession.audioVideo.start();
        props?.getDataFromVideoSection(meetingSession)
      } catch (e) {
        console.error('e___________________________', e);
      }
    } else {
      console.error("No video input devices found.");
      // Handle error scenario where no video input devices are available
    }
  }

  const muteUnmute = (action) => {
    setMute(action);
    if (getMeetingSession) {
      if (action == true) {
        getMeetingSession.audioVideo.realtimeMuteLocalAudio();
      } else {
        getMeetingSession.audioVideo.realtimeUnmuteLocalAudio();
      }
    }
  }

  function stopMeetingSessionAwsChime(status) {
    const video_element = document.getElementById("videoId_123");
    const audio_element = document.getElementById("meeting-audio");
    if (getMeetingSession) {
      getMeetingSession.audioVideo.stop();
      getMeetingSession.audioVideo.chooseVideoInputDevice(null);
      getMeetingSession.audioVideo.stopLocalVideoTile();

      getMeetingSession.audioVideo.stopVideoPreviewForVideoInput(video_element);
      localStorage.removeItem("isMeeting");
      navigate("/auction")
    }
  }

  const pickWinner = () => {
    setAuctionWinnerAnimation(false)
    const req = {
      "room_id": localStorage.getItem("room_id"),
      "product_id": selectedProduct_id
    }
    socket.emit('pick_winner', req);
    const handlePickWinner = (data) => {
      setPickWinneDetail(data);
      props.getWinnerData(data)
      if (data?.response_data?.winner_user?.name.length > 0) {
        setGiveawayWinnerStatusCode(1)
      }
      else if (data?.status_code == 0) {
        setPickWinnerButtonShow(true)
        setGiveawayWinnerStatusCode(0)
      }
      else {

      }
    };
    socket.on('winner_name', handlePickWinner);
    newAnimatiponFunction()
    const Time1 = setTimeout(() => {
      setModalShow(true)
      setAnimateLoader(true)
      setShowPickButton(false)
    }, 500);

    const Time2 = setTimeout(() => {
      setAnimateLoader(false)
      setWonAnimateLoader(true)

    }, 6200);

    const Time3 = setTimeout(() => {
      setModalShow(false)
      setAnimateLoader(false)
      setWonAnimateLoader(false)
      setIs_auction(true)
      setIs_giveaway(true)
      setGiveawayDetail({})

    }, 11000);

    return () => {
      clearTimeout(Time1)
      clearTimeout(Time2)
      clearTimeout(Time3)
      socket.off('winner_name', handlePickWinner);
      setAuctionButtonShow(true)
    }
  }

  const giveAwayData = () => {
    try {
      const req = {
        "auction_id": auctionId,
        "product_id": productSelectedId
      }
      socket.emit('giveaway', req);
      setLoaderStatus(true)
      const handleShowGiveaway = async () => {
        socket.on('showgiveaway', (data) => {
          setLoaderStatus(false)
          checkStatusFun();
          setGiveawayDetail(data.data);
          setIs_auction(data.data?.is_auction);
          setIs_giveaway(data.data?.is_giveaway_butt_active);
        });
      };
      handleShowGiveaway()
      return () => {
        socket.off('showgiveaway', handleShowGiveaway);
      }

    } catch (e) {
      console.error('error____', e);
    }
  }

  useEffect(() => {
    joinVideoCall()
  }, []);

  const GiveawayProduct = () => {
    setIs_auction(false)
    setIs_giveaway(false)
    setShowPickButton(true)
    notify("success", "Giveaway Started");
    giveAwayData();
    const handlePicker_button_status = async () => {
      socket.on('picker_button_status', (data) => {
        setPickerButtonStatus(data?.response_data?.is_disabled);
      });
    };
    handlePicker_button_status();
    setIs_auction(false);
    setIs_giveaway(false);
    setAddProductModal(true);
    setAuctionButtonShow(false);
    setRadioButtonSelected(false)
    selectSubmit()
    return () => {
      socket.off('picker_button_status', handlePicker_button_status);
      toast.dismiss();
    };
  }

  const selectSubmit = () => {
    let req = {
      "auction_id": props.auctionId,
      "product_ids": localStorage.getItem("selectedProduct_id")
    }
    socket.emit('add_product_to_auction', req);
    setAddProductModal(false);
    setIs_auction(true);
    setIs_giveaway(true);
  }

  const formatTime = (time) => {
    if (time === null) return '00:00'; // Handle null case
    const duration = moment.duration(time, 'seconds');
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const startTimerFromTimeObj = (startTime, endTime) => {
    setShowStartAuctionButton(false)
    setIs_giveaway(false)
    let timeDifference = endTime - startTime
    timeDifference = Math.max(timeDifference, 0);
    setRemainingTime(timeDifference);
  };

  // Start Aution Button On Click
  const startAuctionClick = () => {
    setGiveawayDetail({})
    setBidData()
    notify("success", "Auction Started");
    const req = {
      "auction_id": auctionId,
      "product_id": localStorage.getItem("selectedProduct_id")
    }
    socket.emit('start_live_auction', req);
    const handleAuctionProductDetail = async () => {
      socket.on('auction_product_detail', (data) => {
        setIs_auction(data?.is_auction)
        setIs_giveaway(data?.is_giveaway)
        setGiveawayDetail(data);
        startTimerFromTimeObj(data?.start_time, data?.end_time);
      });
    };
    handleAuctionProductDetail()
    setIs_auction(false)
    setIs_giveaway(false)
    setAddProductModal(true);
    setAuctionButtonShow(false);
    setRadioButtonSelected(false)
    selectSubmit()
    return () => {
      socket.off('auction_product_detail', handleAuctionProductDetail);
      toast.dismiss();
    };
  }

  // Animation
  const WinnerAnimation = () => {
    const Time1 = setTimeout(() => {
      setModalShow(true)
      setAnimateLoader(true)
    }, 500);

    const Time2 = setTimeout(() => {
      setAnimateLoader(false)
      setWonAnimateLoader(true)
    }, 6200);  //6200

    const Time3 = setTimeout(() => {
      setModalShow(false)
      setAnimation(false)
      setAnimateLoader(false)
      setWonAnimateLoader(false)
      setWinnerStatusCode(0)
      setGiveawayWinnerStatusCode(0)
      setBidData()
      setGiveawayDetail({})
      setShowStartAuctionButton(true)
      setIs_giveaway(true)

    }, 11000);

    return () => {
      clearTimeout(Time1)
      clearTimeout(Time2)
      clearTimeout(Time3)
    }
  }

  // Product Loop
  useEffect(() => {
    if (animation == false) {
      checkStatusFun()
      setAnimation(true)
    }
  }, [animation, pickWinneDetail])

  // Data Fetch
  useEffect(() => {
    let timer;
    // Decrease Timer by 1 sec
    if (remainingTime !== null && remainingTime !== 0) {
      timer = setInterval(() => {
        setRemainingTime(prevTime => Math.max(prevTime - 1, 0));
      }, 1000);
    }

    // After Timer exhaust
    if ((remainingTime == 0)) {
      setLottieAnimation(false)
      setGiveawayDetail({})
      setRadioButtonSelected(false)
      setIs_auction(true);
      setIs_giveaway(true);
      setAuctionButtonShow(false);
      setShowStartAuctionButton(true)
      setAuctionWinnerAnimation(false)
      const req = {
        "auction_id": localStorage.getItem("auctionId"),
        "room_id": localStorage.getItem("room_id"),
        "product_id": giveawayDetail?.product?.id,
      }
      socket.emit('auction_winner', req);
      const fetchDataFromSocket = async () => {
        socket.on('winner_name', (data) => {
          setAuctionWinnerAnimation(true)
          if (data?.response_data?.winner_user?.name.length > 0) {
            setWinnerStatusCode(1)
          }
          setPickWinneDetail(data);
        });
      };

      fetchDataFromSocket();
      const winerTimeout = setTimeout(() => {
        setAuctionWinnerAnimation(false)
      }, 4000)

      // Control Product Cycle
      localStorage.setItem("prodBidCount", (parseInt(localStorage.getItem("prodBidCount")) + 1))
      return () => {
        socket.off('winner_name', fetchDataFromSocket);
        toast.dismiss();
        clearTimeout(winerTimeout)
      }
    } else if (checkForBidValue == true) {
      if (remainingTime < 10) {
        setRemainingTime(10)
      }
      setCheckForBidValue(false)
    }
    return () => clearInterval(timer);
  }, [remainingTime]);

  // Button click handler to start the timer with timeObj
  const restartTimer = () => {
    try {
      const handleTopBidAmount = (data) => {
        setBidData(data?.response_data)
        if (data?.response_data?.is_timer_reset === true) {
          setCheckForBidValue(true)
        }
      };
      socket.on('top_bid_amount', handleTopBidAmount);

      return () => {
        socket.off('top_bid_amount', handleTopBidAmount);
        setCheckForBidValue(false)
      };
    } catch (error) {

    }

  }

  useEffect(() => {
    restartTimer()
  })

  const checkStatusFun = () => {
    const handleStatus = (data) => {
      let errorMessage = data.error;
      console.log("errorMessage__________", errorMessage)
    };
    socket.on('status', handleStatus);
    return () => {
      socket.off('status', handleStatus);
    };
  }

  const startAuction = (auctionId) => {
    try {
      console.log("auctionId_start_auction", auctionId)
      socket.emit('start_auction', { "auction_id": auctionId });
      // Enable Start Auction  Button
      setIs_auction(true)
      // Enable GiveAway Button
      setIs_giveaway(true)
    } catch (error) {
      // console.error("Connection error", error);
    }
  };

  // Broadcast Url
  const addBroadcastUrl = () => {
    let formData = new FormData()
    formData.append("auction_id", auctionId);
    formData.append("broadcast_url", broadcastUrl);

    apiService.editAuction(formData, token).then(res => {
      if (res?.data?.status_code == 1) {
        setLiveShowButton(true)
        startAuction(auctionId)
        notify("success", res?.data?.response_data?.success);
      } else {

      }
    })
  }

  const startStreaming = async (id) => {
    try {
      socket.emit('start_streaming', { "auction_id": id });
    } catch (error) {
      console.log("error________", error)
    }
  }

  const checkRadioButtonSelected = (data, productId) => {
    setIs_auction(true)
    setIs_giveaway(true)
    setShowPickButton(false)
    setGiveawayDetail({})
    setProductSelectedId(productId)
    if (data == true) {
      setRadioButtonSelected(true);
    }
  };

  // useEffect(() => {
  //   socket.emit('test');
  //   setLiveShowButton(true)
  //   startAuction(props?.auctionId)
  // }, [])



  useEffect(() => {
    const initializeAuction = () => {
      setLiveShowButton(true); // Set the button state
  
      if (props?.auctionId) {
        startAuction(props.auctionId);
      }
  
      setTimeout(() => {
        socket?.emit('test',{key:"test"});
      }, 10000); // Emit event after 10sec delay
    };
  
    initializeAuction();
  }, []); // Runs only once on mount

  const newAnimatiponFunction = () => {
    setAuctionWinnerAnimation(false)
    setModalShow(true)
    setAnimateLoader(false)
    setWonAnimateLoader(false)
    setLottieAnimation(true)
    const auctionWinnerName = (data) => {
      setWinnername(data?.response_data?.winner_user?.name)
      let participant = []
      participant = data?.response_data?.participant_list?.map((item) => {
        return item.name
      })
      setParticipantList(participant)
    };
    socket.on('auction_winner_name', auctionWinnerName);
    setAuctionWinnerAnimation(false)
    return () => {
      socket.off('auction_winner_name', auctionWinnerName);
    };
  }

  const [loading, setLoading] = useState(false); // New loading state

  const [isVideoOn, setIsVideoOn] = useState(true); // Initial state for video on/off


  const handleToggle = async () => {
    setLoading(true);
    console.log("selectedVideoDevice", selectedVideoDevice)
    try {
      if (isVideoOn) {
        // Video is currently on, so turn it off
        getMeetingSession.audioVideo.stopLocalVideoTile();
        await getMeetingSession.audioVideo.stopVideoInput();
      } else {
        // Video is currently off, so turn it on
        await getMeetingSession.audioVideo.startVideoInput(selectedVideoDevice);

        // Bind video to an element
        const videoElement = document.getElementById("videoId_123");
        if (videoElement) {
          const tileId = getMeetingSession.audioVideo.hasStartedLocalVideoTile()
            ? getMeetingSession.audioVideo.getLocalVideoTile().state().tileId
            : null;

          if (tileId) {
            // Unbind and rebind the video element to the existing tile
            getMeetingSession.audioVideo.unbindVideoElement(tileId);
            getMeetingSession.audioVideo.bindVideoElement(tileId, videoElement);
          } else {
            // Start a new local video tile
            getMeetingSession.audioVideo.startLocalVideoTile();
          }
        }
      }
    } catch (error) {
      console.error("Error handling video toggle:", error);
    } finally {
      setLoading(false);
      setIsVideoOn((prevState) => !prevState); // Toggle the state after operations
    }
  };
  return (
    <>
      <div className='_left_section'>
        <div className='_auction_details_section'>
          <div className='_video_bottom'>
            <div className='_first'>
              <Button name="Giveaway Product"
                className={`_giveaway lower_case ${((radioButtonSelected == false || is_giveaway == false || props.unSoldProductCount == 0)) ? 'disabled' : ''} `}
                onClick={GiveawayProduct} />
              {giveawayDetail?.condition == 1 ?
                <img src={Used_tag} alt="used_tag" />
                : <div></div>
              }
              {(showStartAuctionButton || props.unSoldProductCount == 0) &&
                <Button name="Start Sale"
                  className={`_pick lower_case ${((radioButtonSelected == false || is_auction == false || auctionButtonShow == true || props.unSoldProductCount == 0)) ? 'disabled' : ''} `}
                  onClick={startAuctionClick}
                />
              }
              {showStartAuctionButton == false && giveawayDetail?.is_auction && props.unSoldProductCount > 0 &&
                <span className='btn _pick lower_case common_button avenir_heavy'>
                  {formatTime(remainingTime)}
                </span>
              }
            </div>
            <div className='_middle'>
              {giveawayDetail?.is_auction ?
                <>
                  {bidData &&
                    <h5 className='my-3'>Max Offer&nbsp;&nbsp; :&nbsp;&nbsp;<span className='_text me-3'>{bidData?.bid_detail?.name} {bidData?.bid_detail?.amount}</span><span><Coin /></span></h5>}
                </>
                : ''
              }
            </div>
            <div className='_last'>
              {loaderStatus ? <GiveawaySkeleton />
                : <>
                  {Object.keys(giveawayDetail).length > 0 ?
                    <div className='description'>
                      <h6>Product on {giveawayDetail?.is_auction ? 'Auction' : 'Giveaway'} : <span>{giveawayDetail?.product?.name}</span></h6>
                      <p>{giveawayDetail?.product?.description}
                      </p>
                    </div>
                    :
                    <div className='description'></div>
                  }
                </>
              }
              {giveawayDetail?.product &&
                <h5>Original price : <span className='_ornage'>₹{giveawayDetail?.product ? giveawayDetail?.product?.original_mrp : '000'}</span></h5>
              }
              <div className='btn_block pb-3'>
                {/* <Button name="Giveaway Product"
                  className={`_giveaway lower_case ${((radioButtonSelected == false || is_giveaway == false || props.unSoldProductCount == 0)) ? 'disabled' : ''} `}
                  onClick={GiveawayProduct} /> */}
                {showPickButton &&
                  <button className={`btn _pick lower_case ${pickerButtonStatus == false ? 'disabled' : ''} common_button avenir_heavy`}
                    onClick={pickWinner}
                  ><WinnerIcon /> Pick Winner</button>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='show_only_desktop'>
          <ProductList
            getProductListStaus={props.getProductListStaus}
            getProductCountData={props.getProductCountData}
            checkRadioButtonSelected={checkRadioButtonSelected}
            is_giveaway={is_giveaway}
            is_auction={is_auction}
            displayWeb="true"
            className="show_only_desktop" />
        </div>
      </div>
      <div className='_main_center'>
        <div className='_video_section'>
          <div className='_video'>
            <video id="videoId_123"></video>
            <audio id="meeting-audio" style={{ "display": "none" }}></audio>
            {liveShowButton ?
              <span className='_live_svg'><img src={livesvg} /></span>
              :
              <span className='_live_svg'>Please check your internet connection...</span>
            }

            {loading && <Spinner className='lg _setup_leader' />}
            <p className='_mute_icon_row'>
              <span onClick={handleToggle} style={{ cursor: "pointer" }}>
                {isVideoOn ? (
                  <img src={CameraIcon} alt="icon" className="me-2" />
                ) : (
                  <img src={CameraOffIcon} alt="icon" className="me-2" />
                )}
              </span>
              {
                (mute == false) ?
                  <span className="muteIcon" onClick={() => muteUnmute(true)}><MuteIcon /></span>
                  :
                  <span><img src={UnMute} alt="icon" onClick={() => muteUnmute(false)} /></span>
              }
            </p>
          </div>
          {(auctionWinnerAnimation && lottieAnimation == false) ?
            <div className={`cm_modal ${!animateLoader ? '__center_text_icon' : ''}`}>
              <div className='' style={{ background: "#fff", padding: "20px", borderRadius: "8px", textAlign: "center" }}>
                <img
                  src={`${pickWinneDetail?.response_data?.winner_user?.name ? winimage : unsold}`}
                  alt='win svg' className='win svg'
                  style={{
                    width: "60px",
                    height: "60px",
                    marginBottom: " 13px"
                  }}
                />
                {
                  (winnerStatusCode == 1 || giveawayWinnerStatusCode == 1) ?
                    <>
                      {pickWinneDetail?.response_data?.winner_user?.name ?
                        <h5>{pickWinneDetail?.response_data?.winner_user?.name} won</h5>
                        : <h5>Product is unsold</h5>
                      }
                    </>
                    :
                    <h5>Product is unsold</h5>
                }
              </div>
            </div> : ''
          }
          {modalShow &&
            <div className={`cm_modal ${!animateLoader ? '__center_text_icon' : ''}`}>
              {lottieAnimation &&
                <div className='_winner_loties'>
                  <Lottie options={defaultOptions}
                    height={500}
                    width={500} />
                  <div className='lotie_layout'>
                    <SlotAnimation names={participantList} winnerName={winnername} />
                  </div>
                </div>
              }
            </div>

          }
        </div>
      </div>
      <div className='show_only_mobile'>
        <ProductList
          getProductListStaus={props.getProductListStaus}
          getProductCountData={props.getProductCountData}
          checkRadioButtonSelected={checkRadioButtonSelected}
          is_giveaway={is_giveaway}
          is_auction={is_auction}
          show={props.showProductlistmobile}
          showProductList={props.showProductList}
          displayWeb="false"
          className="show_only_mobile __mobile_product_list" />
      </div>
    </>
  )
}

export default VideoSection
