import React, { useReducer, useState, useEffect, useRef, useCallback } from 'react';
import moment from "moment";
import Button from '../../CommonComponent/Button'
import Modal from 'react-bootstrap/Modal';
import Input from "../../CommonComponent/Input"
import { useNavigate } from 'react-router';
import { apiService } from '../../Services/ApiServices';
import Form from "react-bootstrap/Form";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg } from "../../CommonComponent/Helper";
import BtnLoader from '../../CommonComponent/BtnLoader';
import InlineError from '../../CommonComponent/inlineError';
import { IoCloseCircle } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { ClockIcon } from '../../Svg';
import { AddproductImg } from '../../CommonComponent/Skeleton';
import { checkFileSize } from '../../CommonComponent/Validation';
import { useLogout } from '../../CommonComponent/commonFunction';
import { EditIcon } from '../../Svg';
import { FaPencil } from 'react-icons/fa6';
import { setHours, setMinutes, setSeconds } from 'date-fns';

function AddAuction(props) {
    const logout = useLogout();
    const dateInputRef = useRef(null);
    const navigate = useNavigate();
    const [btnLoader, setBtnLoader] = useState(false);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState();
    const [imageId, setImageId] = useState('');
    const [auctionDate, setAuctionDate] = useState(null);
    const [auctionDateString, setAuctionDateString] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const token = localStorage.getItem('token');
    const [document, setDocument] = useState([]);
    const [image, setImage] = useState([]);
    const [newUrl, setNewUrl] = useState([]);
    const [cImage, setCImage] = useState('');
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const inputRef = useRef();
    const [isAllAdded, setisAllAdded] = useState(false);
    const [startTimeTime, setTimeStartTime] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState();
    const storeLogo = localStorage.getItem('logo');
    const headerLogoImage = 'https://d2ugmqijh93rc0.cloudfront.net/bidsboxx_images/blob_j9CYExM';

    const [selectedEndDate, setSelectedEndDate] = useState();
    const [selectedEndDateConst, setSelectedEndDateConst] = useState();
    const [selectedLogoImage, setSelectedLogoImage] = useState(null);
    const [selectedLogoImageType, setSelectedLogoImageType] = useState(null);
    const [bookedStartTimeSlot, setBookedStartTimeSlot] = useState([]);
    const [bookedSecondStartTimeSlot, setBookedSecondStartTimeSlot] = useState([]);

    const [bookedEndTimeSlot, setBookedEndTimeSlot] = useState([]);
    const [bookedSecondEndTimeSlot, setBookedSecondEndTimeSlot] = useState([]);


    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [endDateConst, setEndDateConst] = useState();


    console.log("bookedStartTimeSlot", bookedStartTimeSlot)
    const [maxEndTime, setMaxEndTime] = useState();

    const [isTodayDate, setisTodayDate] = useState(false);

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            description: '',
            category: '', datepic: '', document: '', media: '', startTime: '', endTime: ''
        }
    )
    const checkFormCompletion = () => {
        if (startTime && description && (category >= 0) && auctionDateString && endTime && (imageId || selectedLogoImage)) {
            setisAllAdded(true);
        } else {
            setisAllAdded(false);
        }
    };
    const [isStartTime1130, setIsStartTime1130] = useState(false);

    const [isStartTime10, setIsStartTime10] = useState(false);

    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            imgError: '',
            media: [],
            document: [],
            documentErr: '',
            description: '',
            endTime: '',
            startTime: '',
            isImage: '',
        }
    );

    const [isImageLoader, setisImageLoader] = useState(false);

    useEffect(() => {
        checkFormCompletion()
    }, [startTime, description, category, auctionDateString, endTime, imageId, selectedLogoImage])


    const onChangeAuctionDate = (e) => {
        // debugger;
        setError({ datepic: '' })
        setSelectedStartDate(null)
        setSelectedEndDateConst(null)
        setSelectedEndDate(null)
        setEndDateConst(null);
        setMaxEndTime(null)
        setEndDate(null)
        setEndTime(null)
        let startDate = e ? moment(e).format("x") : "";
        setAuctionDateString(e);
        let startDateX = + new Date();
        let startDatem = e ? moment(e).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)") : "";
        if (startDateX <= startDate) {
            setTimeStartTime(startDatem)
        } else {
            setTimeStartTime(new Date());
            setSelectedStartDate(null)
            setSelectedEndDate(null)
            setSelectedEndDateConst(null)
        }
        setAuctionDate(startDate)
        getAuctionSlot(startDate)
    };

    const onChangeAuctionEndDate = (e) => {
        // debugger;
        setError({ datepic: '' })
        console.log("selectedStartDateselectedStartDateselectedStartDateselectedStartDate12", selectedStartDate, isTodayDate)
        setEndDate(e)
        let today = moment(auctionDateString).format("ddd MMM DD YYYY");
        let selectedDate = moment(e).format("ddd MMM DD YYYY");
        console.log("startDateXstartDateXstartDateXstartDateX", today, selectedDate, e, (today == selectedDate))
        setSelectedEndDate(null)
        if (today == selectedDate) {
            setisTodayDate(true)
        } else {
            setisTodayDate(false)
        }
    };

    const generateSlots = (date, startHour, endHour, intervalMinutes) => {
        const slots = [];
        const startTime = moment(date).set({ hour: startHour, minute: 0, second: 0, millisecond: 0 });
        const endTime = moment(date).set({ hour: endHour, minute: 30, second: 0, millisecond: 0 });

        let current = startTime;

        while (current.isSameOrBefore(endTime)) {
            // slots.push(new Date(current.toISOString()));
            slots.push(current.toISOString()); // Store as ISO string for consistency



            current = current.add(intervalMinutes, "minutes");
        }

        return slots;
    };


    const generateSlotsWithTime = (startDateTime, endDateTime, intervalMinutes) => {
        const slots = [];

        // Ensure start and end times are moment objects
        const startTime = moment(startDateTime);
        const endTime = moment(endDateTime);

        // Check if end time is valid and after start time
        if (!endTime.isValid() || !startTime.isValid() || endTime.isBefore(startTime)) {
            console.error("Invalid start or end time. Please provide valid date-time values.");
            return slots;
        }

        // Loop through and create slots
        let current = startTime.clone();

        while (current.isSameOrBefore(endTime)) {
            slots.push(current.toISOString());
            current = current.add(intervalMinutes, "minutes"); // Increment by interval
        }

        return slots;
    };

    const combinedActualTime = (time) => {
        console.log("auctionDateString")
        let date = moment(auctionDateString).format("YYYY-MM-DD")
        const timeWithTimezone = moment(time).format("HH:mm:ss [GMT]ZZ [(India Standard Time)]"); // HH:mm:ss with timezone
        console.log("time____________________________Extracted Time with Timezone:", timeWithTimezone, date);

        // const selectedTime = moment(time).format("h:mm A");
        const combinedDateTime = moment(`${date} ${timeWithTimezone}`, "YYYY-MM-DD HH:mm:ss");

        const formattedCombinedDateTime = combinedDateTime.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
        //  console.log("Formatted Combined Date and Time:", formattedCombinedDateTime);
        return formattedCombinedDateTime;
    }
    const handleStartTimeChange = (time) => {
        // alert("Dddddddddddddddddddddddd")
        const selectedTime = moment(time).format("h:mm A");
        let actualDateStringTime = new Date(combinedActualTime(time));
        console.log("handleStartTimeChange_____________1", actualDateStringTime, time)
        setSelectedStartDate(actualDateStringTime);
        let startTime = moment(time).format("x");
        setStartTime(parseInt(startTime))
        const slotsDate = moment(auctionDateString).format("YYYY-MM-DD");
        const normalizedBookedStartTimeSlot = bookedEndTimeSlot.map((slot) =>
            new Date(slot).toISOString()
        );

        const slots = generateSlots(slotsDate, 22, 23, 30);

        console.log("Generated Slots:", slots, slotsDate, bookedEndTimeSlot);

        const matches = slots.filter((date1) => normalizedBookedStartTimeSlot.includes(date1));

        console.log("11111111111111111111222Match matches:", matches, normalizedBookedStartTimeSlot, "---------------", slots);
        if (matches.length > 0) {
            console.log("11111111111111111111222Match found:", matches);
        } else {
            console.log("11111111111111111111222No match found");
        }


        let startDateTime = moment(actualDateStringTime)
            .add(30, 'minutes');
        const endDateTime = moment(actualDateStringTime)
            .add(2, 'hour');

        const intervalMinutes = 30; // Interval in minutes

        const slotsAfterTwoHour = generateSlotsWithTime(startDateTime, endDateTime, intervalMinutes);

        console.log("slotsAfterTwoHour Slots:", startDateTime, endDateTime, slotsAfterTwoHour);

        const matchesAfter = slotsAfterTwoHour.filter((date1) => normalizedBookedStartTimeSlot.includes(date1));

        console.log("matchesAfter matches:", matchesAfter, startDateTime, endDateTime, slotsAfterTwoHour, "-------", normalizedBookedStartTimeSlot);
        if (matchesAfter.length > 0) {
            console.log("matchesAfter found:", matches);
        } else {
            console.log("matchesAfter not match found");
        }
        // console.log("11111111111111111111222No 2222222222222222222",bookedStartTimeSlot,slots, normalizedBookedStartTimeSlot);

        let date = moment(actualDateStringTime)
            .add(120, 'minutes')
            .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");


        date = new Date(date);
        console.log("handleStartTimeChange________________2", date)
        setSelectedEndDate(date)
        setSelectedEndDateConst(date)
        setEndTime(moment(date).format("x"));
        setEndDateConst(moment(date).format("x"))

        // Check if the selected start time is 11:30 PM
        if (selectedTime === "11:30 PM") {
            setIsStartTime1130(true);
        } else {
            setIsStartTime1130(false);
        }


        // let today = moment(auctionDateString).format("ddd MMM DD YYYY");
        // let selectedDate = moment(e).format("ddd MMM DD YYYY");
        // console.log("startDateXstartDateXstartDateXstartDateX", today, selectedDate, e, (today == selectedDate))

        // if (today == selectedDate) {
        //     setisTodayDate(true)
        // }

        const thresholdTime = "10:00 PM";

        if (moment(selectedTime, "hh:mm A").isSameOrAfter(moment(thresholdTime, "hh:mm A"))) {
            if ((selectedTime !== "11:30 PM") && (matches.length > 0)) {
                console.log("endDate___________________________iffffffffffffffffffffffffff1111111111111", true)
                setEndDate(auctionDateString)
                setIsStartTime10(false);
                setSelectedEndDate(null)
                setisTodayDate(true)
            } else {
                console.log("iffffffffffffffffffffffffff12222222222222222222", true)
                setIsStartTime10(true);
                let endDate = moment(auctionDateString).add(1, 'day').format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
                console.log("endDate___________________________2", auctionDateString, endDate)
                setEndDate(endDate)

                setisTodayDate(false)

                let startDateTime1 = moment(endDate)
                    .add(30, 'minutes');
                const endDateTime1 = moment(endDate)
                    .add(2, 'hour');

                const intervalMinutes = 30; // Interval in minutes

                const slotsAfterTwoHour = generateSlotsWithTime(startDateTime1, endDateTime1, intervalMinutes);
                const normalizedBookedStartTimeSlot1 = bookedSecondEndTimeSlot.map((slot) =>
                    new Date(slot).toISOString()
                );
                const matchesAfter1 = slotsAfterTwoHour.filter((date1) => normalizedBookedStartTimeSlot1.includes(date1));
                console.log("slotsAfterTwoHour11 Slots:", startDateTime1, endDateTime1, slotsAfterTwoHour, matchesAfter1.length);
                if (matchesAfter1.length > 0)
                    setSelectedEndDate(null)

            }

        } else {
            console.log("iffffffffffffffffffffffffff13333333333333333333333", true)
            console.log("endDate___________________________1", auctionDateString)

            if (matchesAfter.length > 0) {
                console.log("endDate___________________________13333", auctionDateString)
                setEndDate(auctionDateString)
                setSelectedEndDate(null)
                setIsStartTime10(false);
                setisTodayDate(true)
            } else {
                console.log("endDate___________________________1444444444444444", auctionDateString)
                setEndDate(auctionDateString)
                setIsStartTime10(false);
                setisTodayDate(true)
            }

        }

    };

    console.log("isssdddddddddddddddddddd", isStartTime10)
    const handleEndTimeChange = (time) => {
        // setEndTime(null)
        console.log("handleEndTimeChange111111222222222", time)
        // debugger; // For debugging purposes
        if (time instanceof Date) {
            setSelectedEndDate(new Date(time)); // Ensure time is a valid Date object
            setEndTime(moment(time).format("x")); // Convert to Unix timestamp if needed
            setError({ endTime: '' }); // Clear error if any
        } else {
            console.error("Invalid time passed:", time);
        }
    };

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
        if ((e.target.value == undefined) || (e.target.value.length == 0)) {
            setError({ description: 'Description is required' });
        } else if ((e.target.value.length > 0) && (e.target.value.length <= 1)) {
            setError({ description: 'Description must be between 2 and 90 characters' });
        } else {
            setError({ ...error, [e.target.name]: '', });
        }
    };

    const onChangeCategory = (e) => {
        setError({ category: '' })
        setCategory(e.target.value)
    };

    function addDateTime(firstTimestamp, secondTimestamp) {
        // Extract date from the first timestamp
        const datePart = moment(parseInt(firstTimestamp)).format("YYYY-MM-DD");
        // Extract time from the second timestamp
        const timePart = moment(parseInt(secondTimestamp)).format("HH:mm:ss");
        // Combine date and time parts into a single timestamp
        const combinedTimestamp = moment(`${datePart}T${timePart}`).valueOf();
        // Display the combined timestamp
        return combinedTimestamp;
    }
    const handleValidate = () => {
        // debugger
        setBtnLoader(false);
        let validate = true;
        // let { category, datepic, document, media ,description,endTime,startTime} = userInput;
        // console.log("HiDate---",category, datepic, document, media)
        setError({});

        console.log("handleValidate___________", endTime)
        // Validate fields
        console.log("sdsd34343434", userInput)
        if ((description == undefined) || (description.length == 0)) {
            setError({ description: 'Description  is required' });
            validate = false
        }
        if ((category == undefined) || (category.length == 0)) {
            setError({ category: 'Please Select the category' });
            validate = false
        }
        if ((auctionDate == undefined) || (auctionDate.length == 0)) {
            setError({ datepic: 'Date is required' });
            validate = false
        }
        if ((startTime == undefined) || (startTime.length == 0)) {
            setError({ startTime: 'Start Time is required' });
            validate = false
        }
        if ((endTime == undefined) || (endTime.length == 0)) {
            setError({ endTime: 'End Date is required' });
            validate = false
        }

        if ((selectedEndDate == undefined) || (selectedEndDate.length == 0)) {
            setError({ selectedEndDate: 'End Time is required' });
            validate = false
        }


        if (((selectedLogoImage == undefined) || (selectedLogoImage == '')) && ((cImage == undefined) || (cImage.length == 0))) {
            setError({ isImage: 'Please select or upload image' });
            validate = false
        }
        return validate;
    };
    const addAuction = () => {
        if (handleValidate()) {


            let endDateTime;
            console.log("isStartTime10isStartTime10isStartTime10", isStartTime10)
            if (!isTodayDate) {
                endDateTime = moment(auctionDateString).add(1, 'day').format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");

                endDateTime = moment(endDateTime).format("x")
                console.log("isStartTime10isStartTime10isStartTime10", auctionDate, "__________", endDateTime, "_______", auctionDateString)
            } else {
                endDateTime = auctionDate;
            }

            let startTimeE = addDateTime(auctionDate, startTime);
            let endTimeE = addDateTime(endDateTime, endTime);

            let req = {
                "description": description,
                "image_type": selectedLogoImageType == "storeLogo" ? "1" : selectedLogoImageType == "logo" ? "2" : "3",
                "category": category,
                "date": auctionDate / 1000,
                "start_time": (startTimeE / 1000),
                "end_time": (endTimeE / 1000),
                "image": imageId ? imageId : selectedLogoImage
            }
            apiService.addAuction(req, token).then(res => {
                if (res?.data?.status_code == 1) {
                    props.onlyClose(false)
                    navigate("/auction")
                } else {
                    props.onlyClose(false)
                    navigate("/auction")
                }
            })
        }
    }

    const cropInitialState = { aspect: 16 / 15, width: 100 }

    const [crop, setCrop] = useState({ aspect: 16 / 15, width: 100 });

    const onSelectFile = async e => {
        // debugger
        if (e.target.files && e.target.files.length > 0) {
            setError({ isImage: '' })
            const checkSize = await checkFileSize(e);
            if (!checkSize) {
                setCImage(e.target.files);
                const reader = new FileReader();
                reader.addEventListener("load", () => setUpImg(reader.result));
                reader.readAsDataURL(e.target.files[0]);
                setUserInput({
                    documentErr: '',
                });
            } else {
                setCImage('');
                setNewUrl('');
                setUserInput({
                    documentErr: "Image size must be less than 2 MB",
                });
            }
        }
    };

    const completedCrop = crop => {
        setSelectedLogoImageType('')
        setSelectedLogoImage('')
        getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
            setNewUrl(res.url);
            setSelectedLogoImageType(3);
        })
    }

    const removeItem = () => {
        setNewUrl('');
        setSelectedLogoImageType('')
        setImageId('')
        setSelectedLogoImage('')
    }

    const handleFileUpload = () => {
        if (upImg) {
            setUpImg('')
            setCrop(cropInitialState)
            image.push({ "url": newUrl })
            getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
                document.push(res);
                addAuctionImage();
            })
        }
    }

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    const addAuctionImage = () => {
        try {
            setisImageLoader(true)
            let formData = new FormData();
            formData.append("image_upload_to", 2);
            document.forEach(element => {
                formData.append("image", element);
            });
            apiService.addAuctionImage(formData, token).then(result => {
                if (result.data?.status_code == 1) {
                    setImageId(result.data?.response_data?.data.image);
                    setisImageLoader(false)
                } else {
                }
            })
        } catch (e) {
            console.error('e', e);
        }
    }

    const today = new Date().toISOString().split('T')[0];

    const findMaxTime = (array, key) => {
        console.log("array_________11", array, key)
        if (array.length) {
            const timestamps = array.map(dateTime => moment(dateTime).unix());
            let keyDateTime = moment(key).unix()
            console.log("array_________12", timestamps, keyDateTime);
            const validTimestamps = timestamps.filter(timestamp => timestamp >= keyDateTime);
            console.log("array_________13", validTimestamps);
            // Find the maximum timestamp from the filtered list
            const maxTimestamp = validTimestamps.length > 0 ? Math.min(...validTimestamps) : null;
            console.log("array_________14", maxTimestamp);
            return maxTimestamp;
        } else {
            return null
        }

    };

    function epochToGMT(epoch) {
        const date = new Date(epoch * 1000); // Convert seconds to milliseconds
        return date.toGMTString(); // Get the GMT string representation
    }

    const filterStartTimeSlots = (time) => {
        console.log("timeeeeeeeeeeeeeeeeeeeeeee", time)
        const hours = time.getHours();
        const minutes = time.getMinutes();
        // Convert time to milliseconds for comparison
        const currentTime = hours * 60 + minutes;
        // Convert excluded time slots to milliseconds for comparison
        const excludedTimeSlotsInMinutes = bookedStartTimeSlot.map(slot => slot.getHours() * 60 + slot.getMinutes());
        // Check if the current time slot is in the excluded list
        return !excludedTimeSlotsInMinutes.includes(currentTime);
    };


    const convertToLongFormat = (time) => {
        return new Date(epochToGMT(time))
    }


    function adjustLastEndTime122(array, specificTime) {
        if (array.length === 0) return array; // Handle empty array
    
        const lastObject = array[array.length - 1];
        const startDate = new Date(lastObject.start_time * 1000); // Convert start_time to Date object
        const endDate = new Date(lastObject.end_time * 1000); // Convert end_time to Date object
    
        // Check if the dates are not the same
        if (startDate.toDateString() !== endDate.toDateString()) {
            // Parse specificTime (e.g., "19:15:40")
            const [hours, minutes, seconds] = specificTime.split(':').map(Number);
    
            // Create a new Date object for adjusted end_time based on start_date
            const adjustedEndTime = new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate(),
                hours,
                minutes,
                seconds
            );
    
            // Update the end_time of the last object in Unix timestamp format
            lastObject.end_time = Math.floor(adjustedEndTime.getTime() / 1000); // Convert to Unix timestamp
        }
    
        return array;
    }


    function adjustLastEndTimeassadsad(array, specificTime, baseDate) {
        if (array.length === 0) return array; // Handle empty array
    
        const lastObject = array[array.length - 1]; // Get the last object
        const startDate = new Date(lastObject.start_time * 1000); // Convert start_time to Date object
        const endDate = new Date(lastObject.end_time * 1000); // Convert end_time to Date object
    
        // Check if the dates are not the same OR if there's only one object to adjust explicitly
        if (startDate.toDateString() !== endDate.toDateString() || array.length === 1) {
            // Parse specificTime (e.g., "19:15:40")
            const [hours, minutes, seconds] = specificTime.split(':').map(Number);
    
            // Use the base date for adjustment
            const adjustedBaseDate = new Date(baseDate);
            const adjustedEndTime = new Date(
                adjustedBaseDate.getFullYear(),
                adjustedBaseDate.getMonth(),
                adjustedBaseDate.getDate(),
                hours,
                minutes,
                seconds
            );
    
            // Update the end_time of the last object in Unix timestamp format
            lastObject.end_time = Math.floor(adjustedEndTime.getTime() / 1000); // Convert to Unix timestamp
        }
    
        return array;
    }



    function adjustLastEndTime12321(array, specificTime, baseDate) {
        if (array.length === 0) return array; // Handle empty array
    
        // Handle the first object in the array
        const firstObject = array[0];
        const firstStartDate = new Date(firstObject.start_time * 1000); // Convert to Date object
        const firstEndDate = new Date(firstObject.end_time * 1000); // Convert to Date object
    
        if (firstStartDate.toDateString() !== firstEndDate.toDateString()) {
            // Parse baseDate and set time to 00:00:00
            const baseStartDate = new Date(baseDate);
            const adjustedStartTime = new Date(
                baseStartDate.getFullYear(),
                baseStartDate.getMonth(),
                baseStartDate.getDate(),
                0,
                0,
                0
            );
    
            // Update the start_time in the first object
            firstObject.start_time = Math.floor(adjustedStartTime.getTime() / 1000); // Convert to Unix timestamp
        }
    
        // Handle the last object in the array
        const lastObject = array[array.length - 1];
        const lastStartDate = new Date(lastObject.start_time * 1000); // Convert to Date object
        const lastEndDate = new Date(lastObject.end_time * 1000); // Convert to Date object
    
        // Check if the dates are not the same OR if there's only one object to adjust explicitly
        if (lastStartDate.toDateString() !== lastEndDate.toDateString() || array.length >=2) {
            // Parse specificTime (e.g., "19:15:40")
            const [hours, minutes, seconds] = specificTime.split(':').map(Number);
    
            // Use the base date for adjustment
            const adjustedBaseDate = new Date(baseDate);
            const adjustedEndTime = new Date(
                adjustedBaseDate.getFullYear(),
                adjustedBaseDate.getMonth(),
                adjustedBaseDate.getDate(),
                hours,
                minutes,
                seconds
            );
    
            // Update the end_time of the last object in Unix timestamp format
            lastObject.end_time = Math.floor(adjustedEndTime.getTime() / 1000); // Convert to Unix timestamp
        }
    
        return array;
    }
    
    

    function adjustLastEndTime(array, baseDate) {
        if (array.length === 0) return array; // Handle empty array
    
        const baseDateObject = new Date(baseDate); // Parse provided date
    
        return array.map((obj) => {
            const startDate = new Date(obj.start_time * 1000); // Convert start_time to Date object
            const endDate = new Date(obj.end_time * 1000); // Convert end_time to Date object
    
            console.log("startDate.toDateString()",startDate.toDateString(), endDate.toDateString(),baseDateObject.toDateString())
            if(startDate.toDateString() !== endDate.toDateString()){
                if (startDate.toDateString() === baseDateObject.toDateString()) { 
                    // If start_time matches the provided date, adjust end_time to baseDate + 23:59:59
                    const adjustedEndTime = new Date(
                        baseDateObject.getFullYear(),
                        baseDateObject.getMonth(),
                        baseDateObject.getDate(),
                        23,
                        59,
                        59
                    );
                    obj.end_time = Math.floor(adjustedEndTime.getTime() / 1000); // Update end_time in Unix timestamp format
                } else {
                    // If start_time does not match the provided date, adjust start_time to baseDate + 00:00:00
                    const adjustedStartTime = new Date(
                        baseDateObject.getFullYear(),
                        baseDateObject.getMonth(),
                        baseDateObject.getDate(),
                        0,
                        0,
                        0
                    );
                    obj.start_time = Math.floor(adjustedStartTime.getTime() / 1000); // Update start_time in Unix timestamp format
                }
            }
    
            return obj; // Return the modified object
        });
    }
    
    

    const getAuctionSlot = (date) => {
        let req = { date: Math.floor(date / 1000) };

        apiService.getAuctionSlot(req, token).then(res => {
            // console.log("bookedSlots_________________sssssssssss", res?.data?.response_data.data["2"])
            const specificTime = "23:58:40";

            // Adjust the array
            let dateFirst = moment(parseInt(date)).format("YYYY-MM-DD")
            const dateSecond = moment(parseInt(date)).add(1, 'days').format("YYYY-MM-DD");

            const firstDayArray = adjustLastEndTime(res?.data?.response_data.data["1"], dateFirst);
            const secondDayArray = adjustLastEndTime(res?.data?.response_data.data["2"], dateSecond);
            console.log("updatedData111111111111",firstDayArray, secondDayArray, dateFirst, dateSecond, date);

            
            if (res?.data?.status_code == 1 && Array.isArray(res?.data?.response_data.data["1"])) {
                const bookedSlots = [];
                // console.log("bookedSlots_________________", res?.data?.response_data.data)
                firstDayArray.forEach((item) => {
                    const startTime = new Date(item.start_time * 1000);
                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    // let currentSlot = new Date(startTime);
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));


                    while (currentSlot < endTime) {
                        bookedSlots.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });


              

                console.log("res?.data?.response_data.data",res?.data?.response_data.data["1"])
                console.log("bookedSlots____33_____________1", bookedSlots)
                // Set the booked slots in state for later comparison
                setBookedStartTimeSlot(bookedSlots);

                const bookedSlots1 = [];
                firstDayArray.forEach((item) => {
                    // const startTime = new Date(item.start_time * 1000);

                    const newStartTime = new Date(item.start_time * 1000);
                    const startTime = new Date(newStartTime); // Create a copy of the original date
                    startTime.setMinutes(startTime.getMinutes() + 30);


                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));


                    while (currentSlot <= endTime) {
                        bookedSlots1.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                console.log("bookedSlots_________________1", bookedSlots1)
                // Set the booked slots in state for later comparison
                setBookedEndTimeSlot(bookedSlots1);

            } else {
            }

            if (res?.data?.status_code == 1 && Array.isArray(res?.data?.response_data.data["2"])) {
                console.log("bookedSlots_________________sssssssssss", res?.data?.response_data.data["2"])
                const bookedSSecondlots = [];
                console.log("bookedSlots_________________", res?.data?.response_data.data)
                secondDayArray.forEach((item) => {
                    const startTime = new Date(item.start_time * 1000);
                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));


                    while (currentSlot <= endTime) {
                        bookedSSecondlots.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                console.log("bookedSlots_________________1bookedSSecondlots", bookedSSecondlots)
                // Set the booked slots in state for later comparison
                setBookedSecondStartTimeSlot(bookedSSecondlots);



                const bookedSSecondlots1 = [];
                console.log("bookedSlots_________________", res?.data?.response_data.data)
                secondDayArray.forEach((item) => {
                    const newStartTime = new Date(item.start_time * 1000);
                    const startTime = new Date(newStartTime); // Create a copy of the original date
                    startTime.setMinutes(startTime.getMinutes() + 30);

                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));


                    while (currentSlot <= endTime) {
                        bookedSSecondlots1.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                console.log("bookedSlots_________________1bookedSSecondlots", bookedSSecondlots1)
                // Set the booked slots in state for later comparison
                
                setBookedSecondEndTimeSlot(bookedSSecondlots1);
            } else {
            }

        });
    };

    const selectLogoImage = (url, name) => {
        setError({ isImage: '' })
        removeItem();
        setSelectedLogoImage(url === selectedLogoImage ? null : url);
        setSelectedLogoImageType(name)
    };

    const getMax = (time) => {
        console.log("auctionDateString")
        let date = moment(auctionDateString).format("YYYY-MM-DD")
        const timeWithTimezone = moment(time).format("HH:mm:ss [GMT]ZZ [(India Standard Time)]"); // HH:mm:ss with timezone
        console.log("time____________________________Extracted Time with Timezone:", timeWithTimezone, date);

        // const selectedTime = moment(time).format("h:mm A");
        const combinedDateTime = moment(`${date} ${timeWithTimezone}`, "YYYY-MM-DD HH:mm:ss");

        const formattedCombinedDateTime = combinedDateTime.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
        //  console.log("Formatted Combined Date and Time:", formattedCombinedDateTime);

        const maxTime = findMaxTime(bookedStartTimeSlot, formattedCombinedDateTime);

        console.log("maxTime______________________", maxTime)
        // console.log("Maximum Time in Array (<= Key):", maxTime)
        let formattedDate = null;
        if (maxTime) {
            const adjustedTimestamp = maxTime;
            // const adjustedTimestamp = maxTime - 1800;
            // Convert the timestamp to a human-readable string
            formattedDate = moment.unix(adjustedTimestamp).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
        } else {
            formattedDate = new Date().setHours(23, 59);;
        }

        console.log("bookedStartTimeSlot_________________________dd", formattedDate, maxTime, bookedStartTimeSlot, formattedCombinedDateTime)
        return formattedDate;
    }


    const getMaxEndTime = (startTime) => {
        console.log("getMax_________________", startTime, getMax(startTime))
        return getMax(startTime)
    };

    useEffect(() => {

        console.log("useeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", selectedStartDate, endDate)
        if (selectedStartDate) {
            console.log("useeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee11111111111", getMaxEndTime(selectedStartDate))
            setMaxEndTime(getMaxEndTime(selectedStartDate));
        }

        // if (endDate) {
        //     console.log("useeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee222222222222", getMaxEndTime(endDate))
        //     setMaxEndTime(getMaxEndTime(endDate));
        // }
    }, [selectedStartDate, endDate]);


    console.log("maxEndTime_____________________", maxEndTime)


    const generateEndTimeOptions1 = (startTime) => {
        const endTimeOptions = [];
        const endOfDay = moment(selectedStartDate)
        const interval = 30; // 30-minute intervals
        let time = moment(selectedStartDate).add(interval, "minutes");
        // Loop through potential end times
        let bookSlot = (isTodayDate == true) ? bookedStartTimeSlot : bookedSecondStartTimeSlot;

        console.log("isTodayDateisTodayDateisTodayDate______", bookSlot, isTodayDate)
        const isDisabled = bookSlot.some((slot) => {
            const bookedTime = moment(slot);
            const bookedEndTime = moment(bookedTime).add(interval, "minutes");
            return time.isBetween(bookedTime, bookedEndTime, null, "[)"); // Inclusive of start time, exclusive of end time
        });

        // Push the time and its disabled state
        endTimeOptions.push({
            time: time.format("h:mm A"),
            disabled: isDisabled
        });

        console.log("endTimeOptions____________", endTimeOptions)
        return endTimeOptions;
    };

    console.log("bookedSecondEndTimeSlot", bookedSecondEndTimeSlot,
        "--------------------", bookedEndTimeSlot)

    const generateEndTimeOptions = (startTime) => {
        const endTimeOptions = [];
        const interval = 30; // 30-minute intervals

        // Define the end boundary for slot generation
        const endBoundary = isTodayDate
            ? moment(startTime).endOf("day") // For today, limit to the end of the day
            : moment(selectedEndDateConst).endOf("day"); // For other days, limit to the selectedEndDate

        const bookSlot = isTodayDate ? bookedEndTimeSlot : bookedSecondStartTimeSlot;
        let currentTime;
        if (isTodayDate) {
            currentTime = moment(startTime).startOf("day"); // Start generating time slots from the beginning of the day
        } else {
            currentTime = moment(selectedEndDateConst).startOf("day"); // Start of the next day
        }

        // console.log("bookSlotbookSlot", isTodayDate, selectedEndDateConst, startTime, "currentTime", currentTime, isTodayDate)

        console.log("generateEndTimeOptionsbookSlotbookSlot", endBoundary, selectedEndDate, "---------", startTime, "currentTime", currentTime, isTodayDate, bookSlot, "dddd", bookedEndTimeSlot)
        while (currentTime.isBefore(endBoundary)) {
            // Disable slots below or equal to the selectedStartDate

            // const isDisabledByStartTime = currentTime.isSameOrBefore(moment(startTime));


            const isNextSlot = currentTime.isSame(moment(startTime).add(interval, "minutes"));
       const isDisabledByStartTime = !isNextSlot && currentTime.isSameOrBefore(moment(startTime));
            // Disable slots after the selectedEndDate
            const isDisabledByEndDate = currentTime.isAfter(moment(selectedEndDateConst));

            // Disable slots based on booked slots
            const isDisabledByBookedSlot = bookSlot.some((slot) => {
                const bookedTime = moment(slot);
                const bookedEndTime = moment(bookedTime).add(interval, "minutes");
                return currentTime.isBetween(bookedTime, bookedEndTime, null, "[)");
            });
            let isDisabled;
            // Determine if the current time should be disabled
            if (isTodayDate)
                isDisabled = isDisabledByStartTime || isDisabledByEndDate || isDisabledByBookedSlot;
            else
                isDisabled = isDisabledByEndDate || isDisabledByBookedSlot;


            // Push the current time to the options
            endTimeOptions.push({
                time: currentTime.format("h:mm A"), // Format time for display
                disabled: isDisabled,
            });

            // Increment the current time by the interval
            currentTime = currentTime.add(interval, "minutes");
        }

        // console.log("Generated End Time Options:", endTimeOptions);
        let firstBreakingValue = null;

        for (let i = 0; i < endTimeOptions.length - 1; i++) {
            if (!endTimeOptions[i].disabled && endTimeOptions[i + 1].disabled) {
                firstBreakingValue = endTimeOptions[i + 1];
                break;
            }
        }
        console.log("ddddddddddddddddddeeeeeeeeeeeeeeeeeeee",true,firstBreakingValue?.time)

        const updatedSlots = disableSlotsAfter(endTimeOptions, firstBreakingValue?.time);
        // console.log("ddddddddddddddddddeeeeeeeeeeeeeeeeeeee",false,updatedSlots)

        return updatedSlots;
    };

    

    function disableSlotsAfter(slots, startTime) {
        let startDisabling = false; // Flag to track when to start disabling slots
    
        return slots.map(slot => {
            if (slot.time === startTime) {
                startDisabling = true; // Start disabling from this time onward
            }
            if (startDisabling) {
                slot.disabled = true;
            }
            return slot;
        });
    }
    

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                className='cstm_modal_edit_product'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className='close_icon _add_product' onClick={props.onHide}><span><IoCloseCircle /></span></div>
                    {
                        upImg ? <>
                            <div className="text-center mb-3 new_crop_btn">
                                {upImg ?
                                    <Button name="Crop" className="done_btn" onClick={handleFileUpload} />

                                    : ''}
                            </div>
                            <div className='crop_img' style={{ top: '-210px', zIndex: '9' }}>
                                <ReactCrop
                                    src={upImg}
                                    onImageLoaded={onLoad}
                                    crop={crop}
                                    onChange={c => setCrop(c)}
                                    onComplete={completedCrop}
                                />

                            </div>

                        </> :
                            <>
                                <span className='schedl_action avenir_black'>
                                    Schedule Live
                                </span>
                                <div className='auction_store_img'>
                                    {storeLogo &&
                                        <label className='selected_this' style={{ background: '#f0f0f0', border: selectedLogoImage === storeLogo ? '1px solid #F07721' : 'none' }}>
                                            <img src={storeLogo} height={40} width={50} className='selected_img'
                                                onClick={() => selectLogoImage(storeLogo, "storeLogo")} />
                                            {selectedLogoImage === storeLogo && <span className="tick"></span>}
                                        </label>
                                    }

                                    {isImageLoader == true ? <label style={{ border: 'none', background: '#ffc0cb00' }}><AddproductImg /></label> :
                                        <label>
                                            {(newUrl.length) ?

                                                <>
                                                    {(isImageLoader == false && newUrl.length > 0) && <p className='' onClick={removeItem}> <span className="tick" ></span></p>}
                                                    <img src={newUrl ? newUrl : '....'} className='selected_img' />
                                                </>
                                                :
                                                <div className='img_placholder'>
                                                    <img src='assets/photo_upld.svg' height={40} width={50} />
                                                    <p className='mt-3'>Upload Image</p>
                                                    <p>160*160</p>
                                                </div>
                                            }
                                            <input type="file" size="60" name="document" id="file-input" ref={inputRef} onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                                            {/* <span className='auction_pls_icn avenir_medium' title="160*240">+</span> */}


                                            {(newUrl.length <= 0) ?
                                                <span className='auction_pls_icn avenir_medium' title="160*160">+</span>
                                                :
                                                <span className='auction_pls_icn avenir_medium _edit' title="160*160"><FaPencil /></span>
                                            }
                                        </label>
                                    }
                                    <label style={{ background: '#f0f0f0', border: selectedLogoImage === headerLogoImage ? '1px solid #F07721' : 'none' }}>
                                        <img src={headerLogoImage} height={40} width={50} className='selected_img'
                                            onClick={() => selectLogoImage(headerLogoImage, "logo")}
                                        />
                                        {selectedLogoImage === headerLogoImage && <span className="tick"></span>}
                                    </label>
                                </div>
                                <div className='text-center'>
                                    <InlineError className='store_error' errorMessage={error.isImage} />
                                </div>

                                <span className="upload_store_logo_danger avenir_heavy">{userInput.documentErr}</span>
                                <div>
                                    <Input
                                        placeholder="Description"
                                        className="description_inpt"
                                        value={description}
                                        name="description"
                                        maxLength={90}
                                        minLength={10}
                                        onChange={onChangeDescription}
                                    />
                                    <InlineError className='store_error' errorMessage={error.description} />
                                </div>
                                <p className='category avenir_heavy mb-4"'>Category</p>
                                <span className='choose_category avenir_medium'>Choose the product’s category</span>
                                <div className='left_align_error'>
                                    <InlineError errorMessage={error.category} />
                                </div>
                                <div className='wrap_radio mt-3'>
                                    {props.categories.map(categoryItem => (
                                        <div key={categoryItem.id}>
                                            <input
                                                type="radio"
                                                className="radio_inpt"
                                                value={categoryItem.id}
                                                name="category"
                                                checked={category == categoryItem.id}
                                                onChange={onChangeCategory}
                                            />
                                            <span className='avenir_heavy'>{categoryItem.name}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className=' row'>
                                    <div className='col-lg-3 _date _form_with_icon'>
                                        <span className='avenir_heavy'>Start Date</span>
                                        <DatePicker
                                            selected={auctionDateString}
                                            onChange={onChangeAuctionDate}
                                            placeholderText="Select"
                                            className="form-control "
                                            minDate={new Date()}
                                            dateFormat="dd-M-yyyy" // Displays date and time in a readable format
                                            showIcon
                                            toggleCalendarOnIconClick
                                        />
                                    </div>
                                    <div className={`col-lg-3 _time ${auctionDateString ? '' : '_disabled'}`} >
                                        <span className='avenir_heavy'>Start Time</span>
                                        <span className='_clock_icon'><ClockIcon /></span>
                                        <DatePicker
                                            selected={selectedStartDate}
                                            onChange={handleStartTimeChange}
                                            placeholderText='Select'
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeInputLabel="Time:"
                                            dateFormat="h:mm aa"
                                            filterTime={filterStartTimeSlots}
                                            minTime={(startTimeTime)}
                                            maxTime={new Date(new Date().setHours(23, 59))}
                                            disabled={!auctionDateString}

                                        />
                                    </div>

                                    {console.log("endDateendDate", endDate)}
                                    {/* <div className='col-lg-3 _date _form_with_icon '> */}
                                    <div className={`col-lg-3 _date _form_with_icon ${selectedStartDate ? '' : '_disabled'}`} >
                                        <span className='avenir_heavy'>End Date</span>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={onChangeAuctionEndDate}
                                            placeholderText="Select"
                                            className="form-control "
                                            minDate={selectedStartDate}
                                            dateFormat="dd-M-yyyy" // Displays date and time in a readable format
                                            showIcon
                                            toggleCalendarOnIconClick
                                            includeDates={isStartTime10 ? [new Date(auctionDateString), addDays(new Date(auctionDateString), 1)] : [new Date(auctionDateString), addDays(new Date(auctionDateString), 0)]}
                                            minTime={selectedStartDate}
                                            maxTime={new Date(new Date().setHours(23, 59))}
                                            disabled={!selectedStartDate}
                                        />
                                    </div>

                                    {/* {console.log("maxEndTime_______________", isTodayDate, selectedStartDate, maxEndTime, new Date(selectedStartDate?.getTime() + 30 * 60000))} */}
                                    <div className={`col-lg-3 _time ${selectedStartDate ? '' : '_disabled'}`}>
                                        <span className='avenir_heavy'>End Time</span>
                                        <span className='_clock_icon'><ClockIcon /></span>
                                        <DatePicker
                                            selected={selectedEndDate}
                                            placeholderText='Select'
                                            onChange={handleEndTimeChange}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeInputLabel="Time:"
                                            dateFormat="h:mm aa"

                                            minDate={auctionDateString}
                                            // minTime={selectedStartDate ? new Date(selectedStartDate.getTime() + 30 * 60000) : null} // Ensure minTime is a Date object
                                            // maxTime={isTodayDate ? endDate : endDate + 20 * 60000}
                                            filterTime={(time) => {
                                                const options = generateEndTimeOptions(selectedStartDate);
                                                return !options.some(
                                                    (option) =>
                                                        option.time === moment(time).format("h:mm A") && option.disabled
                                                );
                                            }}
                                            injectTimes={[
                                                // setHours(setMinutes(setSeconds(new Date(), 10), 1), 0),
                                                // setHours(setMinutes(new Date(), 5), 12),
                                                // setHours(setMinutes(new Date(), 59), 23),
                                            ]}
                                        />

                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='left_align_error'>
                                            <InlineError errorMessage={error.datepic} />
                                        </div>
                                    </div>


                                    {(!startTime || !endTime || !selectedEndDate) ?
                                        <>  {
                                            !startTime ?
                                                <div className='col-lg-3'>
                                                    <div className='left_align_error'>
                                                        <InlineError errorMessage={error.startTime} />
                                                    </div>
                                                </div>
                                                :
                                                <div className='col-lg-3'>
                                                    <div className='left_align_error'>

                                                    </div>
                                                </div>
                                        }
                                            {
                                                !endTime ?
                                                    <div className='col-lg-3'>
                                                        <div className='left_align_error'>
                                                            <InlineError errorMessage={error.endTime} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='col-lg-3'>
                                                        <div className='left_align_error'>

                                                        </div>
                                                    </div>
                                            }
                                            {
                                                !selectedEndDate ?
                                                    <div className='col-lg-3'>
                                                        <div className='left_align_error'>
                                                            <InlineError errorMessage={error.selectedEndDate} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='col-lg-3'>
                                                        <div className='left_align_error'>

                                                        </div>
                                                    </div>
                                            }

                                        </> : ''
                                    }
                                </div>
                                <div className='text-center mt-4'>

                                    <Button
                                        name={btnLoader ? <BtnLoader /> : "Submit"}
                                        onClick={(e) => addAuction(e)}
                                    />
                                </div>
                            </>}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddAuction