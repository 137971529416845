import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function CancellationPolicy() {
    const navigate = useNavigate();
    const goBack = () => { navigate(-1); };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='privacy_policy_parent'>
            <div className='header'>
                <span onClick={goBack}><MdArrowBackIos /></span>
                <Link to="/">  <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
            </div>
            <div className='privacy_policy'>
                <h4>About Us:</h4>
            </div>
        </div>
    )

}
export default CancellationPolicy