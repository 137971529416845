import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'


function ShippingDelivery() {
        const navigate = useNavigate();
        const goBack = () => { navigate(-1); };

        useEffect(() => {
                window.scrollTo(0, 0)
        }, [])

        return (
                <div className='privacy_policy_parent'>
                        <div className='header'>
                                <span onClick={goBack}><MdArrowBackIos /></span>
                                <Link to="/">  <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
                        </div>
                        <div className='privacy_policy'>
                                <h4>Shipping & Delivery:</h4>
                                {/* <h4>Shipping</h4> */}


                                <p>We partnered with Ecom Express - once the seller receives an order, it will be forwarded to Ecom Express for label generation. Sellers need to recharge their accounts to get the labels. They can recharge and obtain the label using any UPI method, credit card, debit card, or net banking through a secure payment gateway.
                                </p><p>Customers can expect to receive their product within 7 business days, depending on their location. However, specific delivery times may vary. Details are provided below.
                                </p><p>
                                        Shipping times depend on your location and address. Our shipping partners work hard to ensure timely delivery. Below are the estimated delivery times based on different zones:
                                </p>
                                <p><b>● Intra-city (Zone A):</b> 2 Days </p>
                                <p><b>● Intra-region (Zone B):</b> 3 Days </p>
                                <p><b>● Metro Areas (Zone C):</b> 4 Days </p>
                                <p><b>● Rest of India (Zone D):</b> 6 Days </p>
                                <p><b>● Jammu & Kashmir (Zone E):</b> 7 Days </p>
                                <p><b>● Northeast & Andaman (Zone F):</b> 7 Days </p>
                                <p><b>● Upcountry Locations (Zone G):</b> 7 to 8 Days </p>
                                <p><b>● Rest of State (Zone H):</b> 7 to 8 Days </p>
                                <p>Please note that delivery times may vary based on factors beyond our control. </p>
                        </div>
                </div>
        )
}

export default ShippingDelivery