import React from 'react';
import logo from '../../src/assets/landing/logo.svg';
import { Link } from 'react-router-dom';
import leftImg from '../../src/assets/landing/leftbanner1.png';
import bidboxlaptop from '../../src/assets/landing/bidboxlaptop.JPEG';
import bottomshape from '../../src/assets/landing/bottomshape.png';
import downArrow from '../../src/assets/landing/down-arrow.json';
import Lottie from 'react-lottie';
import QrSection from './QrSection';
import video1 from '../../src/assets/landing/video1.mp4';
import video1text from '../../src/assets/landing/video1-text-layout2.svg';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: downArrow,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function BannerSection() {
    return (
        <>
            <div className="header_section">
                <a href='/'>
                    <img src={logo} style={{ height: "62px" }} />
                </a>
                {/* <Link className={`btn btn-primary`} to="/login">
                    Become a seller
                </Link> */}
            </div>
            <div className='_banner'>
                <div className='_left'>
                    <img src={leftImg} alt="image" className='img-fluid' />
                    <div className='frame-video'>
                        <div className="video-container">
                            <video className="html-video" autoPlay loop muted>
                                <source src={video1} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        {/* <img src={video1text} alt="image" className='img-fluid video_text' /> */}
                    </div>
                </div>
                <div className='_right'>
                    <div className='_text'>
                        <h1>One-Stop Destination for <span>QUALITY & STYLE</span></h1>
                        <p>Buy, Sell & Discover products you’ll love</p>
                        <QrSection color="white" heading="DOWNLOAD BIDSBOXX" />
                    </div>
                </div>
                <img src={bidboxlaptop} alt="image" className='laptop_hand_img' />
                <div className='bottom-banner'>
                    <div className='arrow_loty'>
                        <Lottie options={defaultOptions}
                            height={80}
                            width={80}
                        />
                    </div>
                    <img src={bottomshape} alt="image" />
                </div>

            </div>
        </>
    )
}

export default BannerSection