import React from 'react';
import banner from '../../assets/dash-banner2.png';
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <>
      <div className='_left'>
        <img src={banner} alt="banner" className='show_desktop' />
        <h1 className="show_mobile">Expand your product range to boost your sales</h1>
        <Link to="/product" className="btn  btn-sm lower_case common_button avenir_heavy">Add product</Link>
      </div>
      <div className='_right'>
        <div className='_center'>
          <h5>Schedule lives to boost your profits</h5>
          <Link to="/auction" className="btn  btn-sm lower_case common_button avenir_heavy">Schedule Live</Link>
        </div>
      </div>
    </>
  )
}

export default Banner