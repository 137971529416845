import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import localforage from "localforage";

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyBJzK93UOrTeSETLdPGPD-GYUt6Vq23gUs",
  authDomain: "bidsboxx-dev.firebaseapp.com",
  projectId: "bidsboxx-dev",
  storageBucket: "bidsboxx-dev.appspot.com",
  messagingSenderId: "231180436003",
  appId: "1:231180436003:web:b8fb252874dbbd2da8e796",
};

const vapidKey =
  "BEY1YsP_wqvGGTRwquUcsHCd2e9JEp1nZKMBJPl_SZU0LpLJGzWTaksDvxXkN2esOPQ5FtRHJq5lTGWhs2nYEUQ";

// Initialize Firebase App
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const firebaseCloudMessaging = {
  init: async () => {
    try {
      const tokenInLocalForage = await localforage.getItem("fcm_token");

      // Return the existing token if found
      if (tokenInLocalForage) {
        return tokenInLocalForage;
      }

      // Request permission for notifications
      const status = await Notification.requestPermission();
      console.log("Notification Permission Status:", status);

      if (status === "granted") {
        const fcm_token = await getToken(messaging, {
          vapidKey: vapidKey, // Use the provided VAPID key
        });

        console.log("FCM Token:", fcm_token);

        // Store the token
        if (fcm_token) {
          await localforage.setItem("fcm_token", fcm_token);
          return fcm_token;
        }
      }
    } catch (error) {
      console.error("FCM Error:", error);
      return null;
    }
  },
};

// Handle Foreground Messages
onMessage(messaging, (payload) => {
  console.log("Foreground Message received:", payload);

  const notificationTitle = payload.notification?.title || "New Notification";
  const notificationOptions = {
    body: payload.notification?.body || "You have a new message.",
    icon: "/firebase-logo.png", // Change this to your own notification icon
  };

  console.log("Notification Title:", notificationTitle);
  console.log("Notification Options:", notificationOptions);

  // Show notification in the browser
  if (Notification.permission === "granted") {
    new Notification(notificationTitle, notificationOptions);
  }
});

// Register the Service Worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered successfully:", registration);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

export { firebaseCloudMessaging, messaging };
