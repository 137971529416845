import React, { useEffect, useRef, useState } from 'react';
import "../../StyleComponent/dashboard.scss";
import LiveSection from './LiveSection';
import StatsPage from './StatsPage';
import Stats1 from '../../assets/stats1.svg';
import Stats2 from '../../assets/stats2.svg';
import Stats3 from '../../assets/stats3.svg';
import Stats4 from '../../assets/stats4.svg';
import Banner from './Banner';
import GraphSection from './GraphSection';
import TopProduct from './TopProduct';
import ScrollComponent from '../../CommonComponent/ScrollComponentOld';
import { apiService } from '../../Services/ApiServices';
import moment from "moment";
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import { Carousel } from 'react-bootstrap';
import { LiveSectionSkeleton } from '../../CommonComponent/Skeleton';
import { firebaseCloudMessaging } from '../../CommonComponent/Firebase';


function Dashboard() {
  const logout = useLogout();
  const statsRef = useRef(null);
  const liveRef = useRef(null);
  const token = localStorage.getItem('token');
  const [active, setActive] = useState("1");
  const [dasboardDetails, setDasboardDetails] = useState({})
  const [days, setDays] = useState([]);
  const [transformedDatas, setTransformedDatas] = useState([]);

  const fetchDashboardDetails = async (days) => {
    const req = { days: days };
    try {
      apiService.dashboardList(req, token).then(result => {
        if (result?.data?.status_code == 1) {
          setDasboardDetails(result?.data?.response_data?.dashboard);
          if (days == 1 || days == 2) {
            const transformedData = result?.data?.response_data?.dashboard?.profit_graph?.map(item => ({
              date: moment(item?.date).format('DD'),
              profit: item.profit
            })
            )
            setTransformedDatas(transformedData);
          }
          else if (days == 3 || days == 4) {
            const transformedData = result?.data?.response_data?.dashboard?.profit_graph?.map(item => {
              return ({
                date: moment(item?.date).format('MM'),
                profit: item.profit
              })
            }
            )
            setTransformedDatas(transformedData)
          }
        }
        else {
          if (result?.data?.status_code != 1) {
            notify("err", result?.data?.error?.message?.error);

            if (result?.data?.status_code == 2)
              logout();

            if (result?.data?.status_code == 5)
              logout();
          }
        }
      })
    }
    catch (e) {
      console.log("Error:", e)
    }

  }

  const tabBtnClick = (days, activetab) => {
    setActive(activetab)
    setDays(days)
    fetchDashboardDetails(days)
  }

  const statsData = [
    {
      "icon": Stats1,
      "count": dasboardDetails?.total_live,
      "text": "Total number of lives"
    },
    {
      "icon": Stats2,
      // "count":"₹2k",
      "count": dasboardDetails?.hottest_category_revenue?.revenue,
      "text": "Hottest Category Revenue",
      "text1": dasboardDetails?.hottest_category_revenue?.name,
    },
    {
      "icon": Stats3,
      // "count":"10k",
      "count": dasboardDetails?.overall_profit,
      "text": "Overall Profit"
    },
    {
      "icon": Stats4,
      "count": dasboardDetails?.average_live_viwers,
      "text": "Average Live Viewers"
    },
    {
      "icon": Stats2,
      // "count":"₹2k",
      "count": dasboardDetails?.per_category_avg_revenue,
      "text": "Per category average revenue"
    },
    {
      "icon": Stats2,
      // "count":"₹2k",
      "count": dasboardDetails?.hottest_product?.revenue,
      "text": "Hottest Product Revenue",
      "text1": dasboardDetails?.hottest_product?.description?.name,
    }
  ]

  const LiveSectionData = [
    dasboardDetails?.auction_list?.map((item) => {
      return {
        "id": item.id,
        "img": item.image,
        "top": "Live ends in 20 sec",
        "bottom": {
          "p": item.description,
          "h": item.category_name,
        }
      }
    })
  ]

  useEffect(() => {
    fetchDashboardDetails(1)
    sendFCMAPI()
  }, [])


  const sendFCMAPI = async () => {
    try {

      let fcmToken = await getFCMToken();
      if (fcmToken && fcmToken.length) {
        localStorage.setItem("fcm_token", fcmToken);
        let getResponse = await apiService.sendFCMAPI(token, { fcm_token:fcmToken });
        console.log("send FCM API RESPONSE", getResponse);
        if (getResponse.statusCode === 0) {
          console.log("send FCM API ERROR ", getResponse);
        }
      }

    } catch (e) {
      console.error("send FCM API  ERROR", e);
    }
  };

  const getFCMToken = async () => {
    try {
      const token = await firebaseCloudMessaging.init();
      // alert('new token',token)
      if (token) {
        console.log("geting FCM token", token);
        return token;
      }
    } catch (error) {
      console.log("fcm token error", error);
    }
  };


  return (
    <div className='box_section'>
      <div className='dashboard_inner position_relative'>
        <div className='_dasboard_section'>
          <div className='_pannel_group mt-3 mb-4'>
            <button className={`_tab_bn ${active === "1" ? '_active' : ''}`} onClick={() => tabBtnClick(1, "1")}>15 Days</button>
            <button className={`_tab_bn ${active === "2" ? '_active' : ''}`} onClick={() => tabBtnClick(2, "2")} >1 Month</button>
            <button className={`_tab_bn ${active === "3" ? '_active' : ''}`} onClick={() => tabBtnClick(3, "3")} >6 Months</button>
            <button className={`_tab_bn ${active === "4" ? '_active' : ''}`} onClick={() => tabBtnClick(4, "4")} >1 Year</button>
          </div>
          <div className='_pannel_body'>
            {dasboardDetails?.auction_list?.length > 0 &&
              <div style={{ position: "relative" }}>
                <ScrollComponent containerRef={liveRef} authLength={dasboardDetails?.auction_list?.length} elementWidth={600}>
                  <div className='_live_section' ref={liveRef}>
                    <LiveSection LiveSectionData={LiveSectionData} />
                  </div>
                </ScrollComponent>
              </div>
            }
            <div className='_mobile_live_Section'>
              {LiveSectionData[0]?.length > 1 ?
                <Carousel fade>
                  {LiveSectionData[0]?.map((item) => {
                    return (
                      <Carousel.Item>
                        <img src={`${item?.img}`} className="_baner_img" alt="banner" />
                        <Carousel.Caption>
                          <div className='_bottom'>
                            <div className='_left'>
                              <p>{item?.bottom?.p}</p>
                              <h3>{item?.bottom?.h}</h3>
                            </div>
                            <div className='_right'>
                            </div>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                    )
                  })}
                </Carousel>
                :
                <LiveSectionSkeleton />
              }
            </div>
            <div style={{ position: "relative" }} >
              <ScrollComponent containerRef={statsRef} authLength={statsData.length} elementWidth={230}>
                <div className='_stats_section' ref={statsRef}>
                  <StatsPage statsData={statsData} />
                </div>
              </ScrollComponent>
            </div>
            <div className='_banner_section'>
              <Banner />
            </div>
            <div className='_graph_section mt-5'>
              <GraphSection dasboardDetails={dasboardDetails} data={transformedDatas} />
            </div>
            <br />
            {dasboardDetails?.Hottest_five_product?.length > 0 &&
              <div className='_product_section mt-5' style={{ position: "relative" }}><br />
                <h3 className='mb-4 mt-3 text-start'>Hottest selling 10 products in latest 3 lives</h3>
                <TopProduct productLists={dasboardDetails?.Hottest_five_product} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard



