import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function AboutUs() {
        const navigate = useNavigate();
        const goBack = () => { navigate(-1); };

        useEffect(() => {
                window.scrollTo(0, 0)
        }, [])

        return (
                <div className='privacy_policy_parent '>
                        <div className='content'>

                                <div className='inner_content'>

                                </div></div>
                                
                        <div className='header'>
                                <span onClick={goBack}><MdArrowBackIos /></span>
                                <Link to="/">  <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
                        </div>
                        <div class="privacy_policy">
                                <h5>About Us:</h5>
                                <p>
                                        At Bidsboxx, we offer a diverse selection of products through our exciting live sales events. Our platform features a wide variety of categories to cater to every interest and need. For fashion enthusiasts, we offer <b>Men's Fashion, Sneakers & Streetwear, Women's Fashion wear, and Bags & Accessories.</b> Our collection extends to <b>Beauty Products, Jewelry, Music, Video Games, Antiques and Vintage Decor, Home & Garden Items, Rocks & Crystals, and Baby & Kid's products.</b> Additionally, we feature <b>Arts & Handmade</b> items. With such a broad range of categories, there's something for everyone at Bidsboxx!
                                </p>
                                <hr />

                                <h5>How the Bidsboxx Bargaining System Works:</h5>
                                <br />
                                <h6>For Sellers:</h6>
                                <div className='content'>
                                        <p>
                                                1. <b>Host Live Sales:</b> Sellers can host live sales events where they showcase their products in real-time. This is similar to a physical market experience, allowing buyers to see products and interact with the seller.
                                        </p>
                                        <p>
                                                2. <b>Product Presentation:</b> During the live sale, sellers provide detailed information and demonstrate the features of their products. This helps buyers make informed decisions.
                                        </p>
                                </div>

                                <br />
                                <h6>For Buyers:</h6>
                                <div className='content'>
                                        <p>
                                                1. <b>Add Bidsboxx Coins to Your Wallet:</b> To participate in a live sale, you need to add Bidsboxx coins to your Bidsboxx wallet. You can do this using UPI, credit/debit cards, or net banking. Be aware that third-party charges may apply during the transaction.
                                        </p>
                                        <p>
                                                2. <b>Join Live Sales:</b> Browse ongoing live sales and review the product details shared by the seller. When you find an item you like, you can place an offer.
                                        </p>
                                        <p>
                                                3. <b>Recharge Wallets:</b> Buyers must recharge their wallets before making purchases from live sales. Recharge your wallet easily. You can recharge it via PG (UPI/Account Transfer/Credit Card).
                                        </p>
                                        <p>
                                                4. <b>Live Sale:</b> Once the live sale begins, the seller will showcase products through live streaming, and buyers can purchase these products live, depending on the amount in their wallet.
                                        </p>
                                        <p>
                                                5. <b>Recharging Your Wallet:</b> If needed, you can always recharge your wallet to participate in more live sales and place additional offers on products you like.
                                        </p>
                                        <p>
                                                6. <b>Winning the Offer:</b> If you place the winning offer, the amount will be deducted from your wallet, and you’ll receive instructions on how to complete the purchase.
                                        </p>
                                </div>

                                <hr />

                                <h5>How to Buy on Products:</h5><br />
                                <p><b>1. Adding Bidsboxx Coins to Your Wallet:</b></p>
                                <div className='content'>
                                        <p>
                                                To participate in live sales on Bidsboxx, you need to have sufficient Bidsboxx Coins in your wallet. Here’s how you can add Coins to your wallet:
                                        </p>
                                        <p>
                                                <b>• Payment Methods:</b> You can add Bidsboxx Coins to your Bidsboxx wallet by purchasing them using a variety of payment methods, including UPI (Unified Payments Interface), credit/debit cards, or net banking. Choose the method that’s most convenient for you.
                                        </p>
                                        <p>
                                                <b>• Easy Process:</b> The process is simple and user-friendly. Select your preferred payment method, enter the number of coins you wish to add, and follow the prompts to complete the transaction.
                                        </p>
                                        <p>
                                                <b>• Third-Party Charges:</b> Be aware that during the process of adding funds, third-party charges may be applied by the payment service providers. These charges are outside of Bidsboxx’s control and are part of the payment processing fees imposed by banks or payment gateways.
                                        </p>
                                        <p>
                                                <b>• Confirmation:</b> Once the transaction is completed, the coins will be added to your Bidsboxx wallet, and you’ll be able to use them to place offers in live sales events.
                                        </p>
                                </div>

                                <br />
                                {/* <h5>How to Buy the Product:</h5> */}
                                <p><b>2. How to Buy the Product:</b></p>
                                <div className='content'>
                                        <p>
                                                Once you’ve added funds to your wallet, follow these steps to place an offer:
                                        </p>
                                        <p>
                                                <b>• Browse Live Sales:</b> Explore the ongoing live sales events to find products you’re interested in. You can see the products being showcased and reviewed in real-time.
                                        </p>
                                        <p>
                                                <b>• Review Product Information:</b> Check the details and features of each product as presented by the seller. This information will help you make an informed decision.
                                        </p>
                                        <p>
                                                <b>• Buy Products:</b> When you find a product you like, you can make a purchase directly. Ensure the amount is within your budget and that you have enough funds in your wallet to complete the purchase.
                                        </p>
                                        <p>
                                                <b>• Check Your Balance:</b> If you don’t have sufficient balance, you won’t be able to place an offer. If needed, you can add more funds to your wallet and try the process again.
                                        </p>
                                </div>

                        </div>

                </div>
        )
}

export default AboutUs