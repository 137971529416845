import React, { useEffect, useReducer, useState, useCallback } from 'react'
import Input from '../../CommonComponent/Input'
import "../../StyleComponent/AuthStyle.css"
import Button from '../../CommonComponent/Button'
import Lottie from 'react-lottie';
import Animation from '../../Utils/Animation.json'
import { apiService } from '../../Services/ApiServices';
import Logo from '../../CommonComponent/Logo';
import { useNavigate } from 'react-router';
import InlineError from '../../CommonComponent/inlineError';
import BtnLoader from '../../CommonComponent/BtnLoader';

import { useDispatch } from 'react-redux';
import { setStoreInfo } from '../../CommonComponent/actions';


function Login() {
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [mobile, setMobile] = useState("");
    const [isEmailOrPhone, setEmailOrPhone] = useState(0);
    const [email, setEmail] = useState("");
    const [btnLoader, setBtnLoader] = useState(false);
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const navigate = useNavigate()
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Animation,
        renderer: 'svg'
    }

    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            firstName: '', lastName: '', email: '', phone: '', countryCode: '',
            password: '', loading: false, isResponse: false, isClearable: true, isLoading: true,
            value: ''
        }
    );

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            firstName: '', lastName: '', email: '', phone: '', countryCode: '',
            password: '', errorMsg: '', value: ''
        }
    );

    const handleChange = e => {
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
        setError({ ...error, [e.target.name]: '', });
    };

    const handleValidate = () => {
        let validate = true;
        let { password } = userInput;
        setError({});
        if ((password == undefined) || (password == '')) {
            setError({ password: 'Please enter password' });
            validate = false
        }
        return validate;
    }

    function isPhoneNumberOrEmail(e) {
        let value = e.target.value;
        // Regular expression for a simple email address
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (/^\d+$/.test(value) || value == '') {
            setEmailOrPhone(1)
            setMobile(e.target.value);
        } else if (emailRegex.test(value)) {
            setEmailOrPhone(2)
            setEmail(e.target.value);
        } else {
            setEmailOrPhone(0)
        }
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
    }



const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

    const handleLogin1 = () => {
        setIsButtonDisabled(!isButtonDisabled);
        setBtnLoader(true);

        try {
            if (handleValidate() == true) {
                let req = {}
                if (isEmailOrPhone == 1) {
                    req = { password: userInput.password, phone_number: mobile, country_code: "+91" }
                } else {
                    req = { email_address: email.toLowerCase(), password: userInput.password }
                }
                setUserInput({ isLoader: true })
                apiService.userWebLogin(req).then(result => {
                    if (result.data?.status_code == 1) {
                        setBtnLoader(false);
                        localStorage.setItem('token', result.data?.response_data.access_token);
                        const jsonData = JSON.stringify(result?.data?.response_data?.categories);
                        sessionStorage.setItem('name', result.data?.response_data?.store_name);
                        sessionStorage.setItem('logo', result.data?.response_data?.store_image);
                        localStorage.setItem('logo', result.data?.response_data?.store_image);
                 
                        if (result.data?.response_data.username == false) {
                            setBtnLoader(false);
                            localStorage.setItem('first_name', result.data?.response_data?.user_details.first_name);
                            localStorage.setItem('last_name', result.data?.response_data?.user_details.last_name);
                            localStorage.setItem('email_address', result.data?.response_data?.user_details.email_address);
                            localStorage.setItem('phone_number', result.data?.response_data?.user_details.phone_number);
                            localStorage.setItem('country_code', result.data?.response_data?.user_details.country_code);
                            navigate("/signup-process", { state: 0 })
                        } else if (result.data?.response_data.register_store == false) {
                            setBtnLoader(false);
                            navigate("/signup-process", { state: 1 })
                        } else if (result.data?.response_data.add_bank == false) {
                            setBtnLoader(false);
                            navigate("/signup-process", { state: 2 })
                        } else if (result.data?.response_data.add_categories == false) {
                            setBtnLoader(false);
                            localStorage.setItem('categories_list', jsonData);
                            navigate("/signup-process", { state: 3 })
                        } else {
                            localStorage.setItem('isMobileVarified',true);
                            localStorage.setItem('isVarifiedEmail',true);    
                            localStorage.setItem('socketload', "false");
                            localStorage.setItem('isLoggedIn', 'true');
                            navigate("/dashboard")
                        }
                    } else {
                        setError({ errorMsg: result?.data?.error.message.error });
                        setBtnLoader(false);
                        setUserInput({
                            isLoader: false
                        })
                    }
                })
            }
        } catch (e) {
            console.error('e', e);
        }
    }

    const handleLogin = useCallback(
        debounce(() => {
            if (isButtonDisabled) return;
    
            setIsButtonDisabled(true);
            setBtnLoader(true);
    
            try {
                if (handleValidate() == true) {
                    let req = {};
                    if (isEmailOrPhone == 1) {
                        req = { password: userInput.password, phone_number: mobile, country_code: "+91" };
                    } else {
                        req = { email_address: email.toLowerCase(), password: userInput.password };
                    }
                    setUserInput({ isLoader: true });
                    apiService.userWebLogin(req).then(result => {
                        if (result.data?.status_code == 1) {
                            setBtnLoader(false);
                            localStorage.setItem("token", result.data?.response_data.access_token);
                            const jsonData = JSON.stringify(result?.data?.response_data?.categories);
                            sessionStorage.setItem("name", result.data?.response_data?.store_name);
                            sessionStorage.setItem("logo", result.data?.response_data?.store_image);
                            localStorage.setItem("logo", result.data?.response_data?.store_image);
    
                            if (result.data?.response_data.username == false) {
                                setBtnLoader(false);
                                localStorage.setItem("first_name", result.data?.response_data?.user_details.first_name);
                                localStorage.setItem("last_name", result.data?.response_data?.user_details.last_name);
                                localStorage.setItem("email_address", result.data?.response_data?.user_details.email_address);
                                localStorage.setItem("phone_number", result.data?.response_data?.user_details.phone_number);
                                localStorage.setItem("country_code", result.data?.response_data?.user_details.country_code);
                                navigate("/signup-process", { state: 0 });
                            } else if (result.data?.response_data.register_store == false) {
                                setBtnLoader(false);
                                navigate("/signup-process", { state: 1 });
                            } else if (result.data?.response_data.add_bank == false) {
                                setBtnLoader(false);
                                navigate("/signup-process", { state: 2 });
                            } else if (result.data?.response_data.add_categories == false) {
                                setBtnLoader(false);
                                localStorage.setItem("categories_list", jsonData);
                                navigate("/signup-process", { state: 3 });
                            } else {
                                localStorage.setItem("isMobileVarified", true);
                                localStorage.setItem("isVarifiedEmail", true);
                                localStorage.setItem("socketload", "false");
                                localStorage.setItem("isLoggedIn", "true");
                                navigate("/dashboard");
                            }
                        } else {
                            setError({ errorMsg: result?.data?.error.message.error });
                            setBtnLoader(false);
                            setUserInput({
                                isLoader: false,
                            });
                        }
                    }).finally(() => {
                        setIsButtonDisabled(false); // Re-enable the button after the operation completes
                    });
                }
            } catch (e) {
                console.error("Error during login", e);
                setIsButtonDisabled(false); // Re-enable the button in case of an error
            }
        }, 300),
        [isButtonDisabled, userInput, email, mobile]
    );


    useEffect(() => {
        if (localStorage.getItem('isLoggedIn')) {
            navigate('/dashboard')
        }
        else {
            localStorage.clear()
        }
    }, [])


    const saveStoreInfo = (storeImage, storeName ) => {
        dispatch(setStoreInfo(storeImage, storeName));
      };

      
    return (
        <div className="auth_main_section">
            <div className='left_side'>
                <div className='logo_section'>
                    <Logo />
                </div>
                <div>
                    <div>
                        <Lottie
                            options={defaultOptions}
                            height={160}
                            width={160}
                        />
                    </div>
                    <img src='assets/sign-in-girl.svg' alt='women' className='sign_in_girl' />
                </div>

            </div>
            <div className='right_side'>
                <div className='right_section_width'>
                    <div className='mob_signup_logo'>
                        <img src='assets/sinupMobLogo.svg' />
                    </div>
                    <h1 className='sign_in_heading avenir_black'>Sign In</h1>
                    <p className='welcome_txt avenir_heavy'> <b>Welcome back,</b> <span className='avenir_medium'>let’s get started</span> </p>
                    <div className='wrap_inpt wrap_option position_relative'>
                        {
                            (isEmailOrPhone == 1) ?
                                <span className='county_code'>🇮🇳 +91</span>
                                :
                                <></>
                        }

                        <Input
                            placeholder="Phone Number / Email ID"
                            maxLength={50}
                            minLength={10}
                            name="value"
                            className={(isEmailOrPhone == 1) ? 'number_inpt' : 'number_inpt ps-0'}
                            value={userInput.value}
                            onChange={(e) => isPhoneNumberOrEmail(e)}
                        />
                    </div>
                    <div className='wrap_inpt position_relative'>
                        <Input
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            maxLength={15}
                            minLength={10}
                            value={userInput.password}
                            onChange={(e) => handleChange(e)}
                        />

                        <InlineError errorMessage={error.password} />
                        <img onClick={handleTogglePassword} className='eye_icon cursor_pointer' src={showPassword ? 'assets/eye.svg' : 'assets/close-eye.svg'} />

                    </div>
                    <InlineError errorMessage={error.errorMsg} />
                    <div className='wrap_btn'>
                        {
                            ((isEmailOrPhone == 0) || ((userInput.value).length == 0) || (userInput.password.length < 1)) ?
                                <Button name={"Continue"} disabled="true" />
                                :
                                ((isEmailOrPhone == 1) || ((userInput.value).length == 0)) ?
                                    <Button name={"Continue"} onClick={handleLogin} />
                                    :
                                    <Button name={btnLoader ? <BtnLoader /> : "Continue"} onClick={handleLogin} />
                        }

                    </div>
                    <p className='forget_txt avenir_medium'>Forget Password? <a href='/reset_password' className='reset'> <b>Reset</b></a></p>
                    <p className='dont_have_acc avenir_medium'>Don’t have an account?  <a href="/signup" className='register'><span>Register</span> </a></p>
                </div>
            </div>
        </div>


    )
}

export default Login