import React, { useReducer, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import moment from "moment";
import Button from '../../CommonComponent/Button';
import Modal from 'react-bootstrap/Modal';
import Input from "../../CommonComponent/Input"
import { useNavigate } from 'react-router';
import { apiService } from '../../Services/ApiServices';
import Form from "react-bootstrap/Form";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg } from "../../CommonComponent/Helper";
import BtnLoader from '../../CommonComponent/BtnLoader';
import InlineError from '../../CommonComponent/inlineError';
import { IoCloseCircle } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClockIcon } from '../../Svg';
import { AddproductImg } from '../../CommonComponent/Skeleton';
import { checkFileSize } from '../../CommonComponent/Validation';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import { EditIcon } from '../../Svg';
import { FaPencil } from 'react-icons/fa6';
import { addDays, setHours, setMinutes, setSeconds } from 'date-fns';
import debounce from 'lodash.debounce';


function EditAuction(props) {
    const logout = useLogout();
    const dateInputRef = useRef(null);
    const navigate = useNavigate();
    const [btnLoader, setBtnLoader] = useState(false);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [imageId, setImageId] = useState('');
    const [auctionDate, setAuctionDate] = useState(null);
    const [auctionDateString, setAuctionDateString] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const token = localStorage.getItem('token');
    const [document, setDocument] = useState([]);
    const [image, setImage] = useState([]);
    const [newUrl, setNewUrl] = useState([]);
    const [cImage, setCImage] = useState('');
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const inputRef = useRef();
    const [isAllAdded, setisAllAdded] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [selectedEndDateConst, setSelectedEndDateConst] = useState();
    const [isImageLoader, setisImageLoader] = useState(false);
    // const headerLogoImage = 'https://d2ugmqijh93rc0.cloudfront.net/bidsboxx_images/blob_9iMLMFC';
    const headerLogoImage = 'https://d2ugmqijh93rc0.cloudfront.net/bidsboxx_images/blob_j9CYExM';

    const [selectedLogoImage, setSelectedLogoImage] = useState(null);
    const [selectedLogoImageType, setSelectedLogoImageType] = useState(null);
    const storeLogo = localStorage.getItem('logo');
    const [listCategory, setListCategory] = useState(props?.categories);

    const [conststartTime, setconstStartTime] = useState(null);
    const [constendTime, setconstEndTime] = useState(null);
    const [selectedIsFirst, setselectedIsFirst] = useState(true);

    const [isAuctionDate, setIsAuctionDate] = useState(true);


    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            isLoader: true,
            data: {},
            isResponse: '',
            search: '',
            searchType: '',
            inactive: '',
            bP: '',
            totalCount: '',
            activePage: 1,
            dir: '',
            sortValue: '',
            type: '',
            image_type: ''

        }
    );

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            description: '',
            category: '', datepic: '', document: '', media: '', startTime: '', endTime: ''
        }
    )
    const [bookedStartTimeSlot, setBookedStartTimeSlot] = useState([]);

    const [bookedSecondEndTimeSlot, setBookedSecondEndTimeSlot] = useState([]);

    const [bookedEndTimeSlot, setBookedEndTimeSlot] = useState([])
    const [startTimeTime, setTimeStartTime] = useState(null);

    const [isTodayDate, setisTodayDate] = useState(false);
    const [bookedSecondStartTimeSlot, setBookedSecondStartTimeSlot] = useState([]);
    const [endDate, setEndDate] = useState();

    const [isStartTime10, setIsStartTime10] = useState(false);
    const [maxEndTime, setMaxEndTime] = useState();
    const [slotLoading, setSlotLoading] = useState(false);

    const [maxTime, setMaxTime] = useState();

    const [isSlotsApiHit, setIsSlotsApiHit] = useState(false);
    const [isAuctionApiHit, setIsAuctionApiHit] = useState(false);

    const onChangeAuctionDate = (e) => {
        // debugger;
        setError({ datepic: '' })
        setSelectedStartDate(null)
        setMaxEndTime(null)
        setEndDate(null)
        setEndTime(null)
        setStartTime(null)
        setSelectedEndDate(null)
        setSelectedEndDateConst(null)
        let startDate = e ? moment(e).format("x") : "";
        setAuctionDateString(e);
        let startDateX = + new Date();
        let startDatem = e ? moment(e).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)") : "";
        if (startDateX <= startDate) {
            setTimeStartTime(startDatem)
        } else {
            setTimeStartTime(new Date());
            setSelectedStartDate(null)
            setSelectedEndDate(null)
        }
        setAuctionDate(startDate)
        getAuctionSlot1(startDate, conststartTime, constendTime)
    };



    const getAuctionSlot1 = (date) => {
        let req = { date: Math.floor(date / 1000) };

        apiService.getAuctionSlot(req, token).then(res => {
            // console.log("bookedSlots_________________sssssssssss", res?.data?.response_data.data["2"])
            const specificTime = "23:58:40";

            // Adjust the array
            let dateFirst = moment(parseInt(date)).format("YYYY-MM-DD")
            const dateSecond = moment(parseInt(date)).add(1, 'days').format("YYYY-MM-DD");

            const firstDayArray = adjustLastEndTime(res?.data?.response_data.data["1"], dateFirst);
            const secondDayArray = adjustLastEndTime(res?.data?.response_data.data["2"], dateSecond);
            // console.log("updatedData111111111111",firstDayArray, secondDayArray, dateFirst, dateSecond, date);


            if (res?.data?.status_code == 1 && Array.isArray(res?.data?.response_data.data["1"])) {
                const bookedSlots = [];
                // console.log("bookedSlots_________________", res?.data?.response_data.data)
                firstDayArray.forEach((item) => {
                    const startTime = new Date(item.start_time * 1000);
                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    // let currentSlot = new Date(startTime);
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));


                    while (currentSlot < endTime) {
                        bookedSlots.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });




                // console.log("res?.data?.response_data.data",res?.data?.response_data.data["1"])
                // console.log("bookedSlots____33_____________1", bookedSlots)
                // Set the booked slots in state for later comparison
                setBookedStartTimeSlot(bookedSlots);

                const bookedSlots1 = [];
                firstDayArray.forEach((item) => {
                    // const startTime = new Date(item.start_time * 1000);

                    const newStartTime = new Date(item.start_time * 1000);
                    const startTime = new Date(newStartTime); // Create a copy of the original date
                    startTime.setMinutes(startTime.getMinutes() + 30);


                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));


                    while (currentSlot <= endTime) {
                        bookedSlots1.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                // console.log("bookedSlots_________________1", bookedSlots1)
                // Set the booked slots in state for later comparison
                setBookedEndTimeSlot(bookedSlots1);

            } else {
            }

            if (res?.data?.status_code == 1 && Array.isArray(res?.data?.response_data.data["2"])) {
                // console.log("bookedSlots_________________sssssssssss", res?.data?.response_data.data["2"])
                const bookedSSecondlots = [];
                // console.log("bookedSlots_________________", res?.data?.response_data.data)
                secondDayArray.forEach((item) => {
                    const startTime = new Date(item.start_time * 1000);
                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));


                    while (currentSlot <= endTime) {
                        bookedSSecondlots.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                // console.log("bookedSlots_________________1bookedSSecondlots", bookedSSecondlots)
                // Set the booked slots in state for later comparison
                setBookedSecondStartTimeSlot(bookedSSecondlots);



                const bookedSSecondlots1 = [];
                // console.log("bookedSlots_________________", res?.data?.response_data.data)
                secondDayArray.forEach((item) => {
                    const newStartTime = new Date(item.start_time * 1000);
                    const startTime = new Date(newStartTime); // Create a copy of the original date
                    startTime.setMinutes(startTime.getMinutes() + 30);

                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));


                    while (currentSlot <= endTime) {
                        bookedSSecondlots1.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                // console.log("bookedSlots_________________1bookedSSecondlots", bookedSSecondlots1)
                // Set the booked slots in state for later comparison

                setBookedSecondEndTimeSlot(bookedSSecondlots1);
            } else {
            }

        });
    };

    const onChangeAuctionEndDate = (e) => {
        // debugger;
        setError({ datepic: '' })
        setEndDate(e)
        let today = moment(auctionDateString).format("ddd MMM DD YYYY");
        let selectedDate = moment(e).format("ddd MMM DD YYYY");
        setSelectedEndDate(null)
        if (today == selectedDate) {
            setisTodayDate(true)
        } else {
            setisTodayDate(false)
        }
    };

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
        if ((e.target.value == undefined) || (e.target.value.length == 0)) {
            setError({ description: 'Description is required' });
        } else if ((e.target.value.length > 0) && (e.target.value.length <= 1)) {
            setError({ description: 'Description must be between 2 and 90 characters' });
        } else {
            setError({ ...error, [e.target.name]: '', });
        }
    };

    const onChangeCategory = (e) => {
        setCategory(e.target.value);
    };

    function addDateTime(firstTimestamp, secondTimestamp) {
        const datePart = moment(parseInt(firstTimestamp)).format("YYYY-MM-DD");
        const timePart = moment(secondTimestamp * 1000).format("HH:mm:ss");
        const combinedTimestamp = moment(`${datePart}T${timePart}`).valueOf();
        return combinedTimestamp;
    }
    const handleValidate = () => {
        // debugger
        setBtnLoader(false);
        let validate = true;
        setError({});
        if ((description == undefined) || (description.length == 0)) {
            setError({ description: 'Description  is required' });
            validate = false
        }
        if ((auctionDateString == undefined) || (auctionDateString.length == 0)) {
            setError({ datepic: 'Date is required' });
            validate = false
        }
        if ((startTime == undefined) || (startTime.length == 0)) {
            setError({ startTime: 'Start Time is required' });
            validate = false
        }
        if ((endTime == undefined) || (endTime.length == 0)) {
            setError({ endTime: 'End Date is required' });
            validate = false
        }

        if ((selectedEndDate == undefined) || (selectedEndDate.length == 0)) {
            setError({ selectedEndDate: 'End Time is required' });
            validate = false
        }
        return validate;
    };

    const addAuction = async () => {
        // debugger

        if (handleValidate()) {
            // console.log("ddddddddddddddddddddddffffffffffffff",isTodayDate, selectedIsFirst)
            let endDateTime;
            let startTimeT; let endTimeT;
            if (!selectedIsFirst) {
                if (!isTodayDate) {
                    endDateTime = moment(auctionDateString).add(1, 'day').format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
                    endDateTime = moment(endDateTime).format("x")
                } else {
                    endDateTime = auctionDate;
                }

                startTimeT = addDateTime(auctionDate, startTime);
                endTimeT = addDateTime(endDateTime, endTime);
            } else {
                startTimeT = conststartTime * 1000
                endTimeT = constendTime * 1000
            }


            let formData = new FormData();

            formData.append("auction_id", props.id);
            formData.append("description", description);
            formData.append("category", category);
            formData.append("date", parseInt(auctionDate / 1000));
            formData.append("start_time", startTimeT / 1000);
            formData.append("end_time", endTimeT / 1000);
            formData.append("image", userInput.image);
            formData.append("image_type", userInput.image_type);
            apiService.editAuction(formData, token).then(res => {
                if (res?.data?.status_code == 1) {
                    props.onlyClose(false)
                    navigate("/auction")
                } else {
                    if (res?.data?.status_code != 1) {
                        notify("err", res?.data?.error?.message?.error);
                        if (res?.data?.status_code == 2)
                            logout();
                        if (res?.data?.status_code == 5)
                            logout();
                        if (res?.data?.status_code == 4)
                            logout();
                    }
                }
            })
        }
    }

    const cropInitialState = { aspect: 16 / 15, width: 100 }

    const [crop, setCrop] = useState({ aspect: 16 / 15, width: 100 });
    // const cropInitialState = {
    //     unit: '%', // Can be 'px' or '%'
    //     width: 100,
    //     aspect: 9 / 16, 
    // }

    // const [crop, setCrop] = useState(cropInitialState);

    const removeItem = () => {
        setNewUrl('');
        setUserInput({ image: '', image_type: '' });
        setSelectedLogoImageType('');
        setImageId('');
        setSelectedLogoImage('');
    }

    const onSelectFile = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const checkSize = await checkFileSize(e);
            if (!checkSize) {
                setCImage(e.target.files);
                const reader = new FileReader();
                reader.addEventListener("load", () => setUpImg(reader.result));
                reader.readAsDataURL(e.target.files[0]);
                setUserInput({
                    documentErr: '',
                });
            } else {
                setCImage('');
                setNewUrl('');
                setUserInput({ documentErr: "Image size must be less than 2 MB" });
            }
        }
    };

    const completedCrop = crop => {
        getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
            setNewUrl(res.url);
            setUserInput({ image: res.url, image_type: 3 })
        })
    }

    const handleFileUpload = () => {
        if (upImg) {
            setUpImg('')
            setCrop(cropInitialState)
            image.push({ "url": newUrl })
            getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
                document.push(res);
                addAuctionImage();
            })
        }
    }

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    const addAuctionImage = () => {
        try {
            setisImageLoader(true);
            let formData = new FormData();
            document.forEach(element => {
                formData.append("image", element);
            });
            apiService.addAuctionImage(formData, token).then(result => {
                if (result.data?.status_code == 1) {
                    setisImageLoader(false);
                    setUserInput({ ...userInput, image: result.data?.response_data?.data.image });
                } else {
                }
            })
        } catch (e) {
            console.error('e', e);
        }
    }

    useEffect(() => {
        if (props?.id)
            getAuction();
    }, [props?.id])


    const getAuction = async () => {
        setUserInput((prev) => ({ ...prev, isLoader: true }));
        // console.log("bookedStartTimeSlot______________22222222222222222222222222")
        try {
            if (props.id) {
                const auction_id = props.id;

                const res = await apiService.getAuction(auction_id, token);
                //   console.log("res________________________",true)
                if (res?.data?.status_code === 1) {
                    const auctionDetails = res.data?.response_data?.auction_details;
                    const { description, start_time, end_time, category, date, image, image_type } = auctionDetails;
                    setUserInput({
                        data: auctionDetails,
                        ...auctionDetails
                    });
                    setIsAuctionDate(date)
                    setIsAuctionApiHit(true)
                } else {
                    setUserInput((prev) => ({ ...prev, isLoader: false }));
                }
            }
        } catch (error) {
            // console.error("Error fetching auction details:", error);
            setUserInput((prev) => ({ ...prev, isLoader: false }));
        } finally {
            setSlotLoading(false);
        }
    };


    useEffect(() => {
        // console.log("rrrrrrrrrrrrrrrrrrrrrrrr",userInput.data,true)
        if ((Object.keys(userInput.data || {}).length !== 0) && isAuctionApiHit)
            getAuctionSlot12();
    }, [isAuctionApiHit])

    const getAuction1 = async () => {
        setUserInput((prev) => ({ ...prev, isLoader: true }));

        try {
            if (props.id) {
                const auction_id = props.id;

                const res = await apiService.getAuction(auction_id, token);
                // console.log("res________________________",true)
                if (res?.data?.status_code === 1) {
                    const auctionDetails = res.data?.response_data?.auction_details;
                    const { description, start_time, end_time, category, date, image } = auctionDetails;

                    // Ensure getAuctionSlot is async
                    await getAuctionSlot(date * 1000, start_time, end_time);

                    const dateString = date
                        ? moment(date * 1000).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]")
                        : "";

                    setUserInput((prev) => ({
                        ...prev,
                        image_type: auctionDetails?.image_type,
                        data: auctionDetails,
                        totalCount: res?.data.response_data.count,
                        isLoader: false,
                        description,
                        start_time,
                        end_time,
                        date,
                        image,
                    }));
                    setAuctionDateString(dateString);
                    setAuctionDate(date * 1000);
                    setDescription(description);
                    setCategory(category.id);

                    // Ensure onUseEffectAuctionDate is async
                    await onUseEffectAuctionDate(date, start_time, end_time);
                } else {
                    setUserInput((prev) => ({ ...prev, isLoader: false }));
                }
            }
        } catch (error) {
            console.error("Error fetching auction details:", error);
            setUserInput((prev) => ({ ...prev, isLoader: false }));
        } finally {
            setSlotLoading(false);
        }
    };
    const getAuctionSlot12 = () => {
        // console.log("userInput?.dateuserInput?.date",userInput?.data)
        if (userInput?.data) {
            console.log("bookedStartTimeSlot______________33333333333333333333333333333", userInput?.data)
            setIsAuctionApiHit(false)
            let req = { date: Math.floor(userInput?.data?.date) };
            apiService.getAuctionSlot(req, token).then(res => {
            
                // console.log("resresresresresresres",res?.data?.response_data,res?.data?.response_data.data["1"], res?.data?.response_data.data["2"])
                const { data } = res?.data?.response_data
                const dataA = data[1];
                const dataB = data[2];
                console.log("resresresresresresres", data[1], data[2], dataA, dataB)
                const fistDateArray = removeFirstDayArray(dataA, userInput?.data?.start_time, userInput?.data?.end_time);
                const secondDateArray = removeFirstDayArray(dataB, userInput?.data?.start_time, userInput?.data?.end_time);
                // Adjust the array

                // console.log("dateNewdateNewdateNewdateNewdateNew",fistDateArray,res?.data?.response_data?.data["1"],userInput?.data?.start_time,userInput?.data?.end_time)
                const dateFirst = moment(userInput?.data?.date * 1000).format("YYYY-MM-DD")
                const dateSecond = moment(userInput?.data.date * 1000).add(1, 'days').format("YYYY-MM-DD");

                const firstDayArray = adjustLastEndTime(fistDateArray, dateFirst);
                const secondDayArray = adjustLastEndTime(secondDateArray, dateSecond);

                if (res?.data?.status_code == 1 && Array.isArray(dataA)) {
                    const bookedSlots = [];
                    firstDayArray.forEach((item) => {
                        const startTime = new Date(item.start_time * 1000);
                        const endTime = new Date(item.end_time * 1000);

                        // Generate time slots in 30-minute intervals from start to end time
                        let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));

                        while (currentSlot < endTime) {
                            bookedSlots.push(new Date(currentSlot)); // Add to booked slots array
                            currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                        }
                    });

                    // Set the booked slots in state for later comparison
                    setBookedStartTimeSlot(bookedSlots);

                    const bookedSlots1 = [];
                    firstDayArray.forEach((item) => {
                        // const startTime = new Date(item.start_time * 1000);

                        const newStartTime = new Date(item.start_time * 1000);
                        const startTime = new Date(newStartTime); // Create a copy of the original date
                        startTime.setMinutes(startTime.getMinutes() + 30);
                        const endTime = new Date(item.end_time * 1000);
                        // Generate time slots in 30-minute intervals from start to end time
                        let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));

                        while (currentSlot <= endTime) {
                            bookedSlots1.push(new Date(currentSlot)); // Add to booked slots array
                            currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                        }
                    });

                    // Set the booked slots in state for later comparison
                    setBookedEndTimeSlot(bookedSlots1);

                }
                if (res?.data?.status_code == 1 && Array.isArray(dataB)) {
                    const bookedSSecondlots = [];
                    secondDayArray.forEach((item) => {
                        const startTime = new Date(item.start_time * 1000);
                        const endTime = new Date(item.end_time * 1000);

                        // Generate time slots in 30-minute intervals from start to end time
                        let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));

                        while (currentSlot <= endTime) {
                            bookedSSecondlots.push(new Date(currentSlot)); // Add to booked slots array
                            currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                        }
                    });

                    // Set the booked slots in state for later comparison
                    setBookedSecondStartTimeSlot(bookedSSecondlots);
                    const bookedSSecondlots1 = [];
                    secondDayArray.forEach((item) => {
                        const newStartTime = new Date(item.start_time * 1000);
                        const startTime = new Date(newStartTime); // Create a copy of the original date
                        startTime.setMinutes(startTime.getMinutes() + 30);

                        const endTime = new Date(item.end_time * 1000);

                        // Generate time slots in 30-minute intervals from start to end time
                        let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));
                        while (currentSlot <= endTime) {
                            bookedSSecondlots1.push(new Date(currentSlot)); // Add to booked slots array
                            currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                        }
                    });
                    // Set the booked slots in state for later comparison

                    setBookedSecondEndTimeSlot(bookedSSecondlots1);
                } 

                let startDate = userInput?.data?.date ? moment(userInput?.data?.date * 1000).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]") : "";
                // let startDate1 = userInput?.data?.date ? moment(startDate).format("x") : "";
                // console.log("startDatestartDatestartDate",startDate1)
                let finalDate = new Date(startDate)
                setAuctionDateString(finalDate);
                setTimeStartTime(finalDate)
                setAuctionDate(finalDate)
                console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeaaaaaaaaaaaaaaaaaaaa", userInput?.data?.description)
                setIsSlotsApiHit(true)
                setUserInput({
                    description: userInput?.data?.description,
                    image: userInput?.data?.image
                });
                
            
               

            });
        }

    };
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeecccccccccccccccccc",false,isSlotsApiHit,selectedIsFirst)

    useEffect(() => {
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeebbbbbbbbbbbbbbbbbbbbbbbbbbb",isSlotsApiHit,selectedIsFirst)

        if (isSlotsApiHit && selectedIsFirst) {
            // console.log("bookedStartTimeSlot______________111111111",true)
            setIsSlotsApiHit(false)
            const dateString = userInput?.data?.date
                ? moment(userInput?.data?.date * 1000).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]")
                : "";

            setAuctionDateString(dateString);
            setAuctionDate(userInput?.data?.date * 1000);
            setDescription(userInput?.data?.description);
            setCategory(userInput?.data?.category.id);
            // console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrreee",userInput?.data?.date, userInput?.data?.start_time, userInput?.data?.end_time)

            let date = userInput?.data?.date;
            let start_time = userInput?.data?.start_time;
            let end_time = userInput?.data?.end_time;

            setconstStartTime(start_time)
            setconstEndTime(end_time)

            let actualDateStringTime = new Date(combinedActualTime(start_time * 1000));
            // Ensure start_time and end_time are converted to valid Date objects
            const startTimeDate = new Date(start_time * 1000); // Convert seconds to milliseconds
            const endTimeDate = new Date(end_time * 1000); // Convert seconds to milliseconds
            // console.log("startTimeDate___________",startTimeDate)
            // Set the state with Date objects (required for DatePicker)
            setSelectedStartDate(startTimeDate);
            setStartTime(start_time);

            setSelectedEndDate(endTimeDate);
            setTimeStartTime(startTimeDate)


            let startDateInitial = date ? date * 1000 : "";
            let startDateX = + new Date();
            let startDatem = date ? moment(date * 1000).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)") : "";
            if (startDateX <= startDateInitial) {
                setTimeStartTime(startDatem)
            } else {
                setTimeStartTime(new Date());
            }

            setSelectedEndDateConst(endTimeDate)


            let dateNew = moment(actualDateStringTime)
                .add(120, 'minutes')
                .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");

            dateNew = new Date(dateNew);
            setSelectedEndDateConst(dateNew)


            setEndDate(endTimeDate);

            setEndTime(end_time)

            setSlotLoading(false); // Stop loading
            let startDate = date ? date : "";
            setAuctionDate(startDate * 1000)

            setSlotLoading(true);


            // console.log("dateStringdateStringdateString",dateString)
            const slotsDate = moment(dateString).format("YYYY-MM-DD");

            const normalizedBookedStartTimeSlot = bookedEndTimeSlot.map((slot) =>
                new Date(slot).toISOString()
            );

            const slots = generateSlots(slotsDate, 22, 23, 30);
            // console.log("dateStringdateStringdateString", dateString, slots)
            const matches = slots.filter((date1) => normalizedBookedStartTimeSlot.includes(date1));

            // console.log("matches_________________",slotsDate, matches,slots, normalizedBookedStartTimeSlot)
            let startDateTime = moment(actualDateStringTime)
                .add(30, 'minutes');
            const endDateTime = moment(actualDateStringTime)
                .add(2, 'hour');


            const intervalMinutes = 30; // Interval in minutes

            const slotsAfterTwoHour = generateSlotsWithTime(startDateTime, endDateTime, intervalMinutes);
            const matchesAfter = slotsAfterTwoHour.filter((date1) => normalizedBookedStartTimeSlot.includes(date1));
            const selectedTime = moment(start_time * 1000).format("h:mm A");
            // Check if the selected start time is 11:30 PM
            if (selectedTime === "11:30 PM") {
                setIsStartTime1130(true);
            } else {
                setIsStartTime1130(false);
            }

            const startTimeDateNew = moment(startTimeDate)
                .add(2, 'hour');

            let endDateTimeNew = new Date(startTimeDateNew);
            setMaxTime(endDateTimeNew)
            const thresholdTime = "10:00 PM";

            // console.log("___________________________________________10000000000000010000000000000", moment(selectedTime, "hh:mm A"), matches, moment(thresholdTime, "hh:mm A"))
            if (moment(selectedTime, "hh:mm A").isSameOrAfter(moment(thresholdTime, "hh:mm A"))) {
                if ((selectedTime !== "11:30 PM") && (matches.length > 0)) {
                    setIsStartTime10(false);
                    setisTodayDate(true)
                    // console.log("___________________________________________1000000000000002222222222222222", bookedEndTimeSlot)
                } else {
                    // console.log("___________________________________________100000000000000111111111111z",selectedTime,matches, bookedEndTimeSlot)
                    setIsStartTime10(true);
                    let endDate = moment(auctionDateString).add(1, 'day').format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
                    
                    let startDateTime1 = moment(endDate)
                        .add(30, 'minutes');
                    const endDateTime1 = moment(endDate)
                        .add(2, 'hour');

                    const intervalMinutes = 30; // Interval in minutes

                    const slotsAfterTwoHour = generateSlotsWithTime(startDateTime1, endDateTime1, intervalMinutes);
                    const normalizedBookedStartTimeSlot1 = bookedSecondEndTimeSlot.map((slot) =>
                        new Date(slot).toISOString()
                    );
                    const matchesAfter1 = slotsAfterTwoHour.filter((date1) => normalizedBookedStartTimeSlot1.includes(date1));



                    let firstDate = moment(auctionDateString, "ddd MMM DD YYYY");
                    firstDate = new Date(firstDate)
                    let secondDate = moment(endDateTime, "ddd MMM DD YYYY");
                    secondDate = new Date(secondDate)
                    // console.log("handleEndTimeChange------------------",isSameDate(firstDate, secondDate), firstDate,secondDate)
            
                    if (isSameDate(firstDate, secondDate)) {
                        setisTodayDate(true)
                    }else{
                        setisTodayDate(false)
                    }

                }

            } else {
                if (matchesAfter.length > 0) {
                    // console.log("___________________________________________10000000000000044444444444444444", bookedStartTimeSlot)
                    setIsStartTime10(false);
                    setisTodayDate(true)
                } else {
                    // console.log("___________________________________________10000000000000055555555555555")
                    setIsStartTime10(false);
                    setisTodayDate(true)
                }
            }

        }
    }, [isSlotsApiHit])

    const handleStartTimeChange = (time) => {
        setselectedIsFirst(false)
        const selectedTime = moment(time).format("h:mm A");
        let actualDateStringTime = new Date(combinedActualTime(time));
        setSelectedStartDate(actualDateStringTime);
        let startTime = moment(time).format("x");
        setStartTime(parseInt(startTime / 1000))
        const slotsDate = moment(auctionDateString).format("YYYY-MM-DD");
        const normalizedBookedStartTimeSlot = bookedEndTimeSlot.map((slot) =>
            new Date(slot).toISOString()
        );

        const slots = generateSlots(slotsDate, 22, 23, 30);
        const matches = slots.filter((date1) => normalizedBookedStartTimeSlot.includes(date1));
        let startDateTime = moment(actualDateStringTime)
            .add(30, 'minutes');
        const endDateTime = moment(actualDateStringTime)
            .add(2, 'hour');

        const intervalMinutes = 30; // Interval in minutes
        const slotsAfterTwoHour = generateSlotsWithTime(startDateTime, endDateTime, intervalMinutes);
        const matchesAfter = slotsAfterTwoHour.filter((date1) => normalizedBookedStartTimeSlot.includes(date1));
        let date = moment(actualDateStringTime)
            .add(120, 'minutes')
            .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");

        date = new Date(date);
        setSelectedEndDate(date)
        setSelectedEndDateConst(date)
        setEndTime(parseInt(moment(date).format("x")) / 1000);

        // Check if the selected start time is 11:30 PM
        if (selectedTime === "11:30 PM") {
            setIsStartTime1130(true);
        } else {
            setIsStartTime1130(false);
        }

        const thresholdTime = "10:00 PM";

        if (moment(selectedTime, "hh:mm A").isSameOrAfter(moment(thresholdTime, "hh:mm A"))) {
            if ((selectedTime !== "11:30 PM") && matches.length > 0) {
                // console.log("startmain________________________1111111111", matches, selectedTime)
                setEndDate(auctionDateString)
                setIsStartTime10(false);
                setSelectedEndDate(null)
                setisTodayDate(true)
            } else {
                // console.log("startmain________________________222222222")
                setIsStartTime10(true);
                let endDate = moment(auctionDateString).add(1, 'day').format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
                setEndDate(endDate)
                setisTodayDate(false)

                let startDateTime1 = moment(endDate)
                    .add(30, 'minutes');
                const endDateTime1 = moment(endDate)
                    .add(2, 'hour');

                const intervalMinutes = 30; // Interval in minutes
                const slotsAfterTwoHour = generateSlotsWithTime(startDateTime1, endDateTime1, intervalMinutes);
                const normalizedBookedStartTimeSlot1 = bookedSecondEndTimeSlot.map((slot) =>
                    new Date(slot).toISOString()
                );

                const matchesAfter1 = slotsAfterTwoHour.filter((date1) => normalizedBookedStartTimeSlot1.includes(date1));
                if (matchesAfter1.length > 0)
                    setSelectedEndDate(null)
            }

        } else {
            if (matchesAfter.length > 0) {
                // console.log("startmain________________________333333333")
                setEndDate(auctionDateString)
                setSelectedEndDate(null)
                setIsStartTime10(false);
                setisTodayDate(true)
            } else {
                // console.log("startmain________________________4444444444")
                setEndDate(auctionDateString)
                setIsStartTime10(false);
                setisTodayDate(true)
            }
        }

    };

    const onUseEffectAuctionDate = (date, start_time, end_time) => {
        setconstStartTime(start_time)
        setconstEndTime(end_time)

        let actualDateStringTime = new Date(combinedActualTime(start_time * 1000));
        // Ensure start_time and end_time are converted to valid Date objects
        const startTimeDate = new Date(start_time * 1000); // Convert seconds to milliseconds
        const endTimeDate = new Date(end_time * 1000); // Convert seconds to milliseconds

        // Set the state with Date objects (required for DatePicker)
        setSelectedStartDate(startTimeDate);
        setStartTime(start_time);

        setSelectedEndDate(endTimeDate);
        setTimeStartTime(startTimeDate)


        let startDateInitial = date ? date * 1000 : "";
        let startDateX = + new Date();
        let startDatem = date ? moment(date * 1000).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)") : "";
        if (startDateX <= startDateInitial) {
            setTimeStartTime(startDatem)
        } else {
            setTimeStartTime(new Date());
        }

        setSelectedEndDateConst(endTimeDate)


        setEndDate(endTimeDate);

        setEndTime(end_time)

        setSlotLoading(false); // Stop loading
        let startDate = date ? date : "";
        setAuctionDate(startDate * 1000)

        setSlotLoading(true);


        const slotsDate = moment(auctionDateString).format("YYYY-MM-DD");
        const normalizedBookedStartTimeSlot = bookedEndTimeSlot.map((slot) =>
            new Date(slot).toISOString()
        );

        const slots = generateSlots(slotsDate, 22, 23, 30);
        const matches = slots.filter((date1) => normalizedBookedStartTimeSlot.includes(date1));

        let startDateTime = moment(actualDateStringTime)
            .add(30, 'minutes');
        const endDateTime = moment(actualDateStringTime)
            .add(2, 'hour');


        const intervalMinutes = 30; // Interval in minutes

        const slotsAfterTwoHour = generateSlotsWithTime(startDateTime, endDateTime, intervalMinutes);
        const matchesAfter = slotsAfterTwoHour.filter((date1) => normalizedBookedStartTimeSlot.includes(date1));
        const selectedTime = moment(start_time * 1000).format("h:mm A");
        // Check if the selected start time is 11:30 PM
        if (selectedTime === "11:30 PM") {
            setIsStartTime1130(true);
        } else {
            setIsStartTime1130(false);
        }

        const startTimeDateNew = moment(startTimeDate)
            .add(2, 'hour');

        let endDateTimeNew = new Date(startTimeDateNew);
        setMaxTime(endDateTimeNew)
        const thresholdTime = "10:00 PM";

        if (moment(selectedTime, "hh:mm A").isSameOrAfter(moment(thresholdTime, "hh:mm A"))) {
            if ((selectedTime !== "11:30 PM") && (matches.length > 0)) {
                setIsStartTime10(false);
                setisTodayDate(true)
                // console.log("___________________________________________1000000000000002222222222222222", bookedEndTimeSlot)
            } else {
                // console.log("___________________________________________100000000000000111111111111", selectedTime, matches, bookedEndTimeSlot)
                setIsStartTime10(true);
                let endDate = moment(auctionDateString).add(1, 'day').format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
                setisTodayDate(false)
                let startDateTime1 = moment(endDate)
                    .add(30, 'minutes');
                const endDateTime1 = moment(endDate)
                    .add(2, 'hour');

                const intervalMinutes = 30; // Interval in minutes

                const slotsAfterTwoHour = generateSlotsWithTime(startDateTime1, endDateTime1, intervalMinutes);
                const normalizedBookedStartTimeSlot1 = bookedSecondEndTimeSlot.map((slot) =>
                    new Date(slot).toISOString()
                );
                const matchesAfter1 = slotsAfterTwoHour.filter((date1) => normalizedBookedStartTimeSlot1.includes(date1));

            }

        } else {
            if (matchesAfter.length > 0) {
                // console.log("___________________________________________10000000000000044444444444444444")
                setIsStartTime10(false);
                setisTodayDate(true)
            } else {
                // console.log("___________________________________________10000000000000055555555555555")
                setIsStartTime10(false);
                setisTodayDate(true)
            }
        }

    };



    const today = new Date().toISOString().split('T')[0];
    const [isStartTime1130, setIsStartTime1130] = useState(false);


    const findMaxTime = (array, key) => {
        if (array.length) {
            const timestamps = array.map(dateTime => moment(dateTime).unix());
            let keyDateTime = moment(key).unix()
            const validTimestamps = timestamps.filter(timestamp => timestamp >= keyDateTime);
            const maxTimestamp = validTimestamps.length > 0 ? Math.min(...validTimestamps) : null;
            return maxTimestamp;
        } else {
            return null
        }

    };

    const generateSlots = (date, startHour, endHour, intervalMinutes) => {
        const slots = [];
        const startTime = moment(date).set({ hour: startHour, minute: 0, second: 0, millisecond: 0 });
        const endTime = moment(date).set({ hour: endHour, minute: 30, second: 0, millisecond: 0 });

        let current = startTime;

        while (current.isSameOrBefore(endTime)) {
            // slots.push(new Date(current.toISOString()));
            slots.push(current.toISOString()); // Store as ISO string for consistency



            current = current.add(intervalMinutes, "minutes");
        }

        return slots;
    };


    const combinedActualTime = (time) => {
        // console.log("auctionDateString____________", auctionDateString)
        let date = moment(auctionDateString).format("YYYY-MM-DD")
        const timeWithTimezone = moment(time).format("HH:mm:ss [GMT]ZZ [(India Standard Time)]"); // HH:mm:ss with timezone
        // const selectedTime = moment(time).format("h:mm A");
        const combinedDateTime = moment(`${date} ${timeWithTimezone}`, "YYYY-MM-DD HH:mm:ss");

        const formattedCombinedDateTime = combinedDateTime.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
        return formattedCombinedDateTime;
    }

    const generateSlotsWithTime = (startDateTime, endDateTime, intervalMinutes) => {
        const slots = [];

        // Ensure start and end times are moment objects
        const startTime = moment(startDateTime);
        const endTime = moment(endDateTime);

        // Check if end time is valid and after start time
        if (!endTime.isValid() || !startTime.isValid() || endTime.isBefore(startTime)) {
            return slots;
        }

        // Loop through and create slots
        let current = startTime.clone();

        while (current.isSameOrBefore(endTime)) {
            slots.push(current.toISOString());
            current = current.add(intervalMinutes, "minutes"); // Increment by interval
        }

        return slots;
    };

    const isSameDate = (date1, date2) => {
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    };
    

    const handleEndTimeChange = (time) => {
        setselectedIsFirst(false)
        setError({ endTime: '' });

        let date = moment(endDate).format("YYYY-MM-DD")
        const timeWithTimezone = moment(time).format("HH:mm:ss [GMT]ZZ [(India Standard Time)]"); // HH:mm:ss with timezone
        // const selectedTime = moment(time).format("h:mm A");
        let combinedDateTime = moment(`${date} ${timeWithTimezone}`, "YYYY-MM-DD HH:mm:ss");
        combinedDateTime = new Date(combinedDateTime);







        setSelectedEndDate(combinedDateTime);
        let endTime = moment(combinedDateTime).format("x");
        setEndTime(parseInt(endTime / 1000))


        // let today = moment(auctionDateString).format("ddd MMM DD YYYY");
        // let selectedDate = moment(e).format("ddd MMM DD YYYY");
        // setSelectedEndDate(null)
        // if (today == selectedDate) {
        //     setisTodayDate(true)
        // } else {
        //     setisTodayDate(false)
        // }



        // console.log("handleEndTimeChange",time, endDate, combinedDateTime)

        let firstDate = moment(auctionDateString, "ddd MMM DD YYYY");
        firstDate = new Date(firstDate)
        let secondDate = moment(time, "ddd MMM DD YYYY");
        secondDate = new Date(secondDate)
        // console.log("handleEndTimeChange------------------",isSameDate(auctionDateString, endDate), endDate)

        if (isSameDate(auctionDateString, combinedDateTime)) {
            // console.log("handleEndTimeChange_______________________",true)
            setisTodayDate(true)
        } else {
            // console.log("handleEndTimeChange_______________________",false)
            setisTodayDate(false)
        }
    };

    const checkFormCompletion = () => {
        if (startTime && description && (category >= 0) && auctionDateString && endTime) {
            setisAllAdded(true);
        } else {
            setisAllAdded(false);
        }
    };

    useEffect(() => {
        checkFormCompletion()
    }, [startTime, description, category, auctionDateString, endTime])

    const selectLogoImage = (url, name) => {
        setUserInput({ image: url, image_type: name });
    };

    function epochToGMT(epoch) {
        const date = new Date(epoch * 1000); // Convert seconds to milliseconds
        return date.toGMTString(); // Get the GMT string representation
    }


    function adjustLastEndTime(array, baseDate) {
        if (array.length === 0) return array; // Handle empty array

        const baseDateObject = new Date(baseDate); // Parse provided date

        return array.map((obj) => {
            const startDate = new Date(obj.start_time * 1000); // Convert start_time to Date object
            const endDate = new Date(obj.end_time * 1000); // Convert end_time to Date object
            if (startDate.toDateString() !== endDate.toDateString()) {
                if (startDate.toDateString() === baseDateObject.toDateString()) {
                    // If start_time matches the provided date, adjust end_time to baseDate + 23:59:59
                    const adjustedEndTime = new Date(
                        baseDateObject.getFullYear(),
                        baseDateObject.getMonth(),
                        baseDateObject.getDate(),
                        23,
                        59,
                        59
                    );
                    obj.end_time = Math.floor(adjustedEndTime.getTime() / 1000); // Update end_time in Unix timestamp format
                } else {
                    // If start_time does not match the provided date, adjust start_time to baseDate + 00:00:00
                    const adjustedStartTime = new Date(
                        baseDateObject.getFullYear(),
                        baseDateObject.getMonth(),
                        baseDateObject.getDate(),
                        0,
                        0,
                        0
                    );
                    obj.start_time = Math.floor(adjustedStartTime.getTime() / 1000); // Update start_time in Unix timestamp format
                }
            }

            return obj; // Return the modified object
        });
    }


    // function removeFirstDayArray(array, startTime, endTime) {
    //     console.log("ddddddddddddddddddddddddddddddddddd", array, startTime, endTime)
    //     if (!Array.isArray(array)) {
    //         return [];
    //     }

    //     // Filter the array to exclude objects with matching start_time and end_time
    //     return array.filter(item => {
    //         return !(item.start_time === startTime && item.end_time === endTime);
    //     });
    // }


    function removeFirstDayArray(array, startTime, endTime) {
        if (!Array.isArray(array)) {
            // console.error("Invalid array passed to removeFirstDayArray:", array);
            return [];
        }

        return array.filter((item) => {
            return !(item.start_time === startTime && item.end_time === endTime);
        });
    }

    const getAuctionSlot = (date, start_time, end_time) => {
        let req = { date: Math.floor(date / 1000) };
        apiService.getAuctionSlot(req, token).then(res => {
            let fistDateArray = removeFirstDayArray(res?.data?.response_data.data["1"], start_time, end_time);
            let secondDateArray = removeFirstDayArray(res?.data?.response_data.data["2"], start_time, end_time);
            // Adjust the array
            let dateFirst = moment(parseInt(date)).format("YYYY-MM-DD")
            const dateSecond = moment(parseInt(date)).add(1, 'days').format("YYYY-MM-DD");

            const firstDayArray = adjustLastEndTime(fistDateArray, dateFirst);
            const secondDayArray = adjustLastEndTime(secondDateArray, dateSecond);

            if (res?.data?.status_code == 1 && Array.isArray(res?.data?.response_data.data["1"])) {
                const bookedSlots = [];
                firstDayArray.forEach((item) => {
                    const startTime = new Date(item.start_time * 1000);
                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));

                    while (currentSlot < endTime) {
                        bookedSlots.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                // Set the booked slots in state for later comparison
                setBookedStartTimeSlot(bookedSlots);

                const bookedSlots1 = [];
                firstDayArray.forEach((item) => {
                    // const startTime = new Date(item.start_time * 1000);

                    const newStartTime = new Date(item.start_time * 1000);
                    const startTime = new Date(newStartTime); // Create a copy of the original date
                    startTime.setMinutes(startTime.getMinutes() + 30);
                    const endTime = new Date(item.end_time * 1000);
                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));

                    while (currentSlot <= endTime) {
                        bookedSlots1.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                // Set the booked slots in state for later comparison
                setBookedEndTimeSlot(bookedSlots1);

            } else {
            }

            if (res?.data?.status_code == 1 && Array.isArray(res?.data?.response_data.data["2"])) {
                const bookedSSecondlots = [];
                secondDayArray.forEach((item) => {
                    const startTime = new Date(item.start_time * 1000);
                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));

                    while (currentSlot <= endTime) {
                        bookedSSecondlots.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });

                // Set the booked slots in state for later comparison
                setBookedSecondStartTimeSlot(bookedSSecondlots);
                const bookedSSecondlots1 = [];
                secondDayArray.forEach((item) => {
                    const newStartTime = new Date(item.start_time * 1000);
                    const startTime = new Date(newStartTime); // Create a copy of the original date
                    startTime.setMinutes(startTime.getMinutes() + 30);

                    const endTime = new Date(item.end_time * 1000);

                    // Generate time slots in 30-minute intervals from start to end time
                    let currentSlot = new Date(Math.ceil(startTime.getTime() / (30 * 60000)) * (30 * 60000));
                    while (currentSlot <= endTime) {
                        bookedSSecondlots1.push(new Date(currentSlot)); // Add to booked slots array
                        currentSlot = new Date(currentSlot.getTime() + 30 * 60000); // Move to the next 30-minute slot
                    }
                });
                // Set the booked slots in state for later comparison

                setBookedSecondEndTimeSlot(bookedSSecondlots1);
            } else {
            }

        });
    };


    const excludedTimeSlotsInMinutes = useMemo(() =>
        bookedStartTimeSlot.map(
            (slot) => slot.getHours() * 60 + slot.getMinutes()
        ),
        [bookedStartTimeSlot]
    );


    const filterStartTimeSlots = (time) => {
        // if (selectedStartDate) {
        // console.log("bookedStartTimeSlot2______________", bookedStartTimeSlot)
        const hours = time.getHours();
        const minutes = time.getMinutes();
        // Convert time to milliseconds for comparison
        const currentTime = hours * 60 + minutes;
        // Convert excluded time slots to milliseconds for comparison
        // console.log("bookedStartTimeSlotbookedStartTimeSlot",bookedStartTimeSlot,time)
        // const excludedTimeSlotsInMinutes = bookedStartTimeSlot.map(slot => slot.getHours() * 60 + slot.getMinutes());
        // Check if the current time slot is in the excluded list
        return !excludedTimeSlotsInMinutes.includes(currentTime);
        // }

    };

    const getMax = (time) => {
        let date = moment(auctionDateString).format("YYYY-MM-DD")
        const timeWithTimezone = moment(time).format("HH:mm:ss [GMT]ZZ [(India Standard Time)]"); // HH:mm:ss with timezone
        // const selectedTime = moment(time).format("h:mm A");
        const combinedDateTime = moment(`${date} ${timeWithTimezone}`, "YYYY-MM-DD HH:mm:ss");

        const formattedCombinedDateTime = combinedDateTime.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
        const maxTime = findMaxTime(bookedStartTimeSlot, formattedCombinedDateTime);
        let formattedDate = null;
        if (maxTime) {
            const adjustedTimestamp = maxTime;
            // const adjustedTimestamp = maxTime - 1800;
            // Convert the timestamp to a human-readable string
            formattedDate = moment.unix(adjustedTimestamp).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]");
        } else {
            formattedDate = new Date().setHours(23, 59);;
        }
        return formattedDate;
    }


    const getMaxEndTime = (startTime) => {
        return getMax(startTime)
    };


    const generateEndTimeOptions1 = (startTime) => {
        const endTimeOptions = [];
        const interval = 30; // 30-minute intervals

        // Define the end boundary for slot generation
        const endBoundary = isTodayDate
            ? moment(startTime).endOf("day") // For today, limit to the end of the day
            : moment(selectedEndDateConst).endOf("day"); // For other days, limit to the selectedEndDate

        const bookSlot = isTodayDate ? bookedEndTimeSlot : bookedSecondStartTimeSlot;
        let currentTime;
        if (isTodayDate) {
            currentTime = moment(startTime).startOf("day"); // Start generating time slots from the beginning of the day
        } else {
            currentTime = moment(selectedEndDateConst).startOf("day"); // Start of the next day
        }
        while (currentTime.isBefore(endBoundary)) {

            const isNextSlot = currentTime.isSame(moment(startTime).add(interval, "minutes"));
            const isDisabledByStartTime = !isNextSlot && currentTime.isSameOrBefore(moment(startTime));
            // Disable slots after the selectedEndDate
            const isDisabledByEndDate = currentTime.isAfter(moment(maxTime));
            // Disable slots based on booked slots
            const isDisabledByBookedSlot = bookSlot.some((slot) => {
                const bookedTime = moment(slot);
                const bookedEndTime = moment(bookedTime).add(interval, "minutes");
                return currentTime.isBetween(bookedTime, bookedEndTime, null, "[)");
            });
            let isDisabled;
            // Determine if the current time should be disabled
            if (isTodayDate)
                isDisabled = isDisabledByStartTime || isDisabledByEndDate || isDisabledByBookedSlot;
            else
                isDisabled = isDisabledByEndDate || isDisabledByBookedSlot;


            // Push the current time to the options
            endTimeOptions.push({
                time: currentTime.format("h:mm A"), // Format time for display
                disabled: isDisabled,
            });

            currentTime = currentTime.add(interval, "minutes");
        }

        let firstBreakingValue = null;

        for (let i = 0; i < endTimeOptions.length - 1; i++) {
            if (!endTimeOptions[i].disabled && endTimeOptions[i + 1].disabled) {
                firstBreakingValue = endTimeOptions[i + 1];
                break;
            }
        }
        const updatedSlots = disableSlotsAfter(endTimeOptions, firstBreakingValue?.time);
        return updatedSlots;
    };

    const generateEndTimeOptions = (startTime) => {
        const endTimeOptions = [];
        const interval = 30; // 30-minute intervals

        // Define the end boundary for slot generation
        const endBoundary = isTodayDate
            ? moment(startTime).endOf("day") // For today, limit to the end of the day
            : moment(selectedEndDateConst).endOf("day"); // For other days, limit to the selectedEndDate

        const bookSlot = isTodayDate ? bookedEndTimeSlot : bookedSecondStartTimeSlot;
        let currentTime;
        if (isTodayDate) {
            currentTime = moment(startTime).startOf("day"); // Start generating time slots from the beginning of the day
        } else {
            currentTime = moment(selectedEndDateConst).startOf("day"); // Start of the next day
        }

        // console.log("bookSlotbookSlot", isTodayDate, selectedEndDateConst, startTime, "currentTime", currentTime, isTodayDate)
        // console.log("issssssstoday",isTodayDate)
        // console.log("generateEndTimeOptionsbookSlotbookSlot", selectedEndDateConst, "---------", startTime, "currentTime", currentTime, isTodayDate, bookSlot, "dddd", bookedEndTimeSlot)
        while (currentTime.isBefore(endBoundary)) {
            // Disable slots below or equal to the selectedStartDate

            // const isDisabledByStartTime = currentTime.isSameOrBefore(moment(startTime));


            const isNextSlot = currentTime.isSame(moment(startTime).add(interval, "minutes"));
            const isDisabledByStartTime = !isNextSlot && currentTime.isSameOrBefore(moment(startTime));
            // Disable slots after the selectedEndDate
            const isDisabledByEndDate = currentTime.isAfter(moment(selectedEndDateConst));

            // Disable slots based on booked slots
            const isDisabledByBookedSlot = bookSlot.some((slot) => {
                const bookedTime = moment(slot);
                const bookedEndTime = moment(bookedTime).add(interval, "minutes");
                return currentTime.isBetween(bookedTime, bookedEndTime, null, "[)");
            });
            let isDisabled;
            // Determine if the current time should be disabled
            if (isTodayDate)
                isDisabled = isDisabledByStartTime || isDisabledByEndDate || isDisabledByBookedSlot;
            else
                isDisabled = isDisabledByEndDate || isDisabledByBookedSlot;


            // Push the current time to the options
            endTimeOptions.push({
                time: currentTime.format("h:mm A"), // Format time for display
                disabled: isDisabled,
            });

            // Increment the current time by the interval
            currentTime = currentTime.add(interval, "minutes");
        }

        // console.log("Generated End Time Options:", endTimeOptions);
        let firstBreakingValue = null;

        for (let i = 0; i < endTimeOptions.length - 1; i++) {
            if (!endTimeOptions[i].disabled && endTimeOptions[i + 1].disabled) {
                firstBreakingValue = endTimeOptions[i + 1];
                break;
            }
        }
        // console.log("ddddddddddddddddddeeeeeeeeeeeeeeeeeeee",true,firstBreakingValue?.time)

        const updatedSlots = disableSlotsAfter(endTimeOptions, firstBreakingValue?.time);
        // console.log("ddddddddddddddddddeeeeeeeeeeeeeeeeeeee",false,updatedSlots)

        return updatedSlots;
    };
    function disableSlotsAfter(slots, startTime) {
        let startDisabling = false; // Flag to track when to start disabling slots

        return slots.map(slot => {
            if (slot.time === startTime) {
                startDisabling = true; // Start disabling from this time onward
            }
            if (startDisabling) {
                slot.disabled = true;
            }
            return slot;
        });
    }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                className='cstm_modal_edit_product'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className='close_icon _add_product' onClick={props.onHide}><span><IoCloseCircle /></span></div>
                    {
                        upImg ? <>
                            <div className='crop_img ' style={{ top: '-289px', zIndex: '9' }}>
                                <div className="text-center new_crop_btn mb-3">
                                    {upImg ?
                                        <Button name={"Done"} className="done_btn" onClick={handleFileUpload} />

                                        : ''}
                                </div>
                                <ReactCrop
                                    src={upImg}
                                    onImageLoaded={onLoad}
                                    crop={crop}
                                    onChange={c => setCrop(c)}
                                    onComplete={completedCrop}
                                />

                            </div>

                        </> :
                            <>
                                <span className='schedl_action avenir_black'>
                                    Schedule Live
                                </span>
                                <div className='auction_store_img'>
                                    {console.log("isImageLoaderisImageLoaderisImageLoader",isImageLoader, userInput)}
                                    {storeLogo && <label className='selected_this' style={{ background: '#f0f0f0', border: userInput?.image_type == '1' ? '1px solid #F07721' : 'none' }}>
                                        <img src={storeLogo} height={40} width={50} className='selected_img'
                                            onClick={() => selectLogoImage(storeLogo, 1)} />
                                        {userInput?.image_type == '1' && <span className="tick"></span>}
                                    </label>
                                    }
                                    {(isImageLoader == false && newUrl.length > 0) }
                                    {/* {(isImageLoader == false && newUrl.length > 0) && <p className='tick_transparent_bg' onClick={removeItem}> </p>} */}
                                    {isImageLoader == true ? <AddproductImg /> :
                                        <label>
                                            {(userInput?.image_type == '3') ?
                                                <>
                                                    <img src={userInput?.image_type == '3' ? userInput?.image : ''} className='selected_img' />
                                                    {userInput?.image_type == '3' && <span className="tick"></span>}
                                                </>
                                                :
                                                <img src='assets/photo_upld.svg' height={40} width={50} />
                                            }
                                            <input type="file" size="60" name="document" id="file-input" ref={inputRef} onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                                            <span className='auction_pls_icn avenir_medium'>+</span>
                                        </label>
                                    }
                                    <label style={{ background: '#f0f0f0', border: userInput?.image_type == '2' ? '1px solid #F07721' : 'none' }}>
                                        <img src={headerLogoImage} height={40} width={50} className='selected_img'
                                            onClick={() => selectLogoImage(headerLogoImage, 2)}
                                        />
                                        {userInput?.image_type == '2' && <span className="tick"></span>}
                                    </label>
                                </div>

                                <span className="upload_store_logo_danger avenir_heavy">{userInput.documentErr}</span>
                                <div>
                                    <Input
                                        placeholder="Description"
                                        className="description_inpt"
                                        value={description}
                                        name="description"
                                        maxLength={90}
                                        minLength={10}
                                        onChange={onChangeDescription}
                                    />

                                    <InlineError className='store_error' errorMessage={error.description} />
                                </div>
                                <p className='category avenir_heavy mb-4'>Product Category</p>
                                {/* <span className='choose_category avenir_medium'>Choose the product’s category</span> */}
                                <div className='wrap_radio'>
                                    {listCategory.map(categoryItem => (
                                        <div key={categoryItem.id}>
                                            <input
                                                type="radio"
                                                className="radio_inpt"
                                                value={categoryItem.id.toString()}
                                                name="category"
                                                checked={category == categoryItem.id.toString()}
                                                onChange={onChangeCategory}
                                            />
                                            <span className='avenir_heavy'>{categoryItem.name}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3 _date _form_with_icon'>
                                        <span className='avenir_heavy'>Start Date</span>
                                        <DatePicker
                                            selected={auctionDateString}
                                            onChange={onChangeAuctionDate}
                                            placeholderText="Select"
                                            className="form-control "
                                            minDate={new Date()}
                                            dateFormat="dd-M-yyyy" // Displays date and time in a readable format
                                            showIcon
                                            toggleCalendarOnIconClick
                                        />
                                    </div>
                                    <div className={`col-lg-3 _time ${auctionDateString ? '' : '_disabled'}`} >
                                        <span className='avenir_heavy'>Start Time</span>
                                        <span className='_clock_icon'><ClockIcon /></span>
                                        <DatePicker
                                            selected={selectedStartDate}
                                            onChange={handleStartTimeChange}
                                            placeholderText='Select'
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeInputLabel="Time:"
                                            dateFormat="h:mm aa"
                                            filterTime={filterStartTimeSlots}
                                            minTime={(startTimeTime)}
                                            // // minTime={(new Date())}
                                            maxTime={new Date(new Date().setHours(23, 59))}
                                            disabled={!auctionDateString}

                                        />

                                    </div>

                                    <div className={`col-lg-3 _date _form_with_icon ${selectedStartDate ? '' : '_disabled'}`} >
                                        <span className='avenir_heavy'>End Date</span>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={onChangeAuctionEndDate}
                                            placeholderText="Select"
                                            className="form-control "
                                            minDate={selectedStartDate}
                                            dateFormat="dd-M-yyyy" // Displays date and time in a readable format
                                            showIcon
                                            toggleCalendarOnIconClick
                                            includeDates={isStartTime10 ? [new Date(auctionDateString), addDays(new Date(auctionDateString), 1)] : [new Date(auctionDateString), addDays(new Date(auctionDateString), 0)]}
                                            minTime={selectedStartDate}
                                            maxTime={new Date(new Date().setHours(23, 59))}
                                            disabled={!selectedStartDate}
                                        />
                                    </div>
                                    {/* {console.log("selectedStartDate_________",selectedStartDate)} */}
                                    <div className={`col-lg-3 _time ${selectedStartDate ? '' : '_disabled'}`}>
                                        <span className='avenir_heavy'>End Time</span>
                                        <span className='_clock_icon'><ClockIcon /></span>
                                        <DatePicker
                                            selected={selectedEndDate}
                                            placeholderText='Select'
                                            onChange={handleEndTimeChange}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeInputLabel="Time:"
                                            dateFormat="h:mm aa"

                                            minDate={selectedStartDate}
                                            // minTime={selectedIsFirst ? null : (selectedStartDate ? new Date(selectedStartDate.getTime() + 20 * 60000) : null)} // Ensure minTime is a Date object
                                            // maxTime={selectedIsFirst ? null : (isTodayDate ? maxTime : endDate + 120 * 60000)}
                                            filterTime={(time) => {
                                                const options = generateEndTimeOptions(selectedStartDate);
                                                return !options.some(
                                                    (option) =>
                                                        option.time === moment(time).format("h:mm A") && option.disabled
                                                );
                                            }}
                                            injectTimes={[
                                                // setHours(setMinutes(setSeconds(new Date(), 10), 1), 0),
                                                // setHours(setMinutes(new Date(), 5), 12),
                                                // setHours(setMinutes(new Date(), 59), 23),
                                            ]}
                                        />

                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='left_align_error'>
                                            <InlineError errorMessage={error.datepic} />
                                        </div>
                                    </div>
                                    {(!startTime || !endTime || !selectedEndDate) ?
                                        <>  {
                                            !startTime ?
                                                <div className='col-lg-3'>
                                                    <div className='left_align_error'>
                                                        <InlineError errorMessage={error.startTime} />
                                                    </div>
                                                </div>
                                                :
                                                <div className='col-lg-3'>
                                                    <div className='left_align_error'>

                                                    </div>
                                                </div>
                                        }
                                            {
                                                !endTime ?
                                                    <div className='col-lg-3'>
                                                        <div className='left_align_error'>
                                                            <InlineError errorMessage={error.endTime} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='col-lg-3'>
                                                        <div className='left_align_error'>

                                                        </div>
                                                    </div>
                                            }
                                            {
                                                !selectedEndDate ?
                                                    <div className='col-lg-3'>
                                                        <div className='left_align_error'>
                                                            <InlineError errorMessage={error.selectedEndDate} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='col-lg-3'>
                                                        <div className='left_align_error'>

                                                        </div>
                                                    </div>
                                            }

                                        </> : ''
                                    }
                                </div>
                                <div className='text-center mt-4'>
                                    {/* {
                         (isAllAdded == true) ? */}
                                    <Button
                                        name={btnLoader ? <BtnLoader /> : "Submit"}
                                        onClick={(e) => addAuction(e)}
                                    />
                                    {/* :
                             <Button
                                 className="mt-4"
                                 name={"Submit"}
                                 disabled="true"
                             />
                     } */}
                                </div>
                            </>}

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditAuction