/**
 * @About
 * This file mange environment of the project
 */

//dev
export const devSetting = {
    api: {
        url: "https://stagapi.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://stagsocket.bidsboxx.com/",
        web: "https://stag.bidsboxx.com/",
        apiKey: process.env.APIKEY,
        authDomain: process.env.AUTH_DOMAIN,
        projectId: process.env.PROJECT_ID,
        storageBucket: process.env.STORAGEBUCKET,
        messagingSenderId: process.env.MESSAGING_SENDER_ID,
        appId: process.env.APP_ID
    }
}

//stag
export const stagSetting = {
    api: {
        url: "https://stagapi.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://stagsocket.bidsboxx.com/",
        web: "https://stag.bidsboxx.com/",
        apiKey: process.env.APIKEY,
        authDomain: process.env.AUTH_DOMAIN,
        projectId: process.env.PROJECT_ID,
        storageBucket: process.env.STORAGEBUCKET,
        messagingSenderId: process.env.MESSAGING_SENDER_ID,
        appId: process.env.APP_ID
    }
}

//prod
export const prodSetting = {
    api: {
        url: "https://api.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://api.bidsboxx.com/",
        web: "https://socket.bidsboxx.com/",
        apiKey: process.env.APIKEY,
        authDomain: process.env.AUTH_DOMAIN,
        projectId: process.env.PROJECT_ID,
        storageBucket: process.env.STORAGEBUCKET,
        messagingSenderId: process.env.MESSAGING_SENDER_ID,
        appId: process.env.APP_ID
    }
}

//local
export const localhostSetting = {
    api: {
        url: "https://stagapi.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://stagsocket.bidsboxx.com/",
        web: "https://stag.bidsboxx.com/",
        apiKey: process.env.APIKEY,
        authDomain: process.env.AUTH_DOMAIN,
        projectId: process.env.PROJECT_ID,
        storageBucket: process.env.STORAGEBUCKET,
        messagingSenderId: process.env.MESSAGING_SENDER_ID,
        appId: process.env.APP_ID
    },
}


