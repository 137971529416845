import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdArrowBackIos } from 'react-icons/md';

function AboutUs() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='privacy_policy_parent '>
            <div className='header'>
                <span onClick={goBack}><MdArrowBackIos /></span>
                <Link to="/">
                    <img
                        src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/header-logo_VBshfDA.svg'
                        style={{ height: "46px" }}
                        alt="Bidsboxx Logo"
                    />
                </Link>
            </div>
            <div className="privacy_policy">
                <h4>Adult & Explicit Items Policy:</h4>
                <p>
                    At Bidsboxx, we encourage creative expression and commerce opportunities while maintaining a safe and respectful platform for our diverse community. To ensure this, we have guidelines governing the sale and display of adult content and sensitive items.
                </p>
                <h5>Prohibited Adult Items:</h5>
                <ul>
                    <li><b>Sexually Explicit Content:</b> Pornography or sexually explicit video games (e.g., items rated X, XXX, or unrated for adults-only).</li>
                    <li><b>Sexual Services:</b> Services such as sex work, escort services (including dates), filmed sexual activity, or access/credentials to adult content.</li>
                    <li><b>Fetish Items:</b> Items that have been intimately used, custom photographs featuring sexual content, or sexualized depictions of body parts.</li>
                    <li><b>Sexual Aids:</b> Sex toys, sexual massagers, sexual enhancement products, condoms, lubricants, and other items intended for use during sexual activity.</li>
                    <li><b>Non-Artistic Nude Photography:</b> Nude images not categorized as fine art, educational, or journalistic material.</li>
                    <li><b>AI-Generated Nude Content:</b> AI-created or doctored nude images of public figures.</li>
                    <li><b>Content Involving Minors:</b> Any item that sexualizes minors, whether real or fictionalized.</li>
                </ul>
                <p>
                    By using Bidsboxx, you agree to comply with these terms to create a respectful and inclusive environment for all users. Violations of these guidelines may result in account suspension or removal from the platform.
                </p>
                <hr />

                {/* Retain other content */}
                {/* Add other sections here as needed */}
            </div>
        </div>
    );
}

export default AboutUs;
