import React from 'react';
import "../StyleComponent/Footer.css";
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer_sec'>
            <span>© Bidsboxx.com. All rights reserved.</span>
            <div className='footer_icn'>
                <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/information1.svg' alt='img' />
                <Link to="/about-us">About BidsBoxx</Link>
            </div>
            <span className='fotter_line'></span>
            <div className='footer_icn'>
                <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/terms1.svg' alt='img' />
                <Link to="/terms-conditions"> Terms of use</Link>
            </div>
            <span className='fotter_line'></span>
            <div className='footer_icn'>
                <img src='https://d2ugmqijh93rc0.cloudfront.net/temp/images/privacypolicy.svg' alt='img' />
                <Link to="/privacy-policy"> Privacy policy</Link>
            </div>

        </div>
    )
}

export default Footer