/**
 * @About 
 * This file is entry point of config
 */

import _ from 'lodash';
import { devSetting, stagSetting, prodSetting, localhostSetting } from './Setting';

//defaut setting
let defaultSetting = {
    api: {
        url: "https://devapi.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://devsocket.bidsboxx.com/",
        web: "https://dev.bidsboxx.com/",
        apiKey: process.env.APIKEY,
        authDomain: process.env.AUTH_DOMAIN,
        projectId: process.env.PROJECT_ID,
        storageBucket: process.env.STORAGEBUCKET,
        messagingSenderId: process.env.MESSAGING_SENDER_ID,
        appId: process.env.APP_ID
    }
}

let siteSetting = defaultSetting;
switch (process.env.REACT_APP_ENV) {
    case "dev":
    case "development":
        siteSetting = _.extend(defaultSetting, devSetting);
        break;
    case "prod":
    case "production":
        siteSetting = _.extend(defaultSetting, prodSetting);
        break;
    case "stag":
    case "staging":
        siteSetting = _.extend(defaultSetting, stagSetting);
        break;
    case "local":
    case "localhost":
        siteSetting = _.extend(defaultSetting, localhostSetting);
        break;
    default:
        siteSetting = _.extend(defaultSetting, devSetting);
}
export default siteSetting;
