import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FilterIcon } from '../Svg'
import Button from '../CommonComponent/Button'

function FilterBox(props) {
  console.log("propeeeeeeeeeeeeeee",props)
  const [category, setSelectedCategories] = useState(props.getCategory);
  const [isDropdown, SetIsDropdown] = useState(false)
  const onChangeCategory = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedCategories([...category, parseInt(value)]);
    } else {
      setSelectedCategories(category.filter(cat => cat !== parseInt(value)));
    }
  };

  useEffect(() => {
    setSelectedCategories(props.getCategory);
  }, [props.getCategory])

  const dropdownHendelor = () => {
    SetIsDropdown(!isDropdown)
  }

  return (
    <div className='_filter_box '>
      <Dropdown data-bs-theme="" show={isDropdown} autoClose="outside" onToggle={() => SetIsDropdown(!isDropdown)}>
        <Dropdown id="dropdown-button-dark-example1" variant="" onClick={() => dropdownHendelor()}>
          <span></span>
          <FilterIcon />
        </Dropdown>
        <Dropdown.Menu>
          <button type="button" aria-expanded="false" className="dropdown-togglee">
            <span>Select Lives</span>
          </button>
          <div className="dropdown-item">
            <div className="form-group">
              <input type="checkbox" id="category1" name="category1"
                value={1}
                checked={category.includes(1)}
                onChange={onChangeCategory}
              />
              <label htmlFor="category1"><span>Completed</span></label>
            </div>
          </div>
          <div className="dropdown-item">
            <div className="form-group">
              <input type="checkbox" id="category2" name="category2"
                value={2}
                checked={category.includes(2)}
                onChange={onChangeCategory}
              />
              <label htmlFor="category2"><span>Scheduled</span></label>
            </div>
          </div>
          <div className="dropdown-item">
            <div className="form-group">
              <input type="checkbox" id="category3" name="category3"
                value={3}
                checked={category.includes(3)}
                onChange={onChangeCategory}
              />
              <label htmlFor="category3"><span>Archived</span></label>
            </div>
          </div>
          <div className="dropdown-item">
            <Button name="APPLY" className="btn btn-sm lower_case" onClick={() => { SetIsDropdown(!isDropdown); props.auctionList(category) }} />
          </div>
        </Dropdown.Menu>

      </Dropdown>
    </div>
  )
}

export default FilterBox